import React from "react";
import {
  SafeAreaView,
  ActivityIndicator,
  StyleSheet,
} from "react-native";

const Loading = () => {
  return (
    <SafeAreaView style={styles.container}>
      {/* <Image source={loadingImage} style={styles.logo} /> */}
      <ActivityIndicator size="large" color="#FFF" />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 150, // Ajuste conforme necessário
    height: 150, // Ajuste conforme necessário
    margin: 20,
  },
});

export default Loading;
