import { Platform } from "react-native";

let firebaseConfig;

if (Platform.OS === "web") {
  firebaseConfig = require("./firebaseConfigWeb");
} else {
  firebaseConfig = require("./firebaseConfigNative");
}

export const db = firebaseConfig.db;
export const storage = firebaseConfig.storage;
export const auth = firebaseConfig.auth;
