import React from 'react';
import { View, Text, TouchableOpacity, Animated } from 'react-native';
import Icon from "react-native-vector-icons/Ionicons";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import moment from 'moment';

const LotSelection = ({ evento, selectedLots, setSelectedLots, handleSelectLot }) => {
  const renderLot = (lote, index) => {
    const isSelected = selectedLots[lote.name] && selectedLots[lote.name].quantity > 0;
    const quantity = isSelected ? selectedLots[lote.name].quantity : 0;

    const availableQuantity = Number(lote.quantity) - Number(lote.sold);
    const lotIsAvailable = availableQuantity > 0;
    const isFinally = lote.finally;
    const isVisible = lote.visible;

    // Verifica o status de virada do lote anterior
    let previousLotFinalized = true;
    if (lote.previousLot && evento.lots[lote.previousLot]) {
      const previousLot = evento.lots[lote.previousLot];
      previousLotFinalized = previousLot.finally ||
                             (previousLot.turnType === "quantity" && previousLot.sold >= previousLot.quantity) ||
                             (previousLot.turnType === "date" && moment(previousLot.end?.toDate()).isBefore(moment()));
    }

    const currentDate = moment();
    const startDate = lote.start?.toDate ? moment(lote.start.toDate()) : moment(lote.start);
    const endDate = lote.end?.toDate ? moment(lote.end.toDate()) : moment(lote.end);
    const isWithinDateRange = lote.turnType === "date" ? currentDate.isBetween(startDate, endDate) : true;

    // Condições para que o lote esteja ativo (verde) e possa ser exibido
    const isLotActive = isVisible && !isFinally && lotIsAvailable && previousLotFinalized && isWithinDateRange;
    if (!isLotActive) return null;

    return (
      <Animated.View key={index} style={[styles.lotCard, isSelected && styles.selectedLotCard]}>
        <View style={styles.lotDetailContainer}>
          <Text style={styles.lotName}>{lote.name}</Text>
          <Text style={styles.lotPrice}>R$ {Number(lote.price).toFixed(2)}</Text>
          {evento?.absorbFee ? (
            <Text style={styles.taxInfo}>Taxa inclusa</Text>
          ) : (
            <Text style={styles.taxInfo}>+ taxa a partir de R$ {(lote.price * 0.05).toFixed(2)}</Text>
          )}
        </View>
        <View style={styles.lotControls}>
          <TouchableOpacity
            onPress={() => handleSelectLot(lote, false)}
            style={styles.controlButton}
            disabled={!lotIsAvailable || quantity === 0}
          >
            <Icon name="remove" size={24} color="#FFF" />
          </TouchableOpacity>
          <Text style={styles.quantity}>{quantity}</Text>
          <TouchableOpacity
            onPress={() => handleSelectLot(lote, true)}
            style={styles.controlButtonPlus}
            disabled={!lotIsAvailable || quantity >= availableQuantity}
          >
            <Icon name="add" size={24} color="#000" />
          </TouchableOpacity>
        </View>
      </Animated.View>
    );
  };

  const currentDate = moment();
  const endDateEvent = evento.dates.end?.toDate ? moment(evento.dates.end.toDate()) : moment(evento.dates.end);

  const availableLots = evento?.lots && Object.values(evento.lots)
    .filter((lote) => {
      const availableQuantity = Number(lote.quantity) - Number(lote.sold);
      const lotIsAvailable = availableQuantity > 0;
      const isFinally = lote.finally;
      const isVisible = lote.visible;

      // Verifica se o lote anterior atende às condições de virada
      let previousLotFinalized = true;
      if (lote.previousLot && evento.lots[lote.previousLot]) {
        const previousLot = evento.lots[lote.previousLot];
        previousLotFinalized = previousLot.finally ||
                               (previousLot.turnType === "quantity" && previousLot.sold >= previousLot.quantity) ||
                               (previousLot.turnType === "date" && moment(previousLot.end?.toDate()).isBefore(moment()));
      }

      const startDate = lote.start?.toDate ? moment(lote.start.toDate()) : moment(lote.start);
      const endDate = lote.end?.toDate ? moment(lote.end.toDate()) : moment(lote.end);
      const isWithinDateRange = lote.turnType === "date" ? currentDate.isBetween(startDate, endDate) : true;

      return isVisible && !isFinally && lotIsAvailable && previousLotFinalized && isWithinDateRange;
    })
    .sort((a, b) => Number(a.price) - Number(b.price));

  return (
    <View style={styles.lotSelectionContainer}>
      <View style={styles.sectionHeader}>
        <Icon name="ticket-sharp" size={22} color="#FFF" style={styles.sectionIcon} />
        <Text style={styles.lotTitleText}>Lotes Disponíveis</Text>
      </View>
      {evento.status !== "Finalizado" && currentDate.isBefore(endDateEvent) && availableLots.length > 0 ? (
        availableLots.map((lote, index) => renderLot(lote, index))
      ) : (
        <View style={styles.noLotsContainer}>
          <MaterialCommunityIcons name="emoticon-sad" size={40} color="#FFFD" />
          <Text style={styles.noLotsText}>Nenhum lote disponível no momento</Text>
        </View>
      )}
    </View>
  );
};

export default LotSelection;
