import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  headerContainer: {
    backgroundColor: "#000",
  },
  restContainer: {
    backgroundColor: "#000",
    paddingHorizontal: 10,
    flex: 1,
  },
  eventsGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  gridItem: {
    marginBottom: 20,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#444",
    marginLeft: 10,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  emptyText: {
    fontSize: 18,
    color: "#bbb",
    textAlign: "center",
  },
  containerBlack: {
    backgroundColor: "#000",
  },
  scrollViewContent: {
    backgroundColor: "#000",
  },
});