import axios from 'axios';
import { collection, doc, updateDoc, query, where, getDocs, getDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../utils/firebase/firebaseConfig";
import { auth } from "../../utils/firebase/firebaseConfig";

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export const updateEvent = async (eventUsernameDoc, updatedData) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(`${API_URL}/updateEvent`, {
      eventUsernameDoc,
      updatedData,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      }
    });
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao atualizar evento:", error);
    throw error;
  }
};

export const updateEventImage = async (username, url) => {
  const eventsRef = collection(db, "eventsData");
  const q = query(eventsRef, where("username", "==", username));
  const querySnapshot = await getDocs(q);

  for (const docSnapshot of querySnapshot.docs) {
    const userDocRef = doc(db, "eventsData", docSnapshot.id);
    await updateDoc(userDocRef, {
      eventImage: url,
    });
  }
};

export const uploadEventImage = async (uri, username) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  const fileName = `${username}_event.jpg`;
  const ref = storageRef(storage, `eventImages/${fileName}`);
  await uploadBytes(ref, blob);
  const downloadURL = await getDownloadURL(ref);
  return downloadURL;
};

export const updateEventReportCountByUsername = async (username) => {
  try {
    const idToken = await getIdToken();
    const response = await axios.post(`${API_URL}/updateEventReportCount`, {
      username,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      }
    });
    console.log(response.data);
  } catch (error) {
    console.error("Erro ao atualizar a contagem de denúncias do evento:", error);
    throw error;
  }
};
