import React from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { styles } from "../../../../styles/utils/terms/producerTerms/producerTermsStyles";
import Icon from "react-native-vector-icons/Ionicons";

export default function ProducerPrivacyPolicy({ navigation }) {
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" />
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Text style={styles.title}>Política de Privacidade</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon
            name="person-outline"
            size={25}
            color="#000"
            style={styles.goBackButton}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.content}>
          <Text style={styles.sectionTitle}>
            POLÍTICA DE PRIVACIDADE PARA PRODUTORES DA PLATAFORMA E APLICATIVO
            RAYV
          </Text>
          <Text style={styles.sectionTitle}>1. A Plataforma</Text>
          <Text style={styles.body}>
            A RAYV é uma plataforma tecnológica disponível para produtores e
            casas de eventos, facilitando a venda de ingressos, inscrição para
            eventos, cobrança de contribuições para eventos e a gestão de
            participantes, acessível no endereço eletrônico www.rayv.com.br, ou
            por meio dos aplicativos oficiais. É uma pessoa jurídica de direito
            privado, inscrita no CNPJ sob o nº X, com endereço na X.
          </Text>
          <Text style={styles.sectionTitle}>
            2. Obtenção de Informações Pessoais e Não Pessoais
          </Text>
          <Text style={styles.body}>
            2.1. Conforme a Lei nº 13.709/2018, conhecida como "Lei Geral de
            Proteção de Dados Pessoais", a RAYV pode atuar tanto como
            controladora de dados pessoais (por exemplo, ao recomendar eventos
            na plataforma RAYV) quanto como operadora de dados pessoais (por
            exemplo, ao armazenar dados pessoais coletados a partir de
            formulários personalizados de produtores) dependendo das
            circunstâncias específicas.
          </Text>
          <Text style={styles.body}>
            2.2. Ao utilizar as soluções tecnológicas da RAYV Organizador, o
            usuário deverá registrar os dados de identificação do responsável
            pelos eventos, bem como os dados bancários necessários para o
            repasse das vendas em casos de eventos ou conteúdos pagos. Dessa
            forma, a RAYV coletará dados pessoais dos Produtores quando estes
            forem os responsáveis diretos pelos eventos ou estiverem
            representando legalmente a pessoa jurídica responsável.
          </Text>
          <Text style={styles.body}>
            2.2.1. Ao utilizar as soluções tecnológicas oferecidas pela RAYV
            para criar eventos, o Organizador será submetido a uma análise para
            garantir a veracidade das informações fornecidas e a conformidade
            legal das possíveis transações realizadas através da plataforma,
            sendo assim, os documentos de identificação necessários também serão
            coletados. A RAYV pode, ainda, utilizar fontes externas para
            verificar a idoneidade do Organizador, se necessário.
          </Text>
          <Text style={styles.body}>
            2.3. Ao utilizar a Plataforma RAYV, o usuário Consumidor poderá
            preencher quatro blocos de informações:
          </Text>
          <Text style={styles.body}>
            a) Informações de Cadastro: Para se cadastrar na Plataforma RAYV, o
            usuário deve fornecer nome completo, e-mail e data de nascimento.
            {"\n"}
            b) Informações do Participante: Para a compra de um ingresso, serão
            coletados dados de identificação da pessoa que utilizará o ingresso
            para acessar o evento, como nome e e-mail. Em alguns casos, o
            Organizador pode configurar a página de compras para coletar
            informações adicionais, como CPF, data de nascimento ou perguntas
            personalizadas.
            {"\n"}
            c) Informações para o Recebimento do Ingresso: Serão coletados os
            dados de identificação da pessoa que receberá o ingresso por e-mail
            e que terá acesso ao mesmo por meio de seu login na Plataforma,
            baseados no nome e no e-mail.
            {"\n"}
            d) Informações de Pagamento: Serão coletados dados financeiros e de
            identificação da pessoa responsável pelo pagamento dos ingressos,
            como informações do cartão de crédito, nome, telefone, CPF e
            endereço.
          </Text>
          <Text style={styles.body}>
            2.4. De forma geral, a RAYV coletará dados pessoais de todos os
            usuários sempre que estes fornecerem tais informações
            voluntariamente para atingir os objetivos previstos nesta Política.
            Essas informações incluem, por exemplo: Nome, CPF, e-mail, senha e
            telefone, data de nascimento, documentos de identificação
            governamental, histórico de participação, reserva de assentos,
            check-in, preferências de comunicação, endereço, informações de URL,
            informações sobre o dispositivo.
          </Text>
          <Text style={styles.body}>
            2.5. Além das informações mencionadas anteriormente, o usuário pode
            fornecer outros tipos de dados, como informações de geolocalização,
            comportamento de uso do aplicativo ou produtos, e detalhes sobre o
            dispositivo utilizado para acessar a plataforma RAYV, incluindo
            endereços IP, tipo e idioma do navegador, provedor de serviços de
            Internet, páginas visitadas, sistema operacional, informações de
            data e horário, sequência de cliques, fabricante do dispositivo,
            operadora, modelo e redes Wi-Fi.
          </Text>
          <Text style={styles.body}>
            2.5.1. Para entender melhor a usabilidade dos produtos e
            aprimorá-los, a RAYV pode convidar usuários a responderem pesquisas
            sobre suas experiências na Plataforma. Os usuários são informados de
            que a participação é voluntária e que os dados pessoais fornecidos
            serão tratados e armazenados conforme os objetivos especificados no
            convite.
          </Text>
          <Text style={styles.body}>
            2.6. A RAYV pode obter informações dos usuários a partir de fontes
            diversas, como sites de terceiros, instituições bancárias,
            processadores de pagamento e outras empresas ou agências de proteção
            de crédito, respeitando as regras estabelecidas pela legislação
            aplicável.
          </Text>
          <Text style={styles.body}>
            2.7. Em caso de atividades suspeitas ou transações que apresentem
            risco de fraude, a RAYV pode solicitar documentos pessoais ou outras
            formas de comprovação para confirmar a identidade do usuário e a
            correspondência com os dados fornecidos. Esta solicitação pode ser
            feita por telefone, e-mail, conta comercial verificada no WhatsApp
            ou outro meio apropriado.
          </Text>
          <Text style={styles.body}>
            2.7.1. A RAYV só solicitará documentos por meio de seus canais
            oficiais de comunicação, como endereços de e-mail sob o domínio
            “RAYV” e contas verificadas pelos próprios aplicativos.
          </Text>
          <Text style={styles.body}>
            2.8. Caso a RAYV combine informações não pessoais com informações
            pessoais, a informação combinada será tratada como pessoal enquanto
            permanecer combinada.
          </Text>
          <Text style={styles.body}>
            2.9. Se as informações combinadas forem consideradas sensíveis,
            capazes de revelar dados sobre origem racial ou étnica, convicção
            religiosa, opinião política, filiação sindical, saúde, vida sexual,
            dados genéticos ou biométricos, o tratamento dessas informações será
            realizado somente com o consentimento do titular. Tais informações
            serão armazenadas de forma segura e não serão compartilhadas com
            terceiros, exceto após processo de anonimização.
          </Text>
          <Text style={styles.sectionTitle}>
            3. Utilização das Informações Pessoais
          </Text>
          <Text style={styles.body}>
            3.1. Os dados pessoais serão utilizados de acordo com esta Política
            de Privacidade para prover, desenvolver e melhorar os serviços ou
            produtos oferecidos, incluindo novos produtos.
          </Text>
          <Text style={styles.body}>
            3.2. A RAYV utilizará os dados pessoais para:
          </Text>
          <Text style={styles.body}>
            a) Realizar cadastro ou inscrição de participação, permitindo acesso
            aos serviços da RAYV ou dos Produtores, emitir certificados,
            controlar acesso ao evento ou conteúdo, viabilizar pagamentos e
            emitir Nota Fiscal;
            {"\n"}
            b) Enviar avisos e notificações importantes sobre compras, alterações
            em prazos, condições e políticas, e fornecer suporte ao usuário.
            Essas comunicações são essenciais para a interação com a RAYV e não
            podem ser desativadas;
            {"\n"}
            c) Melhorar a segurança, serviços e ofertas da RAYV, personalizar a
            experiência dos usuários, solucionar problemas técnicos, identificar
            e prevenir fraudes;
            {"\n"}
            d) Recomendar eventos e enviar publicidade e materiais promocionais
            sobre nossos serviços e novidades, incluindo campanhas de marketing
            direcionado em redes sociais e notificações;
            {"\n"}
            e) Realizar auditorias, análises de dados, pesquisas para aprimorar
            produtos, serviços e comunicações com clientes, e gerar análises
            estatísticas sobre o uso de nossos serviços;
            {"\n"}
            f) Cumprir obrigações legais, proteger direitos de usuários e da
            empresa, e colaborar com investigações de acordo com as Diretrizes
            de Comunidade da plataforma.
          </Text>
          <Text style={styles.body}>
            3.2.1. A RAYV tratará os dados pessoais com base em obrigações
            legais, execução de contratos, exercício regular de direitos,
            interesses legítimos e proteção ao crédito, variando conforme a
            atividade.
          </Text>
          <Text style={styles.body}>
            3.3. Se a RAYV utilizar os dados pessoais de maneira diferente do
            estabelecido nesta Política de Privacidade, o usuário será informado
            previamente sobre a nova utilização, antes ou na data em que os
            dados pessoais forem recebidos.
          </Text>
          <Text style={styles.sectionTitle}>
            4. Utilização dos Dados Pessoais pelos Produtores
          </Text>
          <Text style={styles.body}>
            4.1. A RAYV pode atuar como operadora de dados pessoais, tratando-os
            em nome dos Produtores. Nesse caso, os dados pessoais coletados
            ficam disponíveis para os Produtores, que são responsáveis pelo
            tratamento desses dados, incluindo compartilhamento, análise,
            utilização e armazenamento. Os Produtores comprometem-se a ser
            transparentes sobre o tratamento dos dados pessoais dos Consumidores
            e Participantes.
          </Text>
          <Text style={styles.body}>
            4.2. Os Produtores podem configurar a página de seus eventos ou
            conteúdos para coletar informações adicionais dos Consumidores e
            Participantes. Nesses casos, a RAYV, como operadora de dados, não
            interfere no tratamento dos dados realizado pelo Organizador nem no
            tipo de dados coletados ou na forma como são utilizados.
          </Text>
          <Text style={styles.body}>
            4.3. A RAYV possui um documento específico, o "Acordo de
            Processamento de Dados", que estabelece direitos e obrigações na
            condição de operadora de dados em nome dos Produtores. Os Produtores
            aceitam expressamente todas as disposições descritas no Acordo.
          </Text>
          <Text style={styles.body}>
            4.4. Os titulares dos dados pessoais devem exercer os direitos
            previstos no item “7” deste documento diretamente com os Produtores,
            podendo a RAYV operacionalizar essas requisições em determinados
            casos.
          </Text>
          <Text style={styles.sectionTitle}>
            5. Compartilhamento de Informações Pessoais
          </Text>
          <Text style={styles.body}>
            5.1. A RAYV não comercializa os dados pessoais de seus usuários a
            terceiros, mas pode divulgar, transferir ou compartilhar esses dados
            conforme estabelecido neste documento e na legislação brasileira.
          </Text>
          <Text style={styles.body}>
            5.2. A RAYV pode vender ou comprar empresas ou ativos. Em caso de
            venda, fusão, reorganização ou dissolução da empresa, os dados
            pessoais podem ser incluídos nos ativos compartilhados ou
            transferidos.
          </Text>
          <Text style={styles.body}>
            5.2.1. A RAYV pode compartilhar dados pessoais com empresas do mesmo
            grupo econômico para desenvolver novos produtos e serviços, oferecer
            produtos e serviços que atendam melhor aos interesses dos usuários,
            e gerar dados estatísticos e agregados sobre o uso de produtos e
            serviços e perfis dos usuários.
          </Text>
          <Text style={styles.body}>
            5.3. A RAYV pode compartilhar dados pessoais com contratados e
            prestadores de serviços que tratem esses dados em seu nome, como
            agências de marketing, prestadores de serviços de bancos de dados,
            recuperação de desastres, backup, e-mail, operação local nos eventos
            e processadores de pagamentos.
          </Text>
          <Text style={styles.body}>
            5.4. A RAYV pode compartilhar informações dos usuários por meio das
            integrações com a plataforma. Nesse caso, os Produtores, como
            controladores de dados, são responsáveis pelo tratamento das
            informações.
          </Text>
          <Text style={styles.body}>
            5.6. A RAYV compartilhará informações dos usuários com autoridades
            competentes dentro e fora do Brasil, se requisitado pela legislação
            aplicável, por decisão judicial, ou para responder a processos
            judiciais ou litígios.
          </Text>
          <Text style={styles.body}>
            5.6.1. Nestas situações, a RAYV cooperará com as autoridades
            competentes em investigações de ilícitos, infrações de direitos de
            propriedade intelectual ou industrial, ou qualquer atividade ilegal
            que possa expor a RAYV ou seus usuários a responsabilidades legais,
            exceto em casos de sigilo legal.
          </Text>
          <Text style={styles.body}>
            5.7. A RAYV se reserva o direito de compartilhar informações dos
            usuários se houver motivos para acreditar que a atividade do usuário
            é suspeita, ilegal ou prejudicial à RAYV ou a terceiros.
          </Text>
          <Text style={styles.body}>
            5.8. Se solicitado por algum Consumidor ou Participante, a RAYV
            poderá fornecer os dados de identificação do Organizador responsável
            pelo evento ou conteúdo do qual o usuário tenha participado.
          </Text>
          <Text style={styles.sectionTitle}>
            6. Armazenamento de Informações Pessoais
          </Text>
          <Text style={styles.body}>
            6.1. As informações pessoais coletadas pela RAYV serão armazenadas
            no banco de dados online FireBase.
          </Text>
          <Text style={styles.body}>
            6.1.1. Na contratação desses serviços, a RAYV buscará empresas que
            empreguem altos níveis de segurança no armazenamento das
            informações, estabelecendo contratos que não violem as definições de
            privacidade desta Política.
          </Text>
          <Text style={styles.body}>
            6.2. A RAYV armazenará as informações dos usuários apenas pelo
            período necessário para os fins apresentados nos Termos de Uso e
            nesta Política de Privacidade, respeitando os períodos de retenção
            de dados determinados pela legislação aplicável.
          </Text>
          <Text style={styles.body}>
            6.2.1. Se o usuário solicitar a exclusão de sua conta, as
            informações pessoais fornecidas durante o uso da plataforma RAYV
            serão anonimizadas ou excluídas definitivamente, exceto se forem
            necessárias para cumprir obrigações legais, atender a interesses
            legítimos ou exercer direitos em processos judiciais,
            administrativos ou arbitrais.
          </Text>
          <Text style={styles.body}>
            6.3. A RAYV emprega esforços para proteger as informações pessoais
            dos usuários contra perda, roubo, uso indevido, acesso não
            autorizado, divulgação, alteração e destruição.
          </Text>
          <Text style={styles.body}>
            6.3.1. A RAYV tratará os dados pessoais com alto grau de segurança,
            utilizando as melhores práticas da indústria, como criptografia,
            monitoramento e testes de segurança periódicos, e firewalls. No
            entanto, não é possível garantir totalmente a não ocorrência de
            interceptações ou violações dos sistemas e bases de dados, já que a
            segurança na internet está em constante aprimoramento.
          </Text>
          <Text style={styles.body}>
            6.3.2. Em caso de incidente de segurança da informação que resulte
            na destruição, perda, alteração, acesso não autorizado ou vazamento
            de dados pessoais, a RAYV avaliará imediatamente os riscos às
            liberdades civis e aos direitos fundamentais dos titulares dos
            dados. A comunicação aos titulares ou à Autoridade Nacional de
            Proteção de Dados será realizada conforme o caso concreto e após
            avaliação dos riscos mencionados.
          </Text>
          <Text style={styles.sectionTitle}>
            7. Transferência Internacional de Dados
          </Text>
          <Text style={styles.body}>
            7.1. A RAYV pode transferir dados internacionalmente para países
            como os Estados Unidos e nações da União Europeia, visando executar
            atividades relacionadas aos serviços oferecidos aos usuários e
            adquirir informações que contribuam para a melhoria e segurança dos
            nossos serviços.
          </Text>
          <Text style={styles.body}>
            7.2. Quando compartilhamos dados com parceiros em outros países,
            exigimos contratualmente que esses parceiros mantenham padrões de
            proteção de dados e segurança da informação compatíveis com esta
            Política de Privacidade, assegurando que os dados sejam sempre
            protegidos conforme descrito.
          </Text>
          <Text style={styles.sectionTitle}>8. Direitos dos Usuários</Text>
          <Text style={styles.body}>
            8.1. Os usuários podem solicitar à RAYV, de maneira simples e
            acessível, através do nosso formulário web no Portal de Privacidade:
            (i) a confirmação da existência de tratamento de dados pessoais;
            (ii) o acesso aos dados pessoais; (iii) a correção de dados
            incompletos, incorretos ou desatualizados; (iv) a anonimização,
            bloqueio ou eliminação de dados desnecessários ou excessivos; (v) a
            portabilidade dos dados, dentro dos limites legais.
          </Text>
          <Text style={styles.body}>
            8.2. Os usuários podem corrigir seus dados autonomamente em várias
            situações, alterando o nome no certificado de participação ou
            atualizando os dados de cadastro da Conta RAYV.
          </Text>
          <Text style={styles.body}>
            8.2.1. Alterar o endereço de e-mail de uma conta resultará na perda
            de acesso a ingressos e conteúdos previamente adquiridos, já que os
            pedidos estão vinculados ao e-mail fornecido no momento da compra.
            Nesse caso, o usuário deve transferir a titularidade dos ingressos
            ou conteúdos desejados antes de alterar seu e-mail, informando o
            novo endereço vinculado.
          </Text>
          <Text style={styles.body}>
            8.3. Ao realizar qualquer das ações especificadas anteriormente, a
            RAYV pode solicitar informações adicionais e documentos de
            identificação para confirmar que a identidade do solicitante
            corresponde à do titular dos dados pessoais a serem acessados ou
            corrigidos.
          </Text>
          <Text style={styles.body}>
            8.4. Se um usuário solicitar a exclusão de seus dados, a RAYV está
            autorizada a excluir ou anonimizar esses dados, mesmo que isso
            signifique remover sua disponibilidade para o Organizador na
            plataforma RAYV. No entanto, os dados pessoais ainda podem estar
            disponíveis nos bancos de dados do Organizador se transmitidos antes
            da RAYV receber ou tomar medidas referentes à exclusão ou
            anonimização.
          </Text>
          <Text style={styles.body}>
            8.5. Os usuários podem gerenciar suas preferências de e-mail e
            notificações da RAYV autonomamente, como ao se descadastrar do
            recebimento de comunicações no próprio corpo do e-mail, enviar uma
            solicitação pelo formulário web ou pelo e-mail indicado no item
            “7.1.”, ou entrar em contato com o Organizador se ele for o
            “Controlador dos dados pessoais” e responsável pelo envio e conteúdo
            das comunicações.
          </Text>
          <Text style={styles.body}>
            8.5.1. A comunicação com o Organizador pode ser feita diretamente,
            caso o Organizador tenha fornecido e-mail e/ou telefone para contato
            no corpo do evento.
          </Text>
          <Text style={styles.body}>
            8.6. Os usuários podem gerenciar suas preferências quanto ao envio
            de notificações dos aplicativos da RAYV.
          </Text>
          <Text style={styles.body}>
            8.7. Os usuários podem solicitar a exclusão de suas contas.
          </Text>
          <Text style={styles.sectionTitle}>
            9. Utilização da Plataforma RAYV por Menores e Incapazes
          </Text>
          <Text style={styles.body}>
            9.1. As soluções da RAYV são destinadas a maiores de 13 anos, e não
            coletamos conscientemente dados de menores dessa idade. Caso
            identifiquemos a coleta de dados de menores de 13 anos, procederemos
            com a exclusão da conta e dados fornecidos, mantendo apenas aqueles
            necessários para evitar nova tentativa de cadastro.
          </Text>
          <Text style={styles.body}>
            9.2. Usuários menores de 18 anos devem obter o consentimento
            expresso de seus pais, tutores ou representantes legais para
            utilizar a plataforma RAYV e suas funcionalidades, conforme os
            Termos de Uso e a Política de Privacidade.
          </Text>
          <Text style={styles.body}>
            9.3. Pais, tutores ou representantes legais são responsáveis pelo
            acesso à plataforma RAYV por crianças e adolescentes sem a devida
            autorização prévia, sendo sua responsabilidade fiscalizar as
            atividades e conduta dos menores sob sua tutela, assim como conhecer
            a integralidade destes Termos.
          </Text>
          <Text style={styles.sectionTitle}>
            10. Cookies e Outras Tecnologias
          </Text>
          <Text style={styles.body}>
            10.1. A RAYV possui uma política própria sobre o uso de cookies e
            tecnologias de rastreamento semelhantes, denominada “Política de
            Cookies”.
          </Text>
          <Text style={styles.sectionTitle}>11. Disposições Gerais</Text>
          <Text style={styles.body}>
            11.1. Ao navegar na plataforma RAYV e utilizar suas funcionalidades,
            os usuários aceitam os termos desta Política de Privacidade e demais
            políticas vigentes na data de acesso, como a Política de Cookies e
            os Termos de Uso. É recomendável que os usuários se mantenham
            atualizados.
          </Text>
          <Text style={styles.body}>
            11.2. Esta Política de Privacidade está sujeita a melhorias e
            aprimoramentos contínuos. A RAYV se reserva o direito de modificá-la
            a qualquer momento para se adequar a novas finalidades ou
            conformidade legal. Os usuários devem verificar a política sempre
            que acessarem a plataforma. Alterações relevantes serão comunicadas
            aos usuários.
          </Text>
          <Text style={styles.body}>
            11.3. Qualquer tolerância quanto à violação dos termos e condições
            será considerada mera liberalidade e não interpretada como renúncia
            a direitos ou alteração contratual.
          </Text>
          <Text style={styles.body}>
            11.4. Se alguma disposição desta Política for julgada inaplicável,
            as demais permanecerão em vigor. Os termos aqui descritos serão
            interpretados segundo a legislação brasileira.
          </Text>
          <Text style={styles.body}>
            11.5. A comunicação entre a RAYV e o usuário deve ser realizada
            pelos canais de atendimento indicados na plataforma.
          </Text>
          <Text style={styles.body}>
            11.6. Potenciais divergências entre o usuário e a RAYV serão
            resolvidas amigavelmente sempre que possível. Se esgotados todos os
            esforços nesse sentido, fica eleito o foro da comarca de São João
            Del Rei, em Minas Gerais, para resolver controvérsias ou queixas
            relacionadas à utilização da nossa plataforma ou a esta Política de
            Privacidade.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
