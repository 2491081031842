import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase/firebaseConfig";

export const onAppConfigMaintenanceSnapshot = (callback) => {
  try {
    const appConfigRef = doc(db, "appConfig", "maintenance");
    return onSnapshot(appConfigRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        callback({
          isUnderMaintenance: data.isUnderMaintenance,
          message: data.message,
          startTime: data.startTime,
          endTime: data.endTime,
        });
      } else {
        callback(null);
      }
    });
  } catch (error) {
    console.error("Erro ao escutar dados de manutenção:", error);
    throw error;
  }
};
