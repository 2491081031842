// src/services/utils/firebaseConfigWeb.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAT75hfZsFY63cphkWAhi3jmAqu4lVfmrE",
  authDomain: "rayv-a7c48.firebaseapp.com",
  projectId: "rayv-a7c48",
  storageBucket: "rayv-a7c48.appspot.com",
  messagingSenderId: "222419218068",
  appId: "1:222419218068:web:9e80d4e12f4720a45c0e81",
  measurementId: "G-XBB3TKFBZZ",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
