import React, { useState, useEffect } from "react";
import { View, Text, ScrollView } from "react-native";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import { getChat } from "../../../services/cruds/eventsChats/getEventsChats";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import ContentLoader, { Rect } from 'react-content-loader/native';

const Communication = ({ eventId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const chatData = await getChat(eventId, "Geral", null);
        if (chatData && chatData.messages) {
          setMessages(chatData.messages);
        }
      } catch (error) {
        console.error("Erro ao obter mensagens:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMessages();
  }, [eventId]);

  const formatDateToBrasilia = (timestamp) => {
    const date = moment(timestamp.toDate()).tz("America/Sao_Paulo").locale("pt-br");
    return date.format("DD/MM/YYYY [às] HH:mm");
  };

  if (loading) {
    return (
      <ScrollView style={styles.tabBeginContainer}>
        {Array.from({ length: 5 }).map((_, index) => (
          <ContentLoader
            key={index}
            speed={1}
            width="100%"
            height={60}
            backgroundColor="#3d3d3d"
            foregroundColor="#1c1c1c"
          >
            <Rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
            <Rect x="0" y="25" rx="5" ry="5" width="50%" height="15" />
          </ContentLoader>
        ))}
      </ScrollView>
    );
  }

  return (
    <ScrollView style={styles.tabBeginContainer}>
      {messages.length > 0 ? (
        messages.map((message, index) => (
          <View key={index} style={styles.messageContainer}>
            <Text style={styles.messageText}>{message.message}</Text>
            <Text style={styles.messageTimestamp}>{formatDateToBrasilia(message.timestamp)}</Text>
          </View>
        ))
      ) : (
        <Text style={styles.noMessageText}>Nenhum comunicado disponível.</Text>
      )}
    </ScrollView>
  );
};

export default Communication;