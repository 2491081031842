import {
  collection,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  startAt,
  endAt,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

// Função para limpar acentos
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Função para limpar o nome
const cleanName = (name) => {
  return removeAccents(name)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Remove caracteres especiais
};

export const getEvent = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const docSnap = await getDoc(eventRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do evento:", error);
    throw error;
  }
};

export const getDraftEventCount = async (userId) => {
  try {
    const eventsRef = collection(db, "eventsData");
    const q = query(eventsRef, where("organizer.organizationId", "==", userId), where("status", "==", "Rascunho"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    console.error("Erro ao obter a contagem de eventos em rascunho:", error);
    throw error;
  }
};

export const getInAnalysisEventCount = async (userId) => {
  try {
    const eventsRef = collection(db, "eventsData");
    const q = query(eventsRef, where("organizer.organizationId", "==", userId), where("status", "==", "Em Análise"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    console.error("Erro ao obter a contagem de eventos em análise:", error);
    throw error;
  }
};

export const getEventEssential = async (username) => {
  try {
    const eventRef = doc(db, "eventsData", username);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      return {
        name: eventData.name,
        username: eventData.username,
        sticker: eventData.sticker,
        address: {
          city: eventData.address.city,
        },
      };
    } else {
      console.error("Nenhum evento encontrado com o nome:", username);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do evento:", error);
    return null;
  }
};

export const validateUsername = async (username) => {
  const eventsRef = collection(db, "eventsData");
  const q = query(eventsRef, where("username", "==", username));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
};

export const getEventMyTicket = async (username) => {
  try {
    const eventRef = doc(db, "eventsData", username);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      return {
        name: eventData.name,
        username: eventData.username,
        eventImage: eventData.eventImage,
        dates: eventData.dates,
        address: eventData.address,
        status: eventData.status,
        absorbFee: eventData.absorbFee,
      };
    } else {
      console.error("Nenhum evento encontrado com o nome:", username);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do evento:", error);
    return null;
  }
};

export const getProducerStatistics = async (producerId) => {
  const eventosQuery = query(
    collection(db, "eventsData"),
    where("organizer.organizationId", "==", producerId),
    orderBy("dates.start", "asc")
  );

  const snapshot = await getDocs(eventosQuery);
  const eventos = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return eventos;
};

export const getEventUsername = async (username) => {
  try {
    if (!username) {
      throw new Error("Username não fornecido");
    }
    const eventsRef = collection(db, "eventsData");
    const q = query(eventsRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    }
    return null;
  } catch (error) {
    console.error("Erro ao obter os dados do evento:", error);
    throw error;
  }
};

export const getEventByNormalizedName = async (normalizedEventName) => {
  const eventDoc = await getDoc(doc(db, "eventsData", normalizedEventName));
  return eventDoc.exists() ? { id: eventDoc.id, ...eventDoc.data() } : null;
};

export const getEventLots = async (username) => {
  try {
    const event = await getEventUsername(username);
    if (event && event.lots) {
      return Object.keys(event.lots).map((lotKey) => event.lots[lotKey].name);
    }
    return [];
  } catch (error) {
    console.error("Erro ao obter os lotes do evento:", error);
    throw error;
  }
};

export const getProducerIDCity = async (producerId, status, lastDocument = null) => {
  try {
    let eventosQuery = query(
      collection(db, "eventsData"),
      where("organizer.organizationId", "==", producerId),
      where("status", "==", status),
      orderBy("dates.start", "asc"),
      limit(10)
    );

    if (lastDocument) {
      eventosQuery = query(eventosQuery, startAfter(lastDocument));
    }

    const snapshot = await getDocs(eventosQuery);
    const eventos = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }));

    return { eventos, lastDocument: snapshot.docs[snapshot.docs.length - 1] };
  } catch (error) {
    console.error("Erro ao obter eventos do produtor:", error);
    throw error;
  }
};

export const getEventCity = async (city, lastDocument = null) => {
  try {
    let eventosQuery;
    if (city !== "Todas as Cidades") {
      eventosQuery = query(
        collection(db, "eventsData"),
        where("address.city", "==", city),
        where("status", "==", "Publicado"),
        orderBy("dates.start", "asc"),
        limit(10)
      );
    } else {
      eventosQuery = query(
        collection(db, "eventsData"),
        where("status", "==", "Publicado"),
        orderBy("dates.start", "asc"),
        limit(10)
      );
    }

    if (lastDocument) {
      eventosQuery = query(eventosQuery, startAfter(lastDocument));
    }

    const snapshot = await getDocs(eventosQuery);
    const eventos = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }));

    return { eventos, lastDocument: snapshot.docs[snapshot.docs.length - 1] };
  } catch (error) {
    console.error("Erro ao obter eventos da cidade:", error);
    throw error;
  }
};

export const getEventStatus = async (status) => {
  try {
    const eventosQuery = query(
      collection(db, "eventsData"),
      where("status", "==", status),
      orderBy("dates.start", "asc"),
      limit(5)
    );

    const snapshot = await getDocs(eventosQuery);
    const eventos = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }));

    return eventos;
  } catch (error) {
    console.error("Erro ao obter eventos por nome normalizado:", error);
    throw error;
  }
};

export const getEventByDocument = async (lastDocument, status, userId, city = null) => {
  try {
    let eventosQuery;
    if (userId) {
      eventosQuery = query(
        collection(db, "eventsData"),
        where("organizer.organizationId", "==", userId),
        where("status", "==", status),
        orderBy("dates.start", "asc"),
        startAfter(lastDocument),
        limit(10)
      );
    } else if (city) {
      eventosQuery = query(
        collection(db, "eventsData"),
        where("address.city", "==", city),
        where("status", "==", status),
        orderBy("dates.start", "asc"),
        startAfter(lastDocument),
        limit(10)
      );
    } else {
      eventosQuery = query(
        collection(db, "eventsData"),
        where("status", "==", status),
        orderBy("dates.start", "asc"),
        startAfter(lastDocument),
        limit(10)
      );
    }

    const snapshot = await getDocs(eventosQuery);
    const eventos = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }));

    return eventos;
  } catch (error) {
    console.error("Erro ao carregar mais eventos:", error);
    throw error;
  }
};

export const buscarEventosPorTermo = async (termoPesquisa, isOrganizacao, userId) => {
  const eventosBusca = [];
  const colecaoEventos = collection(db, "eventsData");

  // Query para username
  let qEventosUsername = query(colecaoEventos, orderBy("username", "asc"));
  if (termoPesquisa) {
    qEventosUsername = query(
      qEventosUsername,
      startAt(termoPesquisa),
      endAt(termoPesquisa + "\uf8ff"),
      limit(10)
    );
  }

  termoPesquisa = cleanName(termoPesquisa);

  // Query para name
  let qEventosName = query(colecaoEventos, orderBy("searchName", "asc"));
  if (termoPesquisa) {
    qEventosName = query(
      qEventosName,
      startAt(termoPesquisa),
      endAt(termoPesquisa + "\uf8ff"),
      limit(10)
    );
  }

  // Aplicar filtros adicionais se necessário
  if (isOrganizacao) {
    qEventosUsername = query(
      qEventosUsername,
      where("organizer.organizationId", "==", userId)
    );
    qEventosName = query(
      qEventosName,
      where("organizer.organizationId", "==", userId)
    );
  } else {
    qEventosUsername = query(
      qEventosUsername,
      where("status", "==", "Publicado")
    );
    qEventosName = query(
      qEventosName,
      where("status", "==", "Publicado")
    );
  }

  // Executar as duas queries
  const eventosSnapshotUsername = await getDocs(qEventosUsername);
  const eventosSnapshotName = await getDocs(qEventosName);

  // Combinar resultados das duas queries
  eventosBusca.push(
    ...eventosSnapshotUsername.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }))
  );
  eventosBusca.push(
    ...eventosSnapshotName.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      eventImage: doc.data().eventImage,
      dates: doc.data().dates,
      address: doc.data().address,
      status: doc.data().status,
    }))
  );

  // Remover duplicatas
  const eventosBuscaUnicos = Array.from(
    new Set(eventosBusca.map((a) => a.id))
  ).map((id) => eventosBusca.find((a) => a.id === id));

  return { eventosBusca: eventosBuscaUnicos };
};

// Gets All

// Função para obter absorbFee
export const getEventAbsorbFee = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().absorbFee;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter absorbFee do evento:", error);
    return null;
  }
};

// Função para obter address
export const getEventAddress = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().address;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter address do evento:", error);
    return null;
  }
};

// Função para obter category
export const getEventCategory = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().category;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter category do evento:", error);
    return null;
  }
};

// Função para obter currentStep
export const getEventCurrentStep = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().currentStep;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter currentStep do evento:", error);
    return null;
  }
};

// Função para obter dates
export const getEventDates = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().dates;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter dates do evento:", error);
    return null;
  }
};

// Função para obter description
export const getEventDescription = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().description;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter description do evento:", error);
    return null;
  }
};

// Função para obter eventImage
export const getEventImage = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().eventImage;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter eventImage do evento:", error);
    return null;
  }
};

// Função para obter eventKey
export const getEventKey = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().eventKey;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter eventKey do evento:", error);
    return null;
  }
};

// Função para obter eventRules
export const getEventRules = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().eventRules;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter eventRules do evento:", error);
    return null;
  }
};

// // Função para obter lots
// export const getEventLots = async (eventUsernameDoc) => {
//   try {
//     const eventRef = doc(db, "eventsData", eventUsernameDoc);
//     const eventDoc = await getDoc(eventRef);

//     if (eventDoc.exists()) {
//       return eventDoc.data().lots;
//     } else {
//       console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
//       return null;
//     }
//   } catch (error) {
//     console.error("Erro ao obter lots do evento:", error);
//     return null;
//   }
// };

// Função para obter name
export const getEventName = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().name;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter name do evento:", error);
    return null;
  }
};

// Função para obter organizer
export const getEventOrganizer = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().organizer;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter organizer do evento:", error);
    return null;
  }
};

// Função para obter socialMedia
export const getEventSocialMedia = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().socialMedia;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter socialMedia do evento:", error);
    return null;
  }
};

// Função para obter readers
export const getEventReaders = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().readers;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter readers do evento:", error);
    return null;
  }
};

// Função para obter searchName
export const getEventSearchName = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().searchName;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter searchName do evento:", error);
    return null;
  }
};

// Função para obter showModal
export const getEventShowModal = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().showModal;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter showModal do evento:", error);
    return null;
  }
};


// Função para obter sticker
export const getEventSticker = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().sticker;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter sticker do evento:", error);
    return null;
  }
};

// Função para obter subject
export const getEventSubject = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().subject;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter subject do evento:", error);
    return null;
  }
};

// Função para obter ticketHistory
export const getEventTicketHistory = async (eventUsernameDoc) => {
  try {
    const eventRef = doc(db, "eventsData", eventUsernameDoc);
    const eventDoc = await getDoc(eventRef);

    if (eventDoc.exists()) {
      return eventDoc.data().ticketHistory;
    } else {
      console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter ticketHistory do evento:", error);
    return null;
  }
};

// // Função para obter username
// export const getEventUsername = async (eventUsernameDoc) => {
//   try {
//     const eventRef = doc(db, "eventsData", eventUsernameDoc);
//     const eventDoc = await getDoc(eventRef);

//     if (eventDoc.exists()) {
//       return eventDoc.data().username;
//     } else {
//       console.error("Nenhum evento encontrado com o nome:", eventUsernameDoc);
//       return null;
//     }
//   } catch (error) {
//     console.error("Erro ao obter username do evento:", error);
//     return null;
//   }
// };