import React from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { styles } from "../../../../styles/utils/terms/producerTerms/producerTermsStyles";
import Icon from "react-native-vector-icons/Ionicons";

export default function ProducerCookiePolicy({ navigation }) {
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" />
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Text style={styles.title}>Política de Cookies</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon
            name="person-outline"
            size={25}
            color="#000"
            style={styles.goBackButton}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.content}>
          <Text style={styles.sectionTitle}>
            POLÍTICA DE COOKIES PARA PRODUTORES DA PLATAFORMA E APLICATIVO RAYV
          </Text>
          <Text style={styles.sectionTitle}>1. A Plataforma</Text>
          <Text style={styles.body}>
            A RAYV é uma plataforma tecnológica disponível para produtores e
            casas de eventos, facilitando a venda de ingressos, inscrição para
            eventos, cobrança de contribuições para eventos e a gestão de
            participantes, acessível no endereço eletrônico www.rayv.com.br, ou
            por meio dos aplicativos oficiais. É uma pessoa jurídica de direito
            privado, inscrita no CNPJ sob o nº X, com endereço na X.
          </Text>
          <Text style={styles.sectionTitle}>2. Considerações sobre cookies</Text>
          <Text style={styles.body}>
            2.1. Cookies são arquivos que contêm um identificador (uma sequência
            de letras e números) enviados por um servidor para determinado
            navegador (browser) que o armazena. O identificador é então enviado
            de volta ao servidor sempre que o navegador tenta acessar uma página
            do servidor.
          </Text>
          <Text style={styles.body}>
            2.2. Os cookies podem ser “persistentes” ou “de sessão”. Um cookie
            persistente será armazenado por um navegador e permanecerá válido
            até sua data de validade, salvo se deletado pelo usuário antes desta
            data. Um cookie de sessão, por outro lado, irá expirar ao final de
            uma sessão de navegação do usuário, quando o navegador é encerrado.
          </Text>
          <Text style={styles.body}>
            2.3. A RAYV trata as informações obtidas por meio de cookies e
            outras tecnologias como informações não-pessoais. Entretanto, na
            medida em que o endereço IP ou identificadores semelhantes sejam
            considerados informações pessoais pela legislação aplicável, a RAYV
            tratará esses identificadores como informação pessoal.
          </Text>
          <Text style={styles.sectionTitle}>3. Utilização de cookies</Text>
          <Text style={styles.body}>
            3.1. A RAYV utiliza cookies e outras tecnologias para que consiga
            viabilizar a oferta dos produtos e serviços procurados e
            aprimorá-los. As informações coletadas podem ser sobre você, sobre o
            dispositivo utilizado para acesso ou sobre as suas preferências.
          </Text>
          <Text style={styles.body}>
            3.2. A RAYV poderá utilizar cookies das seguintes categorias:
          </Text>
          <Text style={styles.listItem}>
            a) Cookies estritamente necessários: são utilizados para que o
            website realize funções básicas, como autenticação do usuário para
            fornecimento dos recursos apropriados e a viabilização de
            ferramentas de segurança.
          </Text>
          <Text style={styles.listItem}>
            b) Cookies de desempenho: possibilitam a coleta de informações sobre
            a utilização do website pelos usuários, como as páginas visitadas
            com frequência e a ocorrência de erros. Assim, é possível melhorar a
            experiência, por exemplo, ajudando a rotear o tráfego entre
            servidores e perceber com qual velocidade o serviço é carregado para
            diferentes pessoas. Ocasionalmente, poderão ser armazenadas
            informações no navegador ou dispositivo para que os recursos em uso
            carreguem e respondam rapidamente.
          </Text>
          <Text style={styles.listItem}>
            c) Cookies de funcionalidade: permitem que o website forneça as
            funcionalidades e personalizações melhoradas, como lembrar as
            preferências do usuário. Caso você não permita esses cookies,
            algumas funcionalidades poderão não funcionar corretamente.
          </Text>
          <Text style={styles.listItem}>
            d) Cookies de publicidade: são utilizados com o objetivo de exibir
            anúncios a partir da coleta de informações relativas aos hábitos de
            navegação do usuário e da construção de perfis de interesse, de modo
            a medir a relevância desses anúncios para o usuário e aprimorar essa
            veiculação.
          </Text>
          <Text style={styles.body}>
            3.3. O usuário poderá gerenciar as suas preferências de cookies por
            meio do banner apresentado durante o acesso à Plataforma, podendo
            optar por desabilitar uma ou mais categorias conforme desejar, com
            exceção dos cookies estritamente necessários.
          </Text>
          <Text style={styles.body}>
            3.4. O usuário poderá, ainda, gerenciar as suas preferências de
            cookies e outras tecnologias semelhantes a partir da configuração de
            seu navegador ou dispositivo, recusando ou excluindo determinados
            cookies e outras tecnologias. Contudo, isso poderá comprometer a
            prestação dos serviços ou impedir o funcionamento de determinadas
            funcionalidades que os integram. Abaixo, listamos alguns endereços
            eletrônicos onde o usuário poderá gerenciar tais preferências:
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://support.google.com/chrome/answer/95647")}
            >
              https://support.google.com/chrome/answer/95647
            </Text>{" "}
            (Chrome);
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences")}
            >
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </Text>{" "}
            (Firefox);
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://www.opera.com/help/tutorials/security/cookies/")}
            >
              https://www.opera.com/help/tutorials/security/cookies/
            </Text>{" "}
            (Opera);
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies")}
            >
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </Text>{" "}
            (Internet Explorer);
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://support.apple.com/pt-br/guide/safari/sfri11471/mac")}
            >
              https://support.apple.com/pt-br/guide/safari/sfri11471/mac
            </Text>{" "}
            (Safari);
          </Text>
          <Text style={styles.body}>
            -{" "}
            <Text
              style={styles.link}
              onPress={() => Linking.openURL("https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy")}
            >
              https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            </Text>{" "}
            (Edge).
          </Text>
          <Text style={styles.sectionTitle}>4. Tecnologias de rastreamento</Text>
          <Text style={styles.body}>
            4.1. A RAYV poderá utilizar outras tecnologias de rastreamento, como
            web beacons (às vezes chamados de "pixels de rastreamento", “pixel
            tags” ou "gifs nítidos"). Essas tecnologias também ajudam os
            Produtores a saber mais sobre o interesse em determinados eventos.
            Em muitos casos, essas tecnologias dependem dos cookies para
            funcionar corretamente.
          </Text>
          <Text style={styles.body}>
            4.2. A RAYV poderá utilizar uma "URL click-through" vinculada ao
            conteúdo da plataforma RAYV. Ao clicar em uma dessas URL’s, os
            usuários poderão ter dados pessoais tratados, com base no legítimo
            interesse da RAYV, para entendermos o interesse em determinados
            tópicos e avaliar a eficácia das comunicações com os usuários.
          </Text>
          <Text style={styles.sectionTitle}>5. Disposições gerais</Text>
          <Text style={styles.body}>
            5.1. Ao navegar pela plataforma RAYV e utilizar suas
            funcionalidades, os usuários aceitam todo o disposto nesta Política
            e demais políticas legais que se encontram vigentes na data de
            acesso. Por isso, é recomendável que os usuários se mantenham
            atualizados.
          </Text>
          <Text style={styles.body}>
            5.2. A presente Política de Cookies está sujeita a constante
            melhoria e aprimoramento. Assim, a RAYV se reserva o direito de
            modificá-la a qualquer momento, conforme a finalidade da plataforma
            RAYV, tal qual para adequação e conformidade legal de disposição de
            lei ou norma que tenha força jurídica equivalente, cabendo aos
            usuários verificá-la sempre que efetuar o acesso à plataforma RAYV.
          </Text>
          <Text style={styles.body}>
            5.3. A eventual tolerância quanto a qualquer violação dos termos e
            condições do contido neste domínio será considerada mera
            liberalidade e não será interpretada como novação, precedente
            invocável, renúncia a direitos, alteração tácita dos termos
            contratuais, direito adquirido ou alteração contratual.
          </Text>
          <Text style={styles.body}>
             5.4. Caso alguma disposição desta Política for julgada inaplicável
            ou sem efeito, o restante das normas continuam a viger, sem a
            necessidade de medida judicial que declare tal assertiva. Os termos
            aqui descritos serão interpretados segundo a legislação brasileira.
          </Text>
          <Text style={styles.body}>
            5.5. A comunicação entre a RAYV e o usuário deverá ser realizada
            pelos canais de atendimento indicados e disponibilizados na
            plataforma RAYV.
          </Text>
          <Text style={styles.body}>
            5.6. Sempre que possível, potenciais divergências entre o usuário e
            a RAYV serão resolvidas de forma amigável. Quando todos os esforços
            neste sentido forem esgotados, você concorda, desde já, que fica
            eleito o foro da comarca de São João Del Rei, no Estado de Minas
            Gerais, para resolver controvérsias ou queixas oriundas da
            utilização de nossa plataforma ou relacionadas a esta Política de
            Cookies.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
