import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { getUserByUsername, getUserEssential } from "../../../services/cruds/users/getUser";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../services/utils/firebase/firebaseConfig";

const LinkProfile = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { username } = route.params;
  const [currentUser, setCurrentUser] = useState(null);
  console.log(route.params);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("HomeTabs");
    }
  };

  const loadUser = async (usernameUser) => {
    try {
      const visitUser = await getUserByUsername(username);
      if (visitUser) {
          if(visitUser.username == usernameUser){
              navigation.navigate("HomeTabs");
          } else {
              navigation.replace("VisitProfile", { usuario: visitUser, currentUser });
          }
      } else {
        console.error("Evento não encontrado.");
        handleGoBack();
      }
    } catch (error) {
      console.error("Erro ao carregar evento:", error);
      handleGoBack();
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            user = await getUserEssential(user.uid);
            setCurrentUser(user);
            loadUser(user.username);
        } else {
            loadUser();
            console.log("Nenhum usuário conectado");
        }
    });
  }, [username]);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000" }}>
      <ActivityIndicator size="large" />
      <Text>Carregando usuario...</Text>
    </View>
  );
};

export default LinkProfile;
