import React, { createContext, useState, useEffect, useContext } from "react";
import moment from "moment-timezone";
import Constants from "expo-constants";
import { onAppConfigMaintenanceSnapshot } from "../services/cruds/appConfig/maintenance/getAppConfigMaintenance";
import { onAppVersionSnapshot } from "../services/cruds/appConfig/appVersion/getAppConfigAppVersion";
import { onSecuritySnapshot } from "../services/cruds/appConfig/security/getAppConfigSecurity";

const MaintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState("");
  const [updateRequired, setUpdateRequired] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [lockMessage, setLockMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeMaintenance = onAppConfigMaintenanceSnapshot((data) => {
      if (data) {
        const { isUnderMaintenance, message, startTime, endTime } = data;

        // Convertendo os Timestamps do Firebase para objetos moment
        const currentTime = moment().tz("America/Sao_Paulo");
        const startMoment = moment
          .unix(startTime.seconds)
          .tz("America/Sao_Paulo");
        const endMoment = moment.unix(endTime.seconds).tz("America/Sao_Paulo");

        if (
          isUnderMaintenance &&
          currentTime.isBetween(startMoment, endMoment)
        ) {
          setIsUnderMaintenance(true);
          setMaintenanceMessage(message);
        } else {
          setIsUnderMaintenance(false);
        }
      } else {
        setIsUnderMaintenance(false);
      }
    });

    const unsubscribeAppVersion = onAppVersionSnapshot((data) => {
      if (data) {
        const { minSupportedVersion, updateMessage } = data;
        const currentVersion = Constants.expoConfig.version;

        if (compareVersions(currentVersion, minSupportedVersion) < 0) {
          setUpdateRequired(true);
          setUpdateMessage(updateMessage);
        } else {
          setUpdateRequired(false);
        }
      }
    });

    const unsubscribeSecurity = onSecuritySnapshot((data) => {
      if (data) {
        const { isLocked, lockMessage } = data;
        setIsLocked(isLocked);
        setLockMessage(lockMessage);
      }
    });

    setLoading(false);

    return () => {
      unsubscribeMaintenance();
      unsubscribeAppVersion();
      unsubscribeSecurity();
    };
  }, []);

  const compareVersions = (v1, v2) => {
    const v1parts = v1.split(".").map(Number);
    const v2parts = v2.split(".").map(Number);

    for (let i = 0; i < Math.max(v1parts.length, v2parts.length); i++) {
      const v1part = v1parts[i] || 0;
      const v2part = v2parts[i] || 0;

      if (v1part > v2part) return 1;
      if (v1part < v2part) return -1;
    }
    return 0;
  };

  return (
    <MaintenanceContext.Provider
      value={{
        isUnderMaintenance,
        maintenanceMessage,
        updateRequired,
        updateMessage,
        isLocked,
        lockMessage,
        loading,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};

export const useMaintenance = () => useContext(MaintenanceContext);
