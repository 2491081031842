import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase/firebaseConfig";

export const onSecuritySnapshot = (callback) => {
  try {
    const securityRef = doc(db, "appConfig", "security");
    return onSnapshot(securityRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        callback({
          isLocked: data.isLocked,
          lockMessage: data.lockMessage,
        });
      } else {
        callback(null);
      }
    });
  } catch (error) {
    console.error("Erro ao escutar dados de segurança:", error);
    throw error;
  }
};
