import React from 'react';
import { View, StyleSheet } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';

const FeaturedEventSkeleton = () => (
  <View style={styles.skeletonContainer}>
    <ContentLoader
      speed={1}
      width={320}
      height={166}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Rect x="0" y="0" rx="5" ry="5" width="320" height="180" />
    </ContentLoader>
    <View style={styles.textContainer}>
      <ContentLoader
        speed={1}
        width={320}
        height={50}
        backgroundColor="#3d3d3d"
        foregroundColor="#1c1c1c"
      >
        <Rect x="0" y="0" rx="5" ry="5" width="200" height="20" />
        <Rect x="0" y="30" rx="5" ry="5" width="150" height="20" />
      </ContentLoader>
    </View>
  </View>
);

const styles = StyleSheet.create({
  skeletonContainer: {
    marginVertical: 10,
    marginHorizontal: 4,
  },
  textContainer: {
    padding: 5,
  },
});

export default FeaturedEventSkeleton;
