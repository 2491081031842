import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";

const OrganizationSection = ({ userData, uid, fullName, avatarUrl, navigation }) => {
  const handlePressOrganizer = () => {
    navigation.navigate("VisitProfile", { usuario: userData, currentUser: uid, isHeader: true });
  };

  const averageRating = userData.reviews ? userData.reviews.rating : 0;

  const renderStars = () => {
    const filledStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);

    return (
      <View style={styles.starsContainer}>
        {Array(filledStars)
          .fill()
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star" size={18} color="#FFF" />
          ))}
        {halfStar && <MaterialCommunityIcons name="star-half-full" size={18} color="#FFF" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star-outline" size={18} color="#FFF" />
          ))}
      </View>
    );
  };

  return (
    <View>
      <View style={styles.sectionDividerSecond}></View>
      <TouchableOpacity style={styles.containerOrganization} onPress={handlePressOrganizer}>
        <Image source={avatarUrl !== "" ? { uri: avatarUrl } : defaultAvatarImage} style={styles.organizerAvatar} />
        <View style={styles.textContainer}>
          <Text style={styles.preText}>Evento organizado por</Text>
          <Text style={styles.organizerName}>{fullName}</Text>
        </View>
        <View style={styles.infoContainer}>
          {renderStars()}
        </View>
      </TouchableOpacity>
      <View style={styles.sectionDividerSecond}></View>
    </View>
  );
};

export default OrganizationSection;
