import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#121212",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  settingTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#FFF",
  },
  settingSubtitle: {
    fontSize: 14,
    color: "#AAA",
  },
  settingArrow: {
    color: "#FFF",
  },
});
