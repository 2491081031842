import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  TextInput,
  Modal,
  ActivityIndicator,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import Header from "../../components/general/home_search/Header"; // Certifique-se de que o caminho para o Header está correto
import { getAppConfigContact } from "../../services/cruds/appConfig/contact/getAppConfigContact";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Linking from "expo-linking";
import { styles } from "../../styles/general/SettingsStyles";
import toastConfig from "../../components/utils/ToastConfig";
import SettingsSkeleton from "../../components/general/settings/SettingsSkeleton";

const Settings = ({ navigation }) => {
  const [state, setState] = useState({
    showInstagramBanner: true,
    suggestion: "",
    isLoading: true,
  });

  const [loading, setLoading] = useState({
    submitSuggestion: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  }, []);

  const onSettingsPress = () => {
    navigation.navigate("Settings");
  };

  const onDownloadPress = () => {
    navigation.navigate("DownloadAppScreen");
  };

  const onHomePress = () => {
    navigation.navigate("Home");
  };

  const onCreateEventPress = () => {
    navigation.navigate("DownloadAppScreen");
  };

  const onLoginPress = () => {
    navigation.navigate("DownloadAppScreen");
  };

  const onSearchPress = () => {
    navigation.navigate("Search");
  };

  const openWhatsApp = async () => {
    try {
      const configData = await getAppConfigContact();
      if (configData) {
        const phoneNumber = configData.whatsappNumber;
        if (phoneNumber) {
          const url = `https://wa.me/${phoneNumber}`;
          Linking.canOpenURL(url)
            .then((supported) => {
              if (supported) {
                return Linking.openURL(url);
              } else {
                showToast(
                  "error",
                  "Erro",
                  "Não foi possível abrir o WhatsApp."
                );
              }
            })
            .catch((err) => console.error("Erro ao abrir o WhatsApp", err));
        } else {
          console.error("Número de WhatsApp não disponível.");
        }
      } else {
        console.error(
          "Documento de configuração do aplicativo não encontrado."
        );
      }
    } catch (err) {
      console.error("Erro ao buscar número de WhatsApp", err);
    }
  };

  const handleFollowInstagram = async () => {
    try {
      const configData = await getAppConfigContact();
      if (configData) {
        const instagramUrl = configData.instagramLink;
        if (instagramUrl) {
          Linking.openURL(instagramUrl).catch((err) =>
            console.error("Erro ao abrir Instagram", err)
          );
        } else {
          console.error("Link do Instagram não disponível.");
        }
      } else {
        console.error(
          "Documento de configuração do aplicativo não encontrado."
        );
      }
    } catch (err) {
      console.error("Erro ao buscar link do Instagram", err);
    }
  };

  const closeInstagramBanner = () => {
    setState((prevState) => ({ ...prevState, showInstagramBanner: false }));
  };

  const submitSuggestion = async () => {
    const { suggestion } = state;
    if (!suggestion) {
      showToast("error", "Erro", "Por favor, forneça uma sugestão.");
      return;
    }
    setLoading((prev) => ({ ...prev, submitSuggestion: true }));
    try {
      await addUserSuggestion(suggestion);
      showToast(
        "success",
        "Sugestão Enviada",
        "Sua sugestão foi enviada com sucesso. Agradecemos seu feedback!"
      );
      setState((prevState) => ({ ...prevState, suggestion: "" }));
    } catch (error) {
      console.error("Erro ao enviar sugestão: ", error);
      showToast("error", "Erro", "Não foi possível enviar sua sugestão.");
    } finally {
      setLoading((prev) => ({ ...prev, submitSuggestion: false }));
    }
  };

  const showToast = (type, text1, text2) => {
    Toast.show({
      type,
      text1,
      text2,
      position: "bottom",
      visibilityTime: 4000,
      autoHide: true,
      topOffset: 30,
      bottomOffset: 40,
    });
  };

  const {
    showInstagramBanner,
    suggestion,
    isLoading,
  } = state;
  const showSuggestionBanner = !showInstagramBanner;

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <Header
          navigation={navigation} 
          onDownloadPress={onDownloadPress}
          onSettingsPress={onSettingsPress}
          onHomePress={onHomePress}
          onSearchPress={onSearchPress}
          onCreateEventPress={onCreateEventPress} 
          onLoginPress={onLoginPress}
          visible={true}
        />
      </View>
      {isLoading ? (
        <SettingsSkeleton />
      ) : (
        <ScrollView>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => navigation.navigate("Faq")}
          >
            <MaterialCommunityIcons
              name="help-network-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>FAQ</Text>
              <Text style={styles.settingSubtitle}>
                Obtenha ajuda e encontre respostas
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.settingItem} onPress={openWhatsApp}>
            <MaterialIcons name="chat" size={24} style={styles.settingIcon} />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>Suporte</Text>
              <Text style={styles.settingSubtitle}>
                Entre em contato conosco para obter ajuda
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => navigation.navigate("Terms")}
          >
            <MaterialCommunityIcons
              name="file-document-multiple-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>Termos e Condições</Text>
              <Text style={styles.settingSubtitle}>
                Leia nossos termos de serviço
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
          {showInstagramBanner && (
            <View style={styles.instagramBanner}>
              <Text style={styles.instagramBannerText}>
                Venha seguir nosso Instagram! Prêmios e recompensas aguardam
                você!
              </Text>
              <View style={styles.instagramBannerButtons}>
                <TouchableOpacity
                  style={styles.instagramFollowButton}
                  onPress={handleFollowInstagram}
                >
                  <Text style={styles.instagramFollowButtonText}>Seguir</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.instagramCloseButton}
                  onPress={closeInstagramBanner}
                >
                  <Text style={styles.instagramCloseButtonText}>Fechar</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          {showSuggestionBanner && (
            <View style={styles.suggestionBanner}>
              <Text style={styles.suggestionBannerText}>
                Encontrou algum erro ou quer que adicionemos alguma informação?
                Sugira no campo abaixo:
              </Text>
              <TextInput
                style={styles.suggestionInput}
                placeholder="Digite sua sugestão aqui"
                placeholderTextColor="#999"
                value={suggestion}
                onChangeText={(text) =>
                  setState((prevState) => ({ ...prevState, suggestion: text }))
                }
              />
              <View style={styles.suggestionBannerButtons}>
                <TouchableOpacity
                  style={[
                    styles.suggestionSubmitButton,
                    loading.submitSuggestion && styles.buttonDisabled,
                  ]}
                  onPress={submitSuggestion}
                  disabled={loading.submitSuggestion}
                >
                  {loading.submitSuggestion ? (
                    <ActivityIndicator size="small" color="#FFF" />
                  ) : (
                    <Text style={styles.suggestionSubmitButtonText}>
                      Enviar
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          )}
        </ScrollView>
      )}
      <Toast config={toastConfig} />
    </SafeAreaView>
  );
};

export default Settings;
