import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#000",
  },
  headerNavBar: {
    backgroundColor: "#000",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#000",
    paddingHorizontal: 15,
  },
  titleAndSubtitleContainer: {
    flexDirection: "column",
  },
  titleText: {
    textAlign: "left",
    fontSize: 22,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginRight: 8,
  },
  infoContainer: {
    paddingLeft: 8,
    flexDirection: "row",
  },
  infoIcon: {
    right: 4,
    top: 4,
  },
  subtitleText: {
    textAlign: "left",
    fontSize: 14,
    color: "#999",
  },
  iconsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 82.5,
  },
  iconsContainerSecond: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 55,
  },
  tabBeginContainer: {
    flex: 1,
    minHeight: 400,
    backgroundColor: "#000",
  },
  tabContainer: {
    padding: 5,
    backgroundColor: "#000",
    marginHorizontal: 10,
    marginTop: 5,
    borderRadius: 10,
  },
  tabHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  tabTitle: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 10,
  },
  descriptionContainer: {
    padding: 10,
    marginBottom: 5,
    backgroundColor: "#000",
  },
  descriptionText: {
    color: "#FFFD",
    fontSize: 14,
  },
  seeMoreText: {
    color: "#1E90FF",
    fontSize: 14,
    marginTop: 5,
  },
  localContainer: {
    padding: 10,
    backgroundColor: "#000",
  },
  localRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  localText: {
    color: "#CCC",
    fontSize: 14,
    marginLeft: 5,
  },
  policyContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#000",
  },
  policyHeader: {
    flexDirection: "row",
    alignItems: "center",
  },
  policyTitle: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 10,
  },
  policyText: {
    color: "#FFFD",
    fontSize: 14,
    padding: 10,
  },
  noPolicyText: {
    fontSize: 16,
    color: "#AAA",
    marginLeft: 10,
    marginBottom: 10,
    textAlign: "center",
  },
  buyButtonContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderColor: "#333",
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    backgroundColor: "#121212",
  },
  priceAndButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoAndPriceContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  buyImage: {
    width: 40,
    height: 40,
    marginRight: 4,
  },
  textContainer: {
    flex: 1,
    marginLeft: 10,
  },
  priceText: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
  },
  priceDetailText: {
    color: "#999",
    fontSize: 12,
  },
  buttonContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  buyButton: {
    backgroundColor: "#1A2035",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 8,
    marginBottom: 3,
  },
  buyButtonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "bold",
  },
  removeButton: {
    backgroundColor: "#1c1c1e",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 5,
    paddingHorizontal: 25,
    borderRadius: 8,
  },
  removeButtonText: {
    color: "#FFF",
    fontSize: 12,
    fontWeight: "bold",
  },
  cardContainer: {
    flexDirection: "column",
    padding: 12,
    backgroundColor: "#000",
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    backgroundColor: "transparent",
    padding: 2,
    borderRadius: 8,
  },
  icon: {
    color: "#FFF",
    marginRight: 10,
  },
  textContent: {
    flex: 1,
  },
  cardText: {
    fontSize: 14,
    color: "#FFF",
    fontWeight: "bold",
  },
  cardSubText: {
    fontSize: 12,
    color: "#999",
    marginTop: 2,
  },
  mapButton: {
    backgroundColor: "#111",
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mapButtonDown: {
    backgroundColor: "#111",
    paddingHorizontal: 12,
    paddingVertical: 10,
    marginTop: 10,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mapButtonText: {
    color: "#FFF",
    fontWeight: "bold",
    marginLeft: 5,
    fontSize: 12,
  },
  containerOrganization: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#000",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  organizerAvatar: {
    width: 50,
    height: 50,
    marginRight: 15,
    borderRadius: 25,
  },
  textContainer: {
    flex: 1,
    flexDirection: "column",
  },
  preText: {
    color: "#888",
    fontSize: 14,
  },
  organizerName: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 1,
  },
  sectionHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 15,
    paddingVertical: 8,
  },
  sectionIcon: {
    marginRight: 10,
  },
  sectionIconSecond: {
    marginLeft: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  sectionDivider: {
    flex: 1,
    height: 1,
    backgroundColor: "#444",
    marginLeft: 10,
  },
  stickerContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#000",
    marginHorizontal: 15,
    marginVertical: 10,
  },
  stickerImage: {
    width: 60,
    height: 60,
    borderRadius: 5,
    marginRight: 5,
  },
  stickerDetails: {
    flex: 1,
  },
  stickerTitle: {
    color: "#FFF",
    textAlign: "right",
    fontWeight: "bold",
    fontSize: 16,
    marginRight: 20,
  },
  stickerDescription: {
    color: "#999",
    textAlign: "right",
    marginRight: 20,
    fontSize: 12,
  },
  lotTitleText: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFF",
  },
  lotSelectionContainer: {
    backgroundColor: "#000",
  },
  lotCard: {
    backgroundColor: "#121212",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderColor: "#121212",
    borderWidth: 1,
  },
  lotDetailContainer: {
    flex: 1,
  },
  lotName: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  lotPrice: {
    color: "#999",
    fontWeight: "bold",
    fontSize: 14,
  },
  taxInfo: {
    color: "#888",
    fontSize: 10,
    marginTop: 2,
  },
  lotAvailable: {
    color: "#777",
    fontSize: 10,
    marginTop: 2,
  },
  lotSoldOut: {
    color: "#777",
    fontSize: 10,
    marginTop: 2,
  },
  lotControls: {
    flexDirection: "row",
    alignItems: "center",
  },
  controlButton: {
    backgroundColor: "transparent",
    color: "#FFF",
    marginHorizontal: 5,
  },
  controlButtonPlus: {
    backgroundColor: "#FFF",
    borderRadius: 30,
    marginHorizontal: 5,
  },
  quantity: {
    color: "#999",
    fontSize: 16,
    marginHorizontal: 10,
  },
  modalContent: {
    backgroundColor: "#222",
    padding: 20,
    borderRadius: 20,
    marginHorizontal: 20,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#FFF",
  },
  modalTotal: {
    fontSize: 18,
    color: "#FFF",
    marginBottom: 20,
  },
  modalCloseButton: {
    backgroundColor: "#333",
    padding: 10,
    borderRadius: 15,
  },
  modalCloseButtonText: {
    color: "#FFF",
    fontSize: 16,
  },
  tabButton: {
    padding: 10,
    alignItems: "center",
  },
  tabText: {
    color: "#BBB",
    fontSize: 16,
  },
  tabTextAtivo: {
    color: "#FFF",
    fontWeight: "bold",
  },
  organizerText: {
    color: "#CCC",
    fontSize: 14,
  },
  brasiliaTime: {
    fontSize: 10,
    color: "#555",
    marginTop: 2,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    borderRadius: 10,
    padding: 10,
  },
  modalImage: {
    width: 200,
    height: 200,
    borderRadius: 10,
    resizeMode: "cover",
  },
  socialMediaContainer: {
    padding: 10,
    backgroundColor: "#000",
    borderRadius: 10,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  socialMediaContainerRow: {
    flexDirection: "row",
    padding: 10,
    backgroundColor: "#000",
  },
  socialMediaButton: {
    alignItems: "center",
    marginRight: 10,
    backgroundColor: "#000",
  },
  socialMediaButtonText: {
    color: "#FFF",
    marginLeft: 10,
    fontSize: 16,
  },
  sectionDividerSecond: {
    flex: 1,
    height: 1,
    backgroundColor: "#121212",
    marginVertical: 5,
  },
  sectionDividerTab: {
    height: 0.5,
    width: "30%",
    backgroundColor: "#333",
    marginVertical: 3,
  },
  contactContainer: {
    padding: 10,
    backgroundColor: "#000",
    borderRadius: 10,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  contactContainerRow: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  contactButton: {
    alignItems: "flex-start",
    backgroundColor: "#000",
    marginLeft: 10,
    marginTop: 10,
  },
  contactButtonText: {
    color: "#FFFD",
    marginLeft: 10,
    fontSize: 14,
  },
  feesContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#000",
    padding: 15,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  feesDetails: {
    flex: 1,
  },
  feesTitle: {
    color: "#FFF",
    marginLeft: 15,
    fontSize: 16,
    fontWeight: "bold",
  },
  feesDescription: {
    color: "#A7A7A7",
    marginLeft: 15,
    fontSize: 14,
  },
  buyersContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingTop: 10,
    backgroundColor: "#000",
    borderRadius: 10,
  },
  buyersImagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  buyerAvatar: {
    width: 30,
    height: 30,
    borderRadius: 15,
    borderColor: "#000",
    borderWidth: 2,
  },
  moreBuyersContainer: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#555",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -10,
  },
  moreBuyersText: {
    color: "#FFF",
    fontSize: 12,
  },
  buyersText: {
    color: "#FFF",
    fontSize: 12,
    alignSelf: "center",
  },
  buyerItem: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#333",
  },
  buyerItemAvatar: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  buyerItemColumn: {
    flexDirection: "column",
  },
  buyerItemName: {
    fontSize: 16,
    color: "#FFF",
  },
  buyerItemUsername: {
    fontSize: 12,
    color: "#FFF9",
  },
  modalContainerFullBuyer: {
    flex: 1,
    backgroundColor: "#000",
  },
  modalContentFullBuyer: {
    flex: 1,
    paddingTop: 30,
    paddingHorizontal: 20,
  },
  modalCloseButtonBuyer: {
    position: "absolute",
    top: 30,
    right: 10,
  },
  modalTitleBuyer: {
    fontSize: 18,
    color: "#FFF",
    fontWeight: "bold",
    marginBottom: 10,
  },
  messageContainer: {
    padding: 10,
    backgroundColor: "#121212",
    borderRadius: 5,
    marginBottom: 5,
  },
  messageText: {
    fontSize: 14,
    color: "#fff",
  },
  messageTimestamp: {
    fontSize: 10,
    marginTop: 5,
    color: "#666",
    textAlign: "right",
  },
  noMessageText: {
    textAlign: "center",
    marginTop: 20,
    fontSize: 16,
    color: "#666",
  },
  starsContainer: {
    flexDirection: "row",
  },
  noLotsContainer: {
    flexDirection: "row",
    alignItems: 'center',
    margin: 20,
    marginLeft: 25,
  },
  noLotsText: {
    fontSize: 16,
    marginLeft: 5,
    color: '#FFFD',
  },
});
