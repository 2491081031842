import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../utils/firebase/firebaseConfig";

export const getAppConfigContact = async () => {
  try {
    const configDoc = await getDoc(doc(db, "appConfig", "contact"));
    return configDoc.exists() ? configDoc.data() : null;
  } catch (error) {
    console.error("Erro ao buscar dados de contato:", error);
    throw error;
  }
};
