import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

const getChallenge = async (accountType, challengeId) => {
  try {
    const type = accountType === "User" ? "client" : "producer";
    const challengeDoc = await getDoc(doc(db, `challengesData`, type));
    if (challengeDoc.exists()) {
      const challengeData = challengeDoc.data();
      if (challengeData && challengeData[challengeId]) {
        return challengeData[challengeId];
      } else {
        console.error("Nenhum desafio encontrado com o ID fornecido");
        return null;
      }
    } else {
      console.error("Nenhum desafio encontrado com o ID fornecido");
      return null;
    }
  } catch (error) {
    console.error("Erro ao buscar desafio:", error);
    return null;
  }
};

export default getChallenge;
