import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase/firebaseConfig";

export const onAppVersionSnapshot = (callback) => {
  try {
    const appVersionRef = doc(db, "appConfig", "appVersion");
    return onSnapshot(appVersionRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        callback({
          minSupportedVersion: data.minSupportedVersion,
          updateMessage: data.updateMessage,
        });
      } else {
        callback(null);
      }
    });
  } catch (error) {
    console.error("Erro ao escutar dados de versão do aplicativo:", error);
    throw error;
  }
};
