import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  RefreshControl,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import ListEventUCard from "../../components/general/home_search/ListEventUCard";
import ListUserCard from "../../components/general/search/ListUserCard";
import SearchBar from "../../components/general/search/SearchBar";
import Header from "../../components/general/home_search/Header";
import { styles } from "../../styles/general/SearchStyles";
import { buscarUsuariosPorTermo } from "../../services/cruds/users/getUser";
import { buscarEventosPorTermo } from "../../services/cruds/events/getEvent";
import ListUserSkeleton from "../../components/general/search/ListUserSkeleton";
import ListEventUSkeleton from "../../components/general/home_search/ListEventUSkeleton";

const Tab = createMaterialTopTabNavigator();

export default class Search extends Component {
  state = {
    refreshing: false,
    termoPesquisa: "",
    eventosBusca: [],
    usuariosBusca: [],
    carregandoMais: false,
    loadingInitial: true,
    activeTab: "Eventos",
    hasLoadedOnce: false, // Novo estado para rastrear se a tela já foi carregada
    hasLoadedEventos: false, // Novo estado para rastrear se os eventos já foram carregados
    hasLoadedUsuarios: false, // Novo estado para rastrear se os usuários já foram carregados
  };

  componentDidMount() {
    this.onRefresh();
    this.focusListener = this.props.navigation.addListener("focus", this.handleFocus);
  }

  componentWillUnmount() {
    if (this.focusListener) {
      this.focusListener();
    }
  }

  handleFocus = () => {
    if (!this.state.hasLoadedOnce) {
      this.onRefresh();
    }
  };

  buscarEventos = async (termoPesquisa) => {
    try {
      const response = await buscarEventosPorTermo(termoPesquisa);
      const { eventosBusca } = response;

      this.setState({
        eventosBusca: eventosBusca.sort((a, b) => {
          const order = ["Rascunho", "Em Análise", "Publicado", "Finalizado"];
          return order.indexOf(a.status) - order.indexOf(b.status);
        }),
        loadingInitial: false,
        hasLoadedOnce: true,
        hasLoadedEventos: true,
      });

      return eventosBusca;
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
      this.setState({ loadingInitial: false });
      return [];
    }
  };

  buscarUsuarios = async (termoPesquisa) => {
    try {
      const response = await buscarUsuariosPorTermo(termoPesquisa);
      const { usuariosBusca } = response;

      this.setState({
        usuariosBusca,
        loadingInitial: false,
        hasLoadedOnce: true,
        hasLoadedUsuarios: true,
      });

      return usuariosBusca;
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      this.setState({ loadingInitial: false });
      return [];
    }
  };

  renderCard = (item) => {
    if (!item) return null;
    return (
      <View style={styles.background}>
        <ListEventUCard
          evento={item}
          onPressCard={() =>
            this.props.navigation.navigate("Ticket", { evento: item })
          }
        />
      </View>
    );
  };

  renderFooter = () => {
    if (!this.state.carregandoMais) return null;
  };

  renderEmpty = () => {
    const { activeTab, loadingInitial } = this.state;
    if (loadingInitial) {
      return null;
    }
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.emptyText}>
          {activeTab === "Eventos"
            ? "Nenhum evento encontrado."
            : "Nenhum usuário encontrado."}
        </Text>
      </View>
    );
  };

  onSettingsPress = async () => {
    this.props.navigation.navigate("Settings");
  };

  onHomePress = () => {
    this.props.navigation.navigate("Home");
  };

  onCreateEventPress = () => {
    this.props.navigation.navigate("DownloadAppScreen");
  };

  onLoginPress = () => {
    this.props.navigation.navigate("DownloadAppScreen");
  };

  onSearchPress = () => {
    this.props.navigation.navigate("Search");
  };

  handleTabChange = (tabName) => {
    this.setState({ activeTab: tabName });
    this.atualizarTermoPesquisa(this.state.termoPesquisa, tabName);
  };

  atualizarTermoPesquisa = (termo, tabName = this.state.activeTab) => {
    this.setState({ termoPesquisa: termo }, () => {
      if (tabName === "Eventos") {
        this.buscarEventos(termo);
      } else {
        this.buscarUsuarios(termo);
      }
    });
  };

  onRefresh = async () => {
    this.setState({ refreshing: true });
    try {
      if (this.state.activeTab === "Eventos") {
        const eventosBusca = await this.buscarEventos(this.state.termoPesquisa);
        this.setState({ eventosBusca });
      } else {
        const usuariosBusca = await this.buscarUsuarios(this.state.termoPesquisa);
        this.setState({ usuariosBusca });
      }
    } catch (error) {
      console.error("Erro ao realizar o refresh:", error);
    } finally {
      this.setState({ refreshing: false });
    }
  };

  render() {
    const { eventosBusca, usuariosBusca, refreshing, loadingInitial, activeTab } = this.state;
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Header
            navigation={navigation} 
            onSettingsPress={this.onSettingsPress}
            onHomePress={this.onHomePress}
            onSearchPress={this.onSearchPress}
            onCreateEventPress={this.onCreateEventPress} 
            onLoginPress={this.onLoginPress}
            visible="true"
          />
        </View>
        <View style={styles.searchBar}>
          <SearchBar
            placeholder={activeTab === "Eventos" ? "Pesquisar Eventos" : "Pesquisar Usuários"}
            value={this.state.termoPesquisa}
            onChangeText={(text) => {
              this.setState({ termoPesquisa: text, hasLoadedEventos: false, hasLoadedUsuarios: false }, () => {
                this.atualizarTermoPesquisa(text);
              });
            }}
          />
        </View>
        <Tab.Navigator
          screenOptions={{
            tabBarStyle: { backgroundColor: "#000" },
            tabBarActiveTintColor: "#FFF",
            tabBarInactiveTintColor: "#A7A7A7",
            tabBarIndicatorStyle: {
              backgroundColor: "#FFF",
              height: 1,
              bottom: 10,
            },
            tabBarLabelStyle: {
              fontSize: 13,
              fontWeight: "bold",
              paddingBottom: 0,
            },
          }}
          screenListeners={({ navigation, route }) => ({
            state: (e) => {
              const index = e.data.state.index;
              const tabName = index === 0 ? "Eventos" : "Usuários";
              this.handleTabChange(tabName);
            },
          })}
        >
          <Tab.Screen
            name="Eventos"
            listeners={{
              tabPress: () => {
                this.handleTabChange("Eventos");
              },
            }}
            children={() => (
              <View style={styles.container}>
                <FlatList
                  data={loadingInitial ? [1, 2, 3, 4, 5] : eventosBusca}
                  renderItem={({ item }) =>
                    loadingInitial ? (
                      <ListEventUSkeleton />
                    ) : (
                      this.renderCard(item)
                    )
                  }
                  keyExtractor={(item, index) =>
                    loadingInitial ? index.toString() : item.username.toString()
                  }
                  ListFooterComponent={this.renderFooter}
                  ListEmptyComponent={this.renderEmpty}
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshing}
                      onRefresh={this.onRefresh}
                    />
                  }
                />
              </View>
            )}
          />
          <Tab.Screen
            name="Usuários"
            listeners={{
              tabPress: () => {
                this.handleTabChange("Usuários");
              },
            }}
            children={() => (
              <View style={styles.container}>
                <FlatList
                  data={loadingInitial ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : usuariosBusca}
                  renderItem={({ item }) =>
                    loadingInitial ? (
                      <ListUserSkeleton />
                    ) : (
                      <ListUserCard
                        usuario={item}
                        onPressItem={() =>
                          this.props.navigation.navigate("VisitProfile", {
                            usuario: item,
                          })
                        }
                      />
                    )
                  }
                  keyExtractor={(item, index) =>
                    loadingInitial ? index.toString() : item.username.toString()
                  }
                  ListFooterComponent={this.renderFooter}
                  ListEmptyComponent={this.renderEmpty}
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshing}
                      onRefresh={this.onRefresh}
                    />
                  }
                />
              </View>
            )}
          />
        </Tab.Navigator>
      </SafeAreaView>
    );
  }
}
