import React, { useState } from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import ContentLoader, { Rect } from 'react-content-loader/native';

const Card = ({ evento, onPressFavorite, onPressCard, isFavorite }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const dataEvento = new Date(evento.dates.start.seconds * 1000);
  // Formate a data para o formato '07 de março'
  const opcoes = { day: "2-digit", month: "long" };
  const dataFormatada = dataEvento
    .toLocaleDateString("pt-BR", opcoes)
    .replace(/ de /, " ");

  const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
  const eventImage =
    evento.eventImage !== "default_image.png"
      ? { uri: evento.eventImage }
      : defaultImageEvent;

  return (
    <TouchableOpacity style={styles.card} onPress={onPressCard}>
      <View style={styles.imageContainer}>
        {!imageLoaded && (
          <ContentLoader
            speed={1}
            width={160}
            height={90}
            backgroundColor="#3d3d3d"
            foregroundColor="#1c1c1c"
          >
            <Rect x="0" y="0" rx="8" ry="8" width="160" height="90" />
          </ContentLoader>
        )}
        <Image
          source={eventImage}
          style={styles.cardImage}
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)} // Para garantir que o estado seja atualizado mesmo em caso de erro no carregamento
        />
      </View>
      <View style={styles.cardDetails}>
        <Text style={styles.cardTitle}>{evento.name}</Text>
        <View style={styles.cardInfo}>
          <MaterialIcons name="location-on" size={14} color="white" />
          <Text style={styles.cardLocation}>
            {evento.address.city}/{evento.address.state}
          </Text>
        </View>
        <View style={styles.cardInfo}>
          <MaterialCommunityIcons
            name="calendar-month-outline"
            size={14}
            color="white"
          />
          <Text style={styles.cardDate}>{dataFormatada}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Card;

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#000",
    borderRadius: 8,
    marginBottom: 10,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    margin: 5,
  },
  imageContainer: {
    position: "relative",
    width: 160,
    height: 90,
  },
  cardImage: {
    width: 160,
    height: 90,
    borderRadius: 8,
    position: "absolute",
    top: 0,
    left: 0,
  },
  cardDetails: {
    flex: 1,
    paddingHorizontal: 10,
  },
  cardTitle: {
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 10,
  },
  cardInfo: {
    marginTop: 3,
    flexDirection: "row",
    alignItems: "center",
  },
  cardDate: {
    marginLeft: 5,
    color: "white",
    fontSize: 11,
  },
  cardLocation: {
    marginLeft: 5,
    color: "white",
    fontSize: 11,
  },
  favoriteButton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
});
