import React from 'react';
import { View, StyleSheet } from 'react-native';
import ContentLoader, { Rect, Circle } from 'react-content-loader/native';

const ListUserSkeleton = () => (
  <View style={styles.skeletonContainer}>
    <ContentLoader
      speed={1}
      width={360}
      height={70}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Circle cx="35" cy="35" r="30" />
      <Rect x="80" y="15" rx="5" ry="5" width="220" height="15" />
      <Rect x="80" y="35" rx="5" ry="5" width="180" height="10" />
    </ContentLoader>
  </View>
);

const styles = StyleSheet.create({
  skeletonContainer: {
    marginVertical: 10,
    marginHorizontal: 4,
  },
});

export default ListUserSkeleton;
