import React from "react";
import {
  TextInput,
  StyleSheet,
  View,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";

const SearchBar = ({ placeholder, value, onChangeText }) => {
  const clearInput = () => {
    onChangeText("");
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <Ionicons name="search" size={20} color="#888" style={styles.icon} />
        <TextInput
          style={styles.input}
          placeholder={placeholder}
          value={value}
          onChangeText={onChangeText}
          placeholderTextColor="#888"
        />
        {value ? (
          <TouchableOpacity onPress={clearInput}>
            <Ionicons name="close-circle" size={20} color="#888" style={styles.icon} />
          </TouchableOpacity>
        ) : null}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 10,
  },
  container: {
    flexDirection: "row",
    backgroundColor: "#222",
    borderRadius: 20,
    paddingHorizontal: 15,
    alignItems: "center",
    width: '90%',
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
  },
  input: {
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 8,
    fontWeight: "500",
    fontSize: 15,
    color: "#FFF",
  },
  icon: {
    marginHorizontal: 5,
  },
});

export default SearchBar;
