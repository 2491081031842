import React from 'react';
import { View, Text, TouchableOpacity, Modal, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

const reportOptions = [
  "Enganoso ou possível golpe",
  "Informação falsa",
  "É spam",
  "Nudez ou atividade sexual",
  "Símbolos ou discurso de ódio",
  "Violência ou organizações perigosas",
  "Está fingindo ser outra pessoa/organização",
];

const ReportModal = ({ reportModalVisible, setReportModalVisible, handleReport }) => (
  <Modal visible={reportModalVisible} transparent={true} animationType="slide" onRequestClose={() => setReportModalVisible(false)}>
    <TouchableWithoutFeedback onPress={() => setReportModalVisible(false)}>
      <View style={modalStyles.modalContainer}>
        <View style={modalStyles.modalContent}>
          <TouchableOpacity style={modalStyles.modalCloseButton} onPress={() => setReportModalVisible(false)}>
            <Icon name="close" size={24} color="#FFF" />
          </TouchableOpacity>
          <Text style={modalStyles.modalTitle}>Denunciar Evento</Text>
          {reportOptions.map((option, index) => (
            <TouchableOpacity key={index} style={modalStyles.reportOption} onPress={() => handleReport(option)}>
              <Text style={modalStyles.reportOptionText}>{option}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
);

const modalStyles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    backgroundColor: "#1C1C1C",
    borderRadius: 10,
    padding: 20,
    width: "80%",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    color: "#FFFFFF",
    marginBottom: 15,
  },
  reportOption: {
    width: "100%",
    backgroundColor: "#3D3D3D",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  reportOptionText: {
    color: "#FFF",
    fontSize: 16,
    textAlign: "center",
  },
  modalCloseButton: {
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  },
  feesText: {
    color: "#FFF",
    fontSize: 16,
    textAlign: "center",
    marginVertical: 5,
  },
});


export default ReportModal;
