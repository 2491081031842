import { collection, doc, getDoc, getDocs, query, where, onSnapshot, orderBy, startAt, endAt, limit, startAfter } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

// Função para limpar acentos
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Função para limpar o nome
const cleanName = (name) => {
  return removeAccents(name)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Remove caracteres especiais
};

export const getUser = async (userId) => {
  try {
    const userDocRef = doc(db, "usersData", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.error("Nenhum documento encontrado para o usuário:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do usuário:", error);
    return null;
  }
};

export const getUserEssential = async (userId) => {
  try {
    const userDocRef = doc(db, "usersData", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        userId: userDoc.id,
        username: userData.username,
        avatarUrl: userData.avatarUrl,
        fullName: userData.fullName,
        accountType: userData.accountType,
      };
    } else {
      console.error("Nenhum documento encontrado para o usuário:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do usuário:", error);
    return null;
  }
};

export const getUserTicketResult = async (userId) => {
  try {
    const userDocRef = doc(db, "usersData", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        userId: userDoc.id,
        username: userData.username,
        avatarUrl: userData.avatarUrl,
        fullName: userData.fullName,
        accountType: userData.accountType,
        ticketHistory: userData.ticketHistory,
        selectedPaymentMethod: userData.selectedPaymentMethod,
        paymentMethods: userData.paymentMethods,
      };
    } else {
      console.error("Nenhum documento encontrado para o usuário:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do usuário:", error);
    return null;
  }
};

export const getUserByUsername = async (username) => {
  try {
    const usuariosRef = collection(db, "usersData");
    const q = query(usuariosRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter usuário pelo username:", error);
    return null;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, "usersData", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        userId: userDoc.id,
        username: userData.username,
        avatarUrl: userData.avatarUrl,
        fullName: userData.fullName,
        accountType: userData.accountType,
        isPublic: userData.isPublic,
        followers: userData.followers,
        following: userData.following,
        eventHistory: userData.eventHistory,
        descriptionProfile: userData.descriptionProfile,
        progressProfile: userData.progressProfile,
        registrationDate: userData.registrationDate,
        rewardsPoints: userData.rewardsPoints,
        stickers: userData.stickers,
        challenges: userData.challenges,
        address: userData.address,
        contactEmail: userData.contactEmail,
        phoneNumber: userData.phoneNumber,
        hiddenEvents: userData.hiddenEvents,
      };
    } else {
      console.error("Nenhum documento encontrado para o usuário:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do usuário:", error);
    return null;
  }
};

export const onUserProfileSnapshot = (userId, callback) => {
  const userDocRef = doc(db, "usersData", userId);
  return onSnapshot(userDocRef, (doc) => {
    if (doc.exists()) {
      const userData = doc.data();
      const profileData = {
        userId: doc.id,
        username: userData.username,
        avatarUrl: userData.avatarUrl,
        fullName: userData.fullName,
        accountType: userData.accountType,
        isPublic: userData.isPublic,
        followers: userData.followers,
        following: userData.following,
        pendingFollowers: userData.pendingFollowers,
        eventHistory: userData.eventHistory,
        hiddenEvents: userData.hiddenEvents || [],
        descriptionProfile: userData.descriptionProfile,
        progressProfile: userData.progressProfile,
        registrationDate: userData.registrationDate,
        rewardsPoints: userData.rewardsPoints,
        reviews: userData.reviews,
        stickers: userData.stickers,
        challenges: userData.challenges,
        address: userData.address,
        contactEmail: userData.contactEmail,
        phoneNumber: userData.phoneNumber,
      };
      if (typeof callback === 'function') {
        callback(profileData);
      }
    } else {
      console.error("Nenhum dado encontrado para o usuário:", userId);
      if (typeof callback === 'function') {
        callback(null);
      }
    }
  });
};

export const getUserMyTicket = async (userId) => {
  try {
    const userDocRef = doc(db, "usersData", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        userId: doc.id,
        username: userData.username,
        avatarUrl: userData.avatarUrl,
        fullName: userData.fullName,
        favoriteEvents: userData.favoriteEvents,
        descriptionProfile: userData.descriptionProfile,
        stickers: userData.stickers,
        ticketHistory: userData.ticketHistory,
        address: userData.address,
        progressProfile: userData.progressProfile,
      };
    } else {
      console.error("Nenhum documento encontrado para o usuário:", userId);
      return null;
    }
  } catch (error) {
    console.error("Erro ao obter os dados do usuário:", error);
    return null;
  }
};

export const getBuyersData = async (ticketHistory) => {
  try {
    const uniqueBuyerUids = Array.from(
      new Set(ticketHistory.map((item) => item.uid))
    );

    const uniqueBuyerData = await Promise.all(
      uniqueBuyerUids.map(async (uid) => {
        const userDocRef = doc(db, "usersData", uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          return {
            uid,
            username: userData.username,
            fullName: userData.fullName,
            avatarUrl: userData.avatarUrl,
            hiddenEvents: userData.hiddenEvents || [],
            isPublic: userData.isPublic,
            followers: userData.followers || [],
            tickets: ticketHistory.filter((buyerUid) => buyerUid.uid === uid)
              .length,
          };
        }
        return null;
      })
    );

    return uniqueBuyerData.filter((buyer) => buyer !== null);
  } catch (error) {
    console.error("Erro ao obter dados dos compradores:", error);
    return [];
  }
};

export const buscarUsuariosPorTermo = async (termoPesquisa) => {
  const usuariosBusca = [];
  const colecaoUsuarios = collection(db, "usersData");

  // Query para username
  let qUsuariosUsername = query(colecaoUsuarios, orderBy("username", "asc"));
  if (termoPesquisa) {
    qUsuariosUsername = query(
      qUsuariosUsername,
      startAt(termoPesquisa),
      endAt(termoPesquisa + "\uf8ff"),
      limit(10)
    );
  }

  // Query para name
  let qUsuariosName = query(colecaoUsuarios, orderBy("searchName", "asc"));
  if (termoPesquisa) {
    termoPesquisa = cleanName(termoPesquisa);
    qUsuariosName = query(
      qUsuariosName,
      startAt(termoPesquisa),
      endAt(termoPesquisa + "\uf8ff"),
      limit(10)
    );
  }

  // Executar as duas queries
  const usuariosSnapshotUsername = await getDocs(qUsuariosUsername);
  const usuariosSnapshotName = await getDocs(qUsuariosName);

  // Combinar resultados das duas queries
  usuariosBusca.push(
    ...usuariosSnapshotUsername.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  );
  usuariosBusca.push(
    ...usuariosSnapshotName.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  );

  // Remover duplicatas
  const usuariosBuscaUnicos = Array.from(
    new Set(usuariosBusca.map((a) => a.id))
  ).map((id) => usuariosBusca.find((a) => a.id === id));

  return { usuariosBusca: usuariosBuscaUnicos };
};

export const checkIfFollows = async (followerId, followeeId) => {
  try {
    const followerData = await getUserProfile(followerId);
    const followeeData = await getUserProfile(followeeId);
    if (!followerData || !followeeData) {
      console.error("Dados do usuário não encontrados");
      return false;
    }

    const isFollowing = followerData.following && followerData.following.includes(followeeId);
    return isFollowing;
  } catch (error) {
    console.error("Erro ao verificar seguidores:", error);
    return false;
  }
};

export const checkHiddenEvents = async (user, eventoUsername) => {
  try {
    const isHidden = user.hiddenEvents.includes(eventoUsername);
    return isHidden;
  } catch (error) {
    console.error('Erro ao verificar eventos ocultos:', error);
    return false;
  }
};
