import React, { useState, useEffect } from "react";
import NetInfo from "@react-native-community/netinfo";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useMaintenance } from "./contexts/MaintenanceContext";
import LoadingScreen from "./components/utils/LoadingScreen";
import Home from "./views/general/Home";
import Search from "./views/general/Search";
import Settings from "./views/general/Settings";
import VisitProfile from "./views/profile/profileView/VisitProfile";
import LinkProfile from "./views/profile/profileView/LinkProfile";
import Ticket from "./views/ticket/ticketView/Ticket";
import EventLoader from "./views/ticket/ticketView/EventLoader";
import Terms from "./views/utils/terms/Terms";
import TermsOfUse from "./views/utils/terms/userTerms/TermsOfUse";
import PrivacyPolicy from "./views/utils/terms/userTerms/PrivacyPolicy";
import CookiePolicy from "./views/utils/terms/userTerms/CookiePolicy";
import ProducerCookiePolicy from "./views/utils/terms/producerTerms/CookiePolicy";
import ProducerPrivacyPolicy from "./views/utils/terms/producerTerms/PrivacyPolicy";
import ProducerTermsOfUse from "./views/utils/terms/producerTerms/TermsOfUse";
import Faq from "./views/utils/secondary/Faq";
import DownloadAppScreen from "./views/general/DownloadAppScreen";
import Maintenance from "./components/nav/Maintenance";
import UpdateRequired from "./components/nav/UpdateRequired";
import SecurityLock from "./components/nav/SecurityLock";
import NoInternet from "./components/nav/NoInternet";

// Componentes de navegação
const Stack = createNativeStackNavigator();

// Configuração de Deep Linking
const linking = {
  prefixes: ["rayv://", "https://rayv.com.br", "exp://192.168.2.133:8081/--/", "https://contadigitalsegura.com.br"],
  config: {
    screens: {
      Home: "home", // Certifique-se que a URL corresponde a tela
      Search: "search",
      EventLoader: "ticket/:id",
      Cart: "cart",
      EditEvent: "editEvent",
      LinkProfile: "visit/:username",
      Settings: "settings",
      DownloadAppScreen: "download",
      Terms: "terms",
      PrivacyPolicy: "privacy-policy",
      CookiePolicy: "cookie-policy",
      ProducerTermsOfUse: "producer-terms",
      ProducerPrivacyPolicy: "producer-privacy-policy",
      ProducerCookiePolicy: "producer-cookie-policy",
      Faq: "faq",
    },
  },
};

// Componente de navegação principal
const Nav = () => {
  const {
    isUnderMaintenance,
    maintenanceMessage,
    updateRequired,
    updateMessage,
    isLocked,
    lockMessage,
  } = useMaintenance();

  const [isConnected, setIsConnected] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    const checkConnection = () => {
      NetInfo.fetch().then((state) => {
        setIsConnected(state.isConnected && state.isInternetReachable);
        setInitialCheckDone(true);

        if (!state.isConnected || !state.isInternetReachable) {
          setLoading(true);
          setTimeout(() => {
            NetInfo.fetch().then((newState) => {
              setLoading(false);
              if (!newState.isConnected || !newState.isInternetReachable) {
                setIsConnected(false);
              } else {
                setIsConnected(true);
              }
            });
          }, 5000); // Verifica novamente após 5 segundos
        } else {
          setLoading(false);
        }
      });
    };

    checkConnection();

    const unsubscribe = NetInfo.addEventListener((state) => {
      if (state.isConnected && state.isInternetReachable) {
        setIsConnected(true);
        setLoading(false);
      } else {
        setIsConnected(false);
        setLoading(true);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!isConnected) {
    return (
      <NoInternet message="Sem conexão com a internet. Verifique sua conexão e tente novamente." />
    );
  }

  if (isUnderMaintenance) {
    return <Maintenance message={maintenanceMessage} />;
  }

  if (updateRequired) {
    return <UpdateRequired message={updateMessage} />;
  }

  if (isLocked) {
    return <SecurityLock message={lockMessage} />;
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{
          animation: "slide_from_right",
          headerShown: false,
          title: "RayV",
        }}
      >
        <Stack.Screen name="Search" component={Search} />
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Ticket" component={Ticket} />
        <Stack.Screen name="VisitProfile" component={VisitProfile} />
        <Stack.Screen name="Settings" component={Settings} />
        <Stack.Screen name="DownloadAppScreen" component={DownloadAppScreen} />
        <Stack.Screen name="EventLoader" component={EventLoader} />
        <Stack.Screen name="LinkProfile" component={LinkProfile} />
        <Stack.Screen name="Terms" component={Terms} />
        <Stack.Screen name="Faq" component={Faq} />
        <Stack.Screen name="TermsOfUse" component={TermsOfUse} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen name="CookiePolicy" component={CookiePolicy} />
        <Stack.Screen
          name="ProducerTermsOfUse"
          component={ProducerTermsOfUse}
        />
        <Stack.Screen
          name="ProducerPrivacyPolicy"
          component={ProducerPrivacyPolicy}
        />
        <Stack.Screen
          name="ProducerCookiePolicy"
          component={ProducerCookiePolicy}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Nav;
