import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Text,
  Dimensions,
  Modal,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

// Assets
import logoTexto from "../../../../assets/imgs/Logo_Texto3.png";

const Header = ({
  onSettingsPress,
  onHomePress,
  onSearchPress,
  onCreateEventPress,
  onLoginPress,
  onDownloadPress,
}) => {
  const [windowWidth, setWindowWidth] = useState(Dimensions.get("window").width);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const updateWidth = () => {
      setWindowWidth(Dimensions.get("window").width);
    };

    const subscription = Dimensions.addEventListener("change", updateWidth);

    return () => {
      subscription?.remove();
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleAppDownload = () => {
    if (navigation) {
      navigation.navigate("download");
    } else if (onDownloadPress) {
      onDownloadPress();
    }
  };
  
  return (
    <SafeAreaView style={styles.safeArea}>
      {/* Barra de Anúncios Fixa */}
      <TouchableOpacity style={styles.announcementContainer} onPress={handleAppDownload}>
        <MaterialIcons name="campaign" size={16} color="#FFF" style={{ marginRight: 5 }} />
        <Text style={styles.announcementText}>Baixe o App do RayV!</Text>
      </TouchableOpacity>

      {/* Cabeçalho Principal */}
      <View
        style={[
          styles.container,
          windowWidth < 600 ? styles.containerSmall : null,
        ]}
      >
        <TouchableOpacity onPress={onHomePress} style={styles.logoContainer}>
          <Image source={logoTexto} resizeMode="contain" style={styles.logo} />
        </TouchableOpacity>

        {windowWidth < 600 ? (
          <>
            <TouchableOpacity onPress={toggleMenu} style={styles.iconMenuButton}>
              <MaterialIcons name="menu" size={22} color="#FFF" />
            </TouchableOpacity>

            <Modal
              transparent={true}
              visible={menuVisible}
              animationType="fade"
              onRequestClose={toggleMenu}
            >
              <TouchableOpacity
                style={styles.modalOverlay}
                onPress={toggleMenu}
              >
                <View style={styles.menuContainer}>
                  <TouchableOpacity
                    onPress={onHomePress}
                    style={styles.menuItem}
                  >
                    <MaterialIcons name="home" size={22} color="#000" />
                    <Text style={styles.menuText}>Início</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={onSearchPress}
                    style={styles.menuItem}
                  >
                    <MaterialIcons name="search" size={22} color="#000" />
                    <Text style={styles.menuText}>Pesquisar</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={onSettingsPress}
                    style={styles.menuItem}
                  >
                    <MaterialIcons name="settings" size={22} color="#000" />
                    <Text style={styles.menuText}>Configurações</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={onCreateEventPress}
                    style={styles.menuItem}
                  >
                    <MaterialIcons name="calendar-month" size={22} color="#000" />
                    <Text style={styles.menuText}>Criar um Evento</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={onLoginPress}
                    style={styles.menuItem}
                  >
                    <MaterialIcons name="login" size={22} color="#000" />
                    <Text style={styles.menuText}>Login</Text>
                  </TouchableOpacity>
                </View>
              </TouchableOpacity>
            </Modal>
          </>
        ) : (
          <View style={styles.rightIcons}>
            <TouchableOpacity onPress={onSearchPress} style={styles.iconButton}>
              <MaterialIcons name="search" size={22} color="#FFF" />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={onSettingsPress}
              style={styles.iconButton}
            >
              <MaterialIcons name="settings" size={22} color="#FFF" />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={onCreateEventPress}
              style={styles.createEventButton}
            >
              <Text style={styles.buttonTextSecond}>Área do Produtor</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={onLoginPress} style={styles.loginButton}>
              <Text style={styles.buttonText}>Login</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: "#000",
  },
  announcementContainer: {
    backgroundColor: "#1A2035", // Cor vibrante para destacar a barra
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: "100%",
  },
  announcementText: {
    color: "#FFF",
    fontSize: 12,
    textAlign: "center",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#000",
    paddingHorizontal: 20,
    paddingVertical: 4,
  },
  containerSmall: {
    justifyContent: "center",
  },
  logoContainer: {
    flex: 1,
    alignItems: "flex-start",
  },
  logo: {
    width: 70,
    height: 20,
  },
  rightIcons: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconButton: {
    marginHorizontal: 12,
  },
  iconMenuButton: {
    marginHorizontal: 0,
  },
  createEventButton: {
    backgroundColor: "#121212",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginLeft: 12,
  },
  loginButton: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginLeft: 8,
  },
  buttonText: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 12,
  },
  buttonTextSecond: {
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 12,
  },
  menuContainer: {
    backgroundColor: "#FFF",
    padding: 15,
    borderRadius: 10,
    position: "absolute",
    top: 50,
    right: 20,
    width: 200,
    zIndex: 1000,
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
  },
  menuItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  menuText: {
    color: "#000",
    marginLeft: 10,
    fontSize: 15,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
});

export default Header;
