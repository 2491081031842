import { collection, addDoc, doc, updateDoc, increment } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

// Função para adicionar uma nova denúncia
export const addReport = async (reportedUserId, reportedUserUsername, reportingUserId, reportReason) => {
  try {
    await addDoc(collection(db, "userReports"), {
      reportedUserId,
      reportedUserUsername,
      reportingUserId,
      message: reportReason,
      timestamp: new Date(),
    });

    const reportedUserRef = doc(db, "usersData", reportedUserId);
    await updateDoc(reportedUserRef, {
      reportCount: increment(1),
    });

    return true;
  } catch (error) {
    console.error("Erro ao enviar denúncia: ", error);
    return false;
  }
};