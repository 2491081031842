import React from "react";
import { View, Text, ScrollView } from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

export const PolicyDetails = ({ eventRules, refundPolicy }) => {
  const getAgeIcon = (age) => {
    if (!age || age === "Livre") return "emoticon-excited-outline";
    if (age < 18) return "emoticon-neutral-outline";
    return "emoticon-devil-outline";
  };

  const getAgeColor = (age) => {
    if (!age || age === "Livre") return "#4CAF50"; // Verde para Livre
    if (age < 18) return "#FF9800"; // Laranja para menores de 18
    return "#F44336"; // Vermelho para maiores de 18
  };

  const getAgeText = (age) => {
    if (age === "Livre") return "Livre para todos";
    return `Proibido para menores de ${age} anos`;
  };

  return (
    <ScrollView style={styles.tabBeginContainer}>
      <View style={styles.tabContainer}>
        <View style={styles.tabHeader}>
          <MaterialIcons name="security" size={20} color="#FFF" />
          <Text style={styles.tabTitle}>Políticas do Evento</Text>
        </View>
        {eventRules?.minimumAge !== undefined && (
          <View style={styles.policyContainer}>
            <>
              <View style={styles.policyHeader}>
                <MaterialCommunityIcons
                  name={getAgeIcon(eventRules.minimumAge)}
                  size={24}
                  color={getAgeColor(eventRules.minimumAge)}
                />
                <Text
                  style={[
                    styles.policyTitle,
                    { color: getAgeColor(eventRules.minimumAge) },
                  ]}
                >
                  Faixa Etária
                </Text>
              </View>
              <Text style={styles.policyText}>
                {getAgeText(eventRules.minimumAge)}
              </Text>
            </>
          </View>
        )}
      </View>
      {eventRules?.minimumAge !== undefined && (
        <View style={styles.sectionDividerTab}></View>
      )}
      {eventRules?.rules && (
        <View style={styles.tabContainer}>
          <View style={styles.policyContainer}>
            <>
              <View style={styles.policyHeader}>
                <MaterialCommunityIcons
                  name="clipboard-list-outline"
                  size={24}
                  color="#FFF"
                />
                <Text style={styles.policyTitle}>Regras do Evento</Text>
              </View>
              <Text style={styles.policyText}>{eventRules.rules}</Text>
            </>
          </View>
        </View>
      )}
      {eventRules?.rules && <View style={styles.sectionDividerTab}></View>}
      <View style={styles.tabContainer}>
        <View style={styles.policyContainer}>
          <View style={styles.policyHeader}>
            <MaterialCommunityIcons name="cash-refund" size={24} color="#FFF" />
            <Text style={styles.policyTitle}>Política de Reembolso</Text>
          </View>
          <Text style={styles.policyText}>
            Devolução total até 7 dias após a compra, desde que seja antes de 48
            horas do início do evento.
          </Text>
          {refundPolicy && refundPolicy.allowPartialRefund && (
            <Text style={styles.policyText}>
              Reembolso parcial disponível após 7 dias da compra e antes de 48
              horas do evento. A taxa de devolução será{" "}
              {refundPolicy.partialRefundRate || "especificada"}%.
            </Text>
          )}
        </View>
      </View>
    </ScrollView>
  );
};
