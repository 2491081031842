import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  ScrollView,
  ActivityIndicator,
  TouchableOpacity,
  Alert,
  RefreshControl,
  Platform,
  Dimensions,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import ListEventUCard from "../../components/general/home_search/ListEventUCard";
import FeaturedEventCard from "../../components/general/home/FeaturedEventCard";
import Header from "../../components/general/home_search/Header";
import CitySelector from "../../components/general/home/CitySelector";
import { styles } from "../../styles/general/HomeStyles";
import { getEventCity, getEventStatus } from "../../services/cruds/events/getEvent";
import FeaturedEventSkeleton from "../../components/general/home/FeaturedEventSkeleton";
import ListEventUSkeleton from "../../components/general/home_search/ListEventUSkeleton";

class Home extends Component {
  state = {
    eventosGerais: [],
    eventosDestaque: [],
    refreshing: false,
    cidadeSelecionada: "Todas as Cidades",
    citySelectorVisible: false,
    ultimosDocumentosGerais: null,
    carregandoMais: false,
    loadingInitial: true,
    isIOS: Platform.OS === "ios",
    hasLoadedOnce: false,
  };

  componentDidMount() {
    this.loadEvents();
    this.focusListener = this.props.navigation.addListener("focus", this.handleFocus);
  }

  componentWillUnmount() {
    if (this.focusListener) this.focusListener();
  }

  handleFocus = async () => {
    if (!this.state.hasLoadedOnce) {
      await this.loadEvents();
    }
  };

  loadEvents = async () => {
    this.setState({ loadingInitial: true });
    await this.buscarEventosDestaque();
    await this.buscarEventosGerais();
  };

  onRefresh = async () => {
    this.setState({ refreshing: true });
    try {
      await this.loadEvents();
    } finally {
      this.setState({ refreshing: false });
    }
  };

  onSettingsPress = () => {
    this.props.navigation.navigate("Settings");
  };

  onCreateEventPress = () => {
    this.props.navigation.navigate("DownloadAppScreen");
  };

  onLoginPress = () => {
    this.props.navigation.navigate("DownloadAppScreen");
  };

  onSearchPress = () => {
    this.props.navigation.navigate("Search");
  };

  capitalizeWords = (str) => {
    const lowercaseWords = [
      "das",
      "des",
      "dos",
      "a",
      "e",
      "i",
      "o",
      "u",
      "as",
      "os",
      "da",
      "de",
      "do",
      "du",
    ];

    return str
      .split(" ")
      .map((word) => {
        if (lowercaseWords.includes(word.toLowerCase())) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  handleSelectCity = (city) => {
    const capitalizedCity = this.capitalizeWords(city);
    this.setState(
      { cidadeSelecionada: capitalizedCity, citySelectorVisible: false },
      this.buscarEventosGerais
    );
  };

  handleCitySelectorToggle = () => {
    this.setState((prevState) => ({
      citySelectorVisible: !prevState.citySelectorVisible,
    }));
  };

  buscarEventosDestaque = async () => {
    try {
      const eventosDestaque = await getEventStatus("Publicado");
      this.setState({ eventosDestaque, loadingInitial: false });
    } catch (error) {
      console.error("Erro ao buscar eventos em destaque:", error);
    }
  };

  buscarEventosGerais = async () => {
    this.setState({ loadingInitial: true });
    try {
      const response = await getEventCity(this.state.cidadeSelecionada);
      const { eventos, lastDocument } = response;
      this.setState({
        eventosGerais: eventos,
        ultimosDocumentosGerais: lastDocument,
        loadingInitial: false,
        refreshing: false,
        hasLoadedOnce: true,
      });
    } catch (error) {
      console.error("Erro ao buscar eventos gerais:", error);
      this.setState({ loadingInitial: false, refreshing: false });
    }
  };

  carregarMaisEventosGerais = async () => {
    if (!this.state.ultimosDocumentosGerais || this.state.carregandoMais) {
      return;
    }
    this.setState({ carregandoMais: true });

    try {
      const response = await getEventCity(
        this.state.cidadeSelecionada,
        this.state.ultimosDocumentosGerais
      );
      const { eventos, lastDocument } = response;
      this.setState((prevState) => ({
        eventosGerais: [...prevState.eventosGerais, ...eventos],
        ultimosDocumentosGerais: lastDocument,
        carregandoMais: false,
      }));
    } catch (error) {
      console.error("Erro ao carregar mais eventos gerais:", error);
      this.setState({ carregandoMais: false });
    }
  };

  renderFeaturedEvents = () => {
    const { eventosDestaque, loadingInitial } = this.state;
    if (loadingInitial && !this.state.hasLoadedOnce) {
      return (
        <FlatList
          horizontal
          data={[1, 2, 3, 4, 5]}
          renderItem={() => <FeaturedEventSkeleton />}
          keyExtractor={(item, index) => index.toString()}
          showsHorizontalScrollIndicator={false}
        />
      );
    }
    return (
      <FlatList
        horizontal
        data={eventosDestaque}
        renderItem={({ item }) => (
          <FeaturedEventCard
            evento={item}
            onPressMore={() =>
              this.props.navigation.navigate("Ticket", { evento: item })
            }
          />
        )}
        keyExtractor={(item) => item.id.toString()}
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  renderEventsList = () => {
    const { eventosGerais, loadingInitial } = this.state;
  
    if (loadingInitial && !this.state.hasLoadedOnce) {
      return (
        <View style={styles.containerBlack}>
          <FlatList
            data={[1, 2, 3, 4, 5]}
            renderItem={() => <ListEventUSkeleton />}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      );
    }
  
    return (
      <FlatList
        data={eventosGerais}
        renderItem={({ item }) => this.renderCard(item)}
        keyExtractor={(item) => item.id.toString()}
        ListEmptyComponent={this.renderEmpty}
        onEndReached={this.handleEndReached}
        onEndReachedThreshold={0.5}
        ListFooterComponent={this.renderFooter}
      />
    );
  };  

  handleEndReached = () => {
    if (!this.state.carregandoMais && this.state.eventosGerais.length > 0) {
      this.carregarMaisEventosGerais();
    }
  };

  renderCard = (item) => {
    if (!item) return null;
    return (
      <ListEventUCard
        evento={item}
        onPressCard={() =>
          this.props.navigation.navigate("Ticket", { evento: item })
        }
      />
    );
  };

  renderFooter = () => {
    if (this.state.carregandoMais) {
      return <ActivityIndicator style={{ color: "#000" }} />;
    }
  };

  renderEmpty = () => {
    const { loadingInitial, hasLoadedOnce, eventosGerais, refreshing } = this.state;

    if (!loadingInitial && !refreshing && eventosGerais.length === 0) {
      return (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>Nenhum evento encontrado.</Text>
        </View>
      );
    }

    return (
      <View style={styles.containerBlack}>
        <FlatList
          data={[1, 2, 3, 4, 5]}
          renderItem={() => <ListEventUSkeleton />}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    );
  };

  renderSectionTitle = (title) => (
    <View style={styles.sectionHeader}>
      <Text style={styles.sectionTitle}>{title}</Text>
      <View style={styles.sectionDivider} />
    </View>
  );

  render() {
    const {
      cidadeSelecionada,
      citySelectorVisible,
    } = this.state;

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.headerContainer}>
          <Header
            navigation={navigation} 
            onSettingsPress={this.onSettingsPress}
            onHomePress={this.onHomePress}
            onSearchPress={this.onSearchPress}
            onCreateEventPress={this.onCreateEventPress} 
            onLoginPress={this.onLoginPress}
            visible="true"
          />
        </View>
        <View style={styles.restContainer}>
          <ScrollView>
            <CitySelector
              onSelectCity={this.handleSelectCity}
              visible={citySelectorVisible}
              onRequestClose={this.handleCitySelectorToggle}
              selectedCity={cidadeSelecionada}
            />
            {this.renderSectionTitle("Eventos em Destaque")}
            {this.renderFeaturedEvents()}
            {this.renderSectionTitle(
              cidadeSelecionada === "Todas as Cidades"
                ? "Todos os Eventos"
                : `Eventos de ${cidadeSelecionada}`
            )}
            {this.renderEventsList()}
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
}

export default Home;
