import React from 'react';
import Nav from './src/Nav';
import { MaintenanceProvider } from './src/contexts/MaintenanceContext';

export default function App() {
  return (
    <MaintenanceProvider>
      <Nav />
    </MaintenanceProvider>
  );
}