import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../styles/utils/secondary/FaqStyles";
import Icon from "react-native-vector-icons/Ionicons";

export default class FAQ extends Component {
  state = {
    expandedQuestionIndex: null,
  };

  toggleQuestion = (index) => {
    this.setState((prevState) => ({
      expandedQuestionIndex:
        prevState.expandedQuestionIndex === index ? null : index,
    }));
  };

  render() {
    const { expandedQuestionIndex } = this.state;
    const faqs = [
      {
        question: "Como faço para resetar minha senha?",
        answer:
          "Você pode resetar sua senha indo nas configurações e selecionando 'Redefinir Senha'.",
      },
      {
        question: "Como faço para excluir minha conta?",
        answer:
          "Para excluir sua conta, por favor entre em contato com o suporte ao cliente e solicite a exclusão.",
      },
      {
        question: "Onde encontro os termos e condições?",
        answer:
          "Os termos e condições podem ser encontrados na seção 'Termos' nas configurações.",
      },
      {
        question: "Como faço para verificar meu email?",
        answer:
          "Um email de verificação será enviado para o endereço de email que você usou ao se registrar.",
      },
      {
        question: "Como altero minha foto de perfil?",
        answer:
          "Você pode alterar sua foto de perfil na seção de 'Editar Perfil' nas configurações.",
      },
      {
        question: "Por que não recebi meu email de confirmação?",
        answer:
          "Verifique sua pasta de spam. Se ainda não encontrou, tente reenviar o email de confirmação.",
      },
      {
        question: "Posso ter mais de uma conta?",
        answer:
          "Sim, você pode ter mais de uma conta, mas cada conta deve ter um cpf/cnpj único.",
      },
      {
        question: "Como faço para desativar notificações?",
        answer:
          "Você pode desativar notificações na seção de 'Gerenciar Notificações' nas configurações.",
      },
      {
        question: "Como entro em contato com o suporte?",
        answer:
          "Você pode entrar em contato com o suporte através da seção 'Suporte' nas configurações.",
      },
    ];

    return (
      <SafeAreaView style={styles.container}>
        <StatusBar barStyle="light-content" />
        <View style={styles.containerHeader}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.backButton}
          >
            <Icon name="arrow-back" size={25} color="#FFFFFF" />
          </TouchableOpacity>

          <Text style={styles.title}>FAQs</Text>

          <TouchableOpacity>
            <Icon
              name="person-outline"
              size={25}
              color="#000"
              style={styles.goBackButton}
            />
          </TouchableOpacity>
        </View>
        <ScrollView>
          {faqs.map((faq, index) => (
            <View key={index}>
              <TouchableOpacity
                style={styles.settingItem}
                onPress={() => this.toggleQuestion(index)}
              >
                <MaterialCommunityIcons
                  name="help-network-outline"
                  size={22}
                  style={styles.settingIcon}
                />
                <View style={styles.settingTextContainer}>
                  <Text style={styles.settingTitle}>{faq.question}</Text>
                </View>
                <Ionicons
                  name={
                    expandedQuestionIndex === index
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  size={24}
                  style={styles.settingArrow}
                />
              </TouchableOpacity>
              {expandedQuestionIndex === index && (
                <View style={styles.answerContainer}>
                  <Text style={styles.answerText}>{faq.answer}</Text>
                </View>
              )}
            </View>
          ))}
        </ScrollView>
      </SafeAreaView>
    );
  }
}
