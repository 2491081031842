import React from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from "react-native";

const { width } = Dimensions.get("window");
const FeaturedEventCard = ({ evento, onPressMore }) => {
  const dataEvento = new Date(evento.dates.start.seconds * 1000);
  const formattedDate = dataEvento.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "long",
  });

  const defaultImageEvent = require("../../../../assets/imgs/fundo_evento.png");
  const eventImage =
    evento.eventImage !== "default_image.png"
      ? { uri: evento.eventImage }
      : defaultImageEvent;

  return (
    <TouchableOpacity onPress={onPressMore} style={styles.card}>
      <Image source={eventImage} style={styles.image} />
      <View style={styles.details}>
        <Text style={styles.title}>{evento.name}</Text>
        <Text style={styles.dateLocation}>
          {formattedDate} • {evento.address.city}/{evento.address.state}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#000",
    overflow: "hidden",
    marginVertical: 10,
    marginHorizontal: 4,
    paddingRight: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
    borderRadius: 5,
  },
  image: {
    width: 320,
    height: 180,
    resizeMode: "cover",
  },
  details: {
    padding: 5,
  },
  title: {
    fontSize: 15,
    color: "#FFF",
    fontWeight: "bold",
    flex: 1,
  },
  dateLocation: {
    fontSize: 12,
    color: "#FFFFFF",
  },
});

export default FeaturedEventCard;
