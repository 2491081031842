import React from "react";
import { View, Text, TouchableOpacity,Linking, ScrollView } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import {
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

export const EventDetails = ({ description, socialMedia, contact }) => (
  <ScrollView style={styles.tabBeginContainer}>
    <View style={styles.tabContainer}>
      <View style={styles.tabHeader}>
        <MaterialCommunityIcons
          name="format-align-left"
          size={20}
          color="#FFF"
        />
        <Text style={styles.tabTitle}>Descrição do Evento</Text>
      </View>
      <View style={styles.descriptionContainer}>
        <Text
          style={description ? styles.descriptionText : styles.noPolicyText}
        >
          {description || "Nenhuma descrição encontrada"}
        </Text>
      </View>
    </View>
    {socialMedia && (
      <View>
        <View style={styles.sectionDividerTab}></View>
        <View style={styles.socialMediaContainer}>
          <View style={styles.tabHeader}>
            <MaterialCommunityIcons
              name="access-point-network"
              size={20}
              color="#FFF"
            />
            <Text style={styles.tabTitle}>Nossas Redes Sociais</Text>
          </View>
          <View style={styles.socialMediaContainerRow}>
            {socialMedia.whatsapp && (
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(`https://wa.me/${socialMedia.whatsapp}`)
                }
                style={styles.socialMediaButton}
              >
                <Icon name="logo-whatsapp" size={20} color="#FFFD" />
              </TouchableOpacity>
            )}
            {socialMedia.instagram && (
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(
                    `https://instagram.com/${socialMedia.instagram}`
                  )
                }
                style={styles.socialMediaButton}
              >
                <Icon name="logo-instagram" size={20} color="#FFFD" />
              </TouchableOpacity>
            )}
            {socialMedia.facebook && (
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(
                    `https://facebook.com/${socialMedia.facebook}`
                  )
                }
                style={styles.socialMediaButton}
              >
                <Icon name="logo-facebook" size={20} color="#FFFD" />
              </TouchableOpacity>
            )}
            {socialMedia.twitter && (
              <TouchableOpacity
                onPress={() =>
                  Linking.openURL(`https://twitter.com/${socialMedia.twitter}`)
                }
                style={styles.socialMediaButton}
              >
                <Icon name="logo-twitter" size={20} color="#FFFD" />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    )}
    {contact && (
      <View>
        <View style={styles.sectionDividerTab}></View>
        <View style={styles.contactContainer}>
          <View style={styles.tabHeader}>
            <MaterialCommunityIcons name="contacts" size={20} color="#FFF" />
            <Text style={styles.tabTitle}>Contato</Text>
          </View>
          {contact.phone && (
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(`tel:${formatPhoneNumber(contact.phone)}`)
              }
              style={styles.contactButton}
            >
              <View style={styles.contactContainerRow}>
                <Icon name="call-outline" size={20} color="#FFFD" />
                <Text style={styles.contactButtonText}>
                  {`${formatPhoneNumber(contact.phone)}`}
                </Text>
              </View>
            </TouchableOpacity>
          )}
          {contact.email && (
            <TouchableOpacity
              onPress={() => Linking.openURL(`mailto:${contact.email}`)}
              style={styles.contactButton}
            >
              <View style={styles.contactContainerRow}>
                <Icon name="mail-outline" size={20} color="#FFFD" />
                <Text style={styles.contactButtonText}>{contact.email}</Text>
              </View>
            </TouchableOpacity>
          )}
        </View>
      </View>
    )}
  </ScrollView>
);
