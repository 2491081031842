import React from 'react';
import { View, StyleSheet } from 'react-native';
import ContentLoader, { Rect, Circle } from 'react-content-loader/native';

const SettingsSkeleton = () => (
  <View style={styles.container}>
    <ContentLoader
      speed={1}
      width={'100%'}
      height={700}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Circle cx="20" cy="40" r="20" />
      <Rect x="50" y="25" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="50" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="110" r="20" />
      <Rect x="50" y="95" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="120" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="180" r="20" />
      <Rect x="50" y="165" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="190" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="250" r="20" />
      <Rect x="50" y="235" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="260" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="320" r="20" />
      <Rect x="50" y="305" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="330" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="390" r="20" />
      <Rect x="50" y="375" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="400" rx="4" ry="4" width="250" height="10" />
      
      <Circle cx="20" cy="460" r="20" />
      <Rect x="50" y="445" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="470" rx="4" ry="4" width="250" height="10" />

      <Circle cx="20" cy="530" r="20" />
      <Rect x="50" y="515" rx="4" ry="4" width="300" height="15" />
      <Rect x="50" y="540" rx="4" ry="4" width="250" height="10" />
      
      <Rect x="5" y="580" rx="8" ry="8" width="350" height="100" />
    </ContentLoader>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
  },
});

export default SettingsSkeleton;
