import React from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { styles } from "../../../../styles/utils/terms/userTerms/userTermsStyles";
import Icon from "react-native-vector-icons/Ionicons";

export default function TermsOfUse({ navigation }) {
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" />
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Text style={styles.title}>Termos de Uso</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon
            name="person-outline"
            size={25}
            color="#000"
            style={styles.goBackButton}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.content}>
          <Text style={styles.sectionTitle}>
            TERMOS DE USO PARA USUÁRIOS DA PLATAFORMA E APLICATIVO RAYV
          </Text>
          <Text style={styles.sectionTitle}>1. A Plataforma</Text>
          <Text style={styles.body}>
            A RAYV é uma plataforma tecnológica disponível para produtores e
            casas de eventos, facilitando a venda de ingressos, inscrição para
            eventos, cobrança de contribuições para eventos e a gestão de
            participantes, acessível no endereço eletrônico www.rayv.com.br, ou
            por meio dos aplicativos oficiais. É uma pessoa jurídica de direito
            privado, inscrita no CNPJ sob o nº X, com endereço na X.
          </Text>
          <Text style={styles.sectionTitle}>2. Informações Gerais</Text>
          <Text style={styles.body}>
            2.1. Estes Termos regulamentam os serviços oferecidos na plataforma
            RAYV, que incluem: 
            <Text style={styles.listItem}>
              (i) fornecer uma solução tecnológica (plataforma RAYV) para que
              Produtores possam criar, organizar, gerenciar e divulgar seus próprios eventos;
            </Text>
            <Text style={styles.listItem}>
              (ii) possibilitar, por meio da plataforma RAYV, a venda de ingressos cadastrados por Produtores;
            </Text>
            <Text style={styles.listItem}>
              (iii) hospedar os eventos criados pelos Produtores.
            </Text>
          </Text>
          <Text style={styles.body}>
            2.2. Os eventos criados e divulgados na plataforma RAYV são de
            responsabilidade exclusiva dos Produtores, que são os únicos
            responsáveis por definir as condições de venda, realização e
            cancelamento dos eventos. A RAYV não cria, co-organiza ou é
            proprietária dos eventos hospedados em sua plataforma, mas pode
            monitorar os eventos para garantir que estejam em conformidade com
            suas políticas legais.
          </Text>
          <Text style={styles.body}>
            2.3. A plataforma RAYV e suas funcionalidades são apresentadas aos
            usuários no estado em que se encontram, podendo ser constantemente
            aprimoradas e atualizadas. A RAYV compromete-se a:
            <Text style={styles.listItem}>
              a) Preservar as funcionalidades da plataforma RAYV, garantindo links ativos e layouts que favoreçam a usabilidade e navegabilidade;
            </Text>
            <Text style={styles.listItem}>
              b) Exibir as funcionalidades de forma clara, completa, precisa e suficiente, permitindo a correta compreensão das operações realizadas.
            </Text>
          </Text>
          <Text style={styles.body}>
            2.4. A RAYV se empenha para garantir a disponibilidade contínua e
            permanente de sua plataforma. No entanto, pode haver
            indisponibilidade temporária devido a manutenção necessária ou a
            causas de força maior, como desastres naturais, falhas nos sistemas
            centrais de comunicação e acesso à internet, ou eventos causados por
            terceiros que estão fora de seu controle e responsabilidade.
          </Text>
          <Text style={styles.body}>
            2.4.1. Em caso de indisponibilidade, a RAYV fará o possível para
            restabelecer o acesso à plataforma o mais rápido possível, dentro
            das limitações técnicas de seus serviços e dos serviços de
            terceiros.
          </Text>
          <Text style={styles.body}>
            2.4.2. O Comprador isenta a RAYV de qualquer dano em casos de paralisações,
            parciais ou totais, de seus serviços devido à falta temporária de energia elétrica,
            falhas nos serviços das concessionárias, problemas nos serviços das redes telefônicas e
            outros provedores, incluindo provedores de hospedagem, greves, tumultos ou quaisquer
            outros eventos que não estejam diretamente ligados ao serviço. A RAYV também não será
            responsável por danos causados por caso fortuito ou força maior, bem como por atos de
            terceiros.
          </Text>
          <Text style={styles.body}>
            2.5. Qualquer procedimento de manutenção necessária na plataforma
            RAYV será informado, sempre que possível, com antecedência adequada,
            através dos canais oficiais de comunicação da RAYV, como suporte
            virtual e outras comunicações eletrônicas.
          </Text>
          <Text style={styles.sectionTitle}>3. Cadastro de usuários</Text>
          <Text style={styles.body}>
            3.1. O registro na plataforma RAYV é gratuito, porém, os serviços
            oferecidos serão cobrados conforme especificado nestes Termos de Uso
            pela RAYV.
          </Text>
          <Text style={styles.body}>
            3.2. O cadastro na Plataforma RAYV é necessário para que os usuários
            acessem seus ingressos, uma vez que os ingressos são
            disponibilizados para uso pessoal e intransferível por meio do
            aplicativo da Plataforma.
          </Text>
          <Text style={styles.listItem}>
            3.2.1. O usuário deve fornecer informações precisas e verdadeiras, comprometendo-se a
            atualizar seus dados pessoais sempre que necessário ou quando solicitado. Caso haja
            indícios de que qualquer informação fornecida não seja verdadeira, a RAYV terá o direito de
            suspender ou encerrar a conta do usuário, bem como se recusar a fornecer qualquer um de seus
            serviços.
          </Text>
          <Text style={styles.listItem}>
            3.2.2. A RAYV não é responsável pela veracidade ou exatidão dos dados
            fornecidos no cadastro, sendo que a criação de perfis falsos na
            plataforma RAYV pode ser considerada crime de falsa identidade,
            falsidade ideológica ou estelionato. Além disso, o fornecimento de
            informações falsas, incorretas ou imprecisas pode resultar na
            suspensão dos serviços descritos nos termos e políticas, sem
            qualquer ônus para a RAYV.
          </Text>
          <Text style={styles.body}>
            3.3. Se a RAYV identificar alguma atividade suspeita, poderá, a
            qualquer momento e a seu critério, confirmar a identidade e os dados
            pessoais do usuário, podendo solicitar documentos pessoais ou outras
            formas de comprovação.
          </Text>
          <Text style={styles.listItem}>
            3.3.1. Qualquer erro ou atraso no envio ou confirmação da identidade do usuário que cause prejuízo ou dano de qualquer natureza será de responsabilidade do usuário.
          </Text>
          <Text style={styles.body}>
            3.4. Sem prejuízo de outras medidas, a RAYV poderá advertir,
            suspender ou cancelar o cadastro dos usuários, bem como os eventos
            associados, recusando-se a prestar os serviços descritos nestes
            Termos, caso o usuário:
            <Text style={styles.listItem}>
              a) Não cumpra qualquer disposição destes Termos ou de qualquer outra política da RAYV;
            </Text>
            <Text style={styles.listItem}>
              b) Cometa atos fraudulentos ou dolosos;
            </Text>
            <Text style={styles.listItem}>
              c) Cause qualquer tipo de dano ou prejuízo a terceiros ou à própria RAYV na utilização da plataforma RAYV.
            </Text>
          </Text>
          <Text style={styles.sectionTitle}>4. Acesso à plataforma RAYV</Text>
          <Text style={styles.body}>
            4.1. Os usuários acessarão suas contas através de login e senha
            pessoais e intransferíveis. Os usuários se comprometem a não
            compartilhar esses dados com terceiros, sendo totalmente
            responsáveis pelo uso feito com eles, e comprometendo-se a notificar
            a RAYV imediatamente sobre qualquer uso não autorizado de sua conta
            ou qualquer outra violação de segurança. A RAYV não será responsável
            por quaisquer perdas e danos resultantes de acessos não autorizados
            ou uso da conta.
          </Text>
          <Text style={styles.body}>
            4.2. Apenas o titular da conta na RAYV poderá acessar os dados
            relativos à mesma, devido ao seu caráter pessoal. Qualquer alteração
            nos dados cadastrados só poderá ser feita pelo próprio usuário,
            devidamente logado. A RAYV não tem prerrogativa de fazer esse tipo
            de alteração, independentemente da razão alegada ou circunstância.
          </Text>
          <Text style={styles.sectionTitle}>5. Responsabilidades e obrigações dos Usuários</Text>
          <Text style={styles.body}>
            5.1. Os usuários devem utilizar a plataforma RAYV de maneira ética e
            adequada aos propósitos estabelecidos nestes Termos, obedecendo às
            leis brasileiras, comprometendo-se a evitar qualquer atividade que
            viole essas normas.
          </Text>
          <Text style={styles.body}>
            5.2. Ao utilizar a plataforma RAYV e suas funcionalidades, os
            usuários se comprometem a:
            <Text style={styles.listItem}>
              a) Fornecer dados corretos, completos e atualizados ao adquirir ingressos (seja pago ou gratuito) e ao se cadastrar na Plataforma RAYV. Caso contrário, a aquisição e o uso do ingresso podem ser prejudicados, e o Comprador, se desejar, terá que fazer uma nova aquisição, sujeito às condições e disponibilidade de ingressos, inscrições ou contribuições nesse momento;
            </Text>
            <Text style={styles.listItem}>
              b) Pagar os valores cobrados na fatura do cartão de crédito, caso essa seja a forma de pagamento escolhida pelo Comprador. A cobrança poderá aparecer em nome da RAYV ou de um de nossos processadores de pagamento;
            </Text>
            <Text style={styles.listItem}>
              c) Pagar o valor total do boleto e quitá-lo até a data de vencimento, caso o Consumidor tenha optado por esse método de pagamento. Após a data de vencimento, o boleto perde a validade para compra e o ingresso não estará mais reservado para o Comprador;
            </Text>
            <Text style={styles.listItem}>
              d) Adquirir ingressos exclusivamente através da plataforma RAYV ou em pontos de venda autorizados. A RAYV não se responsabiliza pela veracidade ou confiabilidade de ingressos adquiridos de terceiros, outros sites ou qualquer ponto de venda não autorizado, e o Comprador está ciente de que, ao fazer tal aquisição, corre o risco de ser vítima de fraude ou engenharia social;
            </Text>
            <Text style={styles.listItem}>
              e) Cooperar no processo de apuração de eventuais fraudes nas transações realizadas, fornecendo todas as informações e documentos solicitados através dos canais oficiais da RAYV, conforme explicitado no Item 8.2 destes Termos;
            </Text>
            <Text style={styles.listItem}>
              f) Garantir a segurança do ingresso a partir do momento em que o receber, não o divulgando publicamente, especialmente em redes sociais, nem permitindo o acesso de terceiros a ele;
            </Text>
            <Text style={styles.listItem}>
              g) Pagar a taxa eventualmente cobrada pela processadora de pagamentos para fins de saque do crédito decorrente do estorno, no caso de cancelamento da compra por parte do Comprador;
            </Text>
            <Text style={styles.listItem}>
              h) Isentar a RAYV de qualquer responsabilidade pela realização dos eventos para os quais foram adquiridos ingressos. A RAYV não é Organizadora de eventos. Nós fornecemos a plataforma para que os Produtores vendam seus ingressos e gerenciem seus Participantes.
            </Text>
          </Text>
          <Text style={styles.body}>
            5.3. Em todas as situações, a RAYV, a seu exclusivo critério, pode
            se recusar a prestar quaisquer serviços, independentemente da
            motivação, sem que isso constitua limitação de direitos.
          </Text>
          <Text style={styles.body}>
            5.4. Em casos de eventos realizados durante um período de calamidade
            pública decretada pelo Governo Federal, Estadual e/ou Municipal, ou
            durante um período de emergência de saúde pública de importância
            internacional, o que terminar por último, ao utilizar a plataforma
            RAYV e suas funcionalidades, os usuários declaram expressamente
            estar cientes e concordam que:
            <Text style={styles.listItem}>
              a) Os eventos criados e divulgados através da plataforma RAYV são de responsabilidade exclusiva dos Produtores, que são os únicos responsáveis por estabelecer as condições de venda, realização e cancelamento dos eventos, obedecendo à legislação vigente, incluindo eventuais Decretos Federais, Estaduais e/ou Municipais que autorizem a realização de eventos;
            </Text>
            <Text style={styles.listItem}>
              b) A RAYV não é criadora, co-organizadora ou proprietária dos eventos hospedados em sua plataforma, portanto, a adoção de medidas de distanciamento e sanitárias é de total responsabilidade dos Produtores e dos usuários participantes dos eventos;
            </Text>
            <Text style={styles.listItem}>
              c) Os usuários declaram, CONSCIENTEMENTE E LIVRES DE QUALQUER COAÇÃO, ASSUMIR TODOS OS RISCOS DE SAÚDE ENVOLVIDOS NA SUA PARTICIPAÇÃO NOS EVENTOS DURANTE ESSE PERÍODO, tanto conhecidos quanto desconhecidos, mesmo que resultantes da negligência de terceiros, assumindo toda a responsabilidade por participar dos eventos;
            </Text>
            <Text style={styles.listItem}>
              d) A RAYV NÃO POSSUI QUALQUER RESPONSABILIDADE SOBRE A REALIZAÇÃO E AUTORIZAÇÃO DE REALIZAÇÃO DO EVENTO, SOLICITAÇÃO DE DOCUMENTOS, ANÁLISE, TRIAGEM, OU QUALQUER CONSEQUÊNCIA DECORRENTE DA PARTICIPAÇÃO LIVRE E CONSCIENTE DO COMPRADOR NOS EVENTOS DURANTE O PERÍODO ACIMA MENCIONADO.
            </Text>
          </Text>
          <Text style={styles.sectionTitle}>6. Vendas de Ingressos</Text>
          <Text style={styles.body}>
            6.1. O processamento dos pagamentos das vendas de ingressos para
            eventos geralmente começa na página do evento publicada pelo
            Organizador. O Comprador selecionará o tipo de ingresso desejado e o
            método de pagamento disponível, finalizando o pedido em seguida.
          </Text>
          <Text style={styles.body}>
            6.1.1. Após a confirmação do pagamento, que pode ser feita por um
            intermediário financeiro, a RAYV recolherá o valor correspondente,
            deduzirá a taxa de serviço e repassará o valor restante ao
            Organizador, conforme o modelo de recebimento escolhido. Todas as
            transações realizadas através da RAYV passam por processos internos
            e automatizados de análise de risco (veja Item 8), que podem ser
            complementados pelos nossos processadores de pagamentos.
          </Text>
          <Text style={styles.body}>
            6.1.2. Se a RAYV identificar alguma inconsistência no procedimento
            de pagamento, a compra poderá ser submetida a um novo processo de
            verificação a qualquer momento. O Comprador será notificado por
            e-mail, no mesmo endereço associado à compra, caso o pedido seja
            suspenso ou cancelado pela RAYV.
          </Text>
          <Text style={styles.sectionTitle}>7. Entrega e Utilização de Ingressos</Text>
          <Text style={styles.body}>
            7.1. Assim que o pagamento for confirmado, um e-mail de confirmação
            da compra será enviado para o endereço de e-mail fornecido pelo
            Comprador. Os ingressos estarão disponíveis na seção “Meus
            ingressos” do aplicativo da RAYV, que deve ser usado para check-in e
            acesso ao evento.
          </Text>
          <Text style={styles.body}>
            7.1.1. Para acessar os ingressos, os usuários precisam ter uma conta
            na Plataforma com o mesmo e-mail usado para identificar a
            titularidade dos ingressos.
          </Text>
          <Text style={styles.body}>
            7.2. Os ingressos estão vinculados a códigos dinâmicos (QR Code),
            permitindo que os usuários gerem seu código no momento da compra do
            ingresso.
          </Text>
          <Text style={styles.body}>
            7.2.1. Se o Comprador adquirir ingressos para outros Usuários, ele
            será responsável por enviar os ingressos para esses Usuários,
            permitindo que cada um acesse o evento com seu próprio dispositivo
            móvel.
          </Text>
          <Text style={styles.body}>
            7.2.2. Após o recebimento dos ingressos, é responsabilidade dos
            Usuários mantê-los seguros, não os divulgando publicamente ou
            permitindo o acesso de terceiros.
          </Text>
          <Text style={styles.body}>
            7.3. Os ingressos comprados pela Plataforma RAYV são nominais, sendo
            decisão do Organizador verificar essa informação no momento do
            acesso ao evento. A não verificação dessa informação não gerará
            qualquer expectativa de direito ou indenização.
          </Text>
          <Text style={styles.body}>
            7.4. Os ingressos adquiridos na Plataforma RAYV podem ser usados
            para acessar o evento uma única vez. O check-in do Participante, que
            envolve a leitura e validação do QR Code dos ingressos, é de
            responsabilidade do Organizador.
          </Text>
          <Text style={styles.body}>
            7.4.1. O acesso do Participante ao evento não será permitido se o
            check-in do ingresso já tiver sido realizado anteriormente, salvo
            decisão exclusiva do Organizador.
          </Text>
          <Text style={styles.body}>
            7.5. A RAYV pode enviar comunicações relacionadas aos ingressos e/ou
            eventos, e é responsabilidade dos usuários garantir que seus
            sistemas anti-spam, filtros ou similares não interfiram no
            recebimento dessas comunicações. A não recepção de e-mails ou
            mensagens eletrônicas devido a esses recursos não será aceita como
            justificativa e não gerará qualquer expectativa de direito ou
            indenização.
          </Text>
          <Text style={styles.sectionTitle}>8. Análise de Risco</Text>
          <Text style={styles.body}>
            8.1. Todas as transações realizadas na Plataforma passam por uma
            análise de risco para identificar possíveis fraudes. Esse processo é
            essencial para garantir a segurança e a integridade das transações,
            sendo feito com base em padrões históricos, conferência de dados
            cadastrais, bases externas e fornecedores ou parceiros. Com base na
            probabilidade de uma transação ser fraudulenta, a compra pode ser
            aprovada ou não, de acordo com critérios pré-estabelecidos. A não
            aprovação não gerará expectativa de direito ou qualquer tipo de
            indenização.
          </Text>
          <Text style={styles.sectionTitle}>9. Cancelamento e Reembolso de Transações</Text>
          <Text style={styles.body}>
            9.1. A plataforma RAYV permite que o Organizador solicite o
            cancelamento de uma venda e o reembolso do valor do ingresso para o
            Comprador, descontada a taxa de serviço, até o 2º dia útil após o
            término do evento. É responsabilidade do Organizador informar seus
            potenciais usuários sobre sua política de cancelamento, que deve
            estar alinhada com o período máximo de reembolso permitido pela
            RAYV, e usar as ferramentas da plataforma para solicitar o
            cancelamento quando necessário.
          </Text>
          <Text style={styles.body}>
            9.1.1. Cabe ao Comprador informar-se sobre a política específica do
            evento que deseja participar e, se necessário, entrar em contato com
            o Organizador utilizando o e-mail usado na compra para solicitar o
            cancelamento e o reembolso do valor do ingresso.
          </Text>
          <Text style={styles.body}>
            9.1.2. O Participante não pode solicitar o reembolso dos ingressos,
            sendo essa uma prerrogativa exclusiva do Comprador (a pessoa que
            realizou a compra na plataforma RAYV), exceto quando Participante e
            Comprador forem a mesma pessoa.
          </Text>
          <Text style={styles.body}>
            9.2. Se for necessário reembolsar os usuários, o Organizador deve
            fazer isso exclusivamente através da plataforma RAYV, sendo proibido
            realizar o reembolso por outros meios.
          </Text>
          <Text style={styles.body}>
            9.2.1. Após o início do procedimento de reembolso pelo Organizador,
            para compras realizadas com cartão de crédito, o Comprador receberá
            uma notificação confirmando o cancelamento da compra e o reembolso
            será efetuado de forma imediata e automática, exceto quando houver
            disposição legal que permita o diferimento. Para compras realizadas
            por boleto, o Comprador receberá um e-mail solicitando o
            preenchimento de dados bancários para que o reembolso seja efetuado.
          </Text>
          <Text style={styles.body}>
            9.2.2. O Comprador é responsável por quaisquer erros ou atrasos no
            fornecimento dos dados bancários para o reembolso dos valores.
          </Text>
          <Text style={styles.body}>
            9.3. Se for identificado que algum evento não foi realizado ou
            ocorreu de maneira diferente do anunciado, a RAYV se reserva o
            direito de reembolsar todas as compras ou suspender,
            temporariamente, o valor do repasse até que a situação seja
            resolvida entre todas as partes envolvidas na transação, ou seja,
            RAYV, Organizador e usuários.
          </Text>
          <Text style={styles.body}>
            9.4. Em caso de arrependimento da compra, o reembolso do valor do
            ingresso, descontada a taxa de serviço, estará garantido, desde que
            a solicitação seja feita ao Organizador dentro de até 7 (sete) dias
            a partir da data da compra. O pedido de devolução deve ser feito
            obrigatoriamente até 48 (quarenta e oito) horas antes do horário de
            início do evento.
          </Text>
          <Text style={styles.sectionTitle}>10. Contestações e Estornos</Text>
          <Text style={styles.body}>
            10.1. As contestações relatadas serão analisadas pela operadora do
            cartão de crédito, pelos nossos processadores de pagamento ou pela
            própria RAYV.
          </Text>
          <Text style={styles.body}>
            10.2. A RAYV gerenciará as disputas iniciadas pelos usuários,
            incluindo, mas não se limitando a, chargebacks (contestações),
            reclamações diversas, cancelamentos e estornos. Portanto, poderá
            iniciar e processar cancelamentos sem a necessidade de intervenção
            ou aprovação do Organizador nos seguintes casos:
            <Text style={styles.listItem}>
              a) não cumprimento, pelo Organizador, da política de reembolsos do evento e/ou do Código de Defesa do Consumidor;
            </Text>
            <Text style={styles.listItem}>
              b) detecção de indícios de fraude em compras realizadas, sejam elas pendentes de aprovação ou já aprovadas, com riscos identificados tanto do lado do Comprador quanto do Organizador;
            </Text>
            <Text style={styles.listItem}>
              c) cancelamento do evento;
            </Text>
            <Text style={styles.listItem}>
              d) erro técnico no processamento da transação;
            </Text>
            <Text style={styles.listItem}>
              e) verificação de um número elevado de queixas referentes a um determinado evento, feitas pelos Consumidores.
            </Text>
          </Text>
          <Text style={styles.sectionTitle}>11. Taxa de serviço e emissão de nota fiscal</Text>
          <Text style={styles.body}>
            11.1. Será cobrada do Organizador uma taxa de serviço para o uso da
            plataforma RAYV, calculada a partir das vendas online processadas
            através dela.
          </Text>
          <Text style={styles.body}>
            11.2. A taxa de serviço cobrada para eventos presenciais é de até
            08% (oito por cento) sobre o valor do ingresso pago, com um valor
            mínimo de taxa de R$ 2,50 (dois reais e cinquenta centavos) por
            ingresso. O valor da taxa pode variar também conforme limitações
            estaduais e municipais. O valor das taxas de serviço aplicáveis ao
            evento podem ser verificadas no momento de realização da compra pelo
            Comprador ou da criação dos ingressos pagos pelo Organizador. É uma
            opção do Organizador repassá-la diretamente ao Comprador. Não será
            cobrada taxa de serviço na distribuição de ingressos gratuitos.
          </Text>
          <Text style={styles.body}>
            11.4. A RAYV somente emite Nota Fiscal para Produtores, tendo em
            vista serem estes os tomadores dos serviços prestados.
          </Text>
          <Text style={styles.sectionTitle}>12. Direitos de Propriedade Intelectual</Text>
          <Text style={styles.body}>
            12.1. A expressão "RAYV" usada como marca, nome empresarial ou nome
            de domínio, assim como os conteúdos das páginas relacionadas aos
            serviços da RAYV, bancos de dados, redes e arquivos que permitem ao
            usuário acessar e utilizar sua conta, são de propriedade da RAYV e
            estão protegidos por leis e tratados internacionais de direitos
            autorais, marcas, patentes, modelos e desenhos industriais. O uso
            indevido e a reprodução, total ou parcial, desses conteúdos são
            proibidos, exceto se houver autorização prévia e expressa por
            escrito da RAYV.
          </Text>
          <Text style={styles.body}>
            12.2. As páginas dos eventos podem conter links para outros sites, o
            que não implica que esses sites sejam de propriedade ou operados
            pela RAYV. A RAYV não tem controle sobre esses sites de terceiros e,
            portanto, não é responsável pelo seu conteúdo, práticas e serviços
            oferecidos. A inclusão de links para outros sites não indica
            qualquer tipo de associação, supervisão, cumplicidade ou
            solidariedade da RAYV com esses sites e seus conteúdos.
          </Text>
          <Text style={styles.sectionTitle}>13. Segurança da Plataforma RAYV</Text>
          <Text style={styles.body}>
            13.1. O acesso às áreas de programação da plataforma RAYV, seu banco
            de dados ou qualquer outro conjunto de informações que faz parte da
            atividade de Webmastering não é permitido.
          </Text>
          <Text style={styles.body}>
            13.2. Os usuários não estão autorizados a realizar ou permitir
            engenharia reversa, nem a traduzir, decompilar, copiar, modificar,
            reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir,
            emprestar, distribuir ou de qualquer outra forma dispor das
            ferramentas de consulta da plataforma RAYV e suas funcionalidades.
          </Text>
          <Text style={styles.body}>
            13.3. Na plataforma RAYV, é proibido o uso de aplicativos spider ou
            de mineração de dados, de qualquer tipo ou espécie, além de qualquer
            outro tipo não especificado aqui, que atuem de forma automatizada,
            tanto para realizar operações em massa quanto para quaisquer outras
            finalidades.
          </Text>
          <Text style={styles.sectionTitle}>14. Privacidade dos Usuários</Text>
          <Text style={styles.body}>
            14.1. A RAYV possui um documento específico chamado "Política de
            Privacidade" que regula o tratamento das informações e dados
            pessoais coletados por meio da plataforma RAYV e suas
            funcionalidades.
          </Text>
          <Text style={styles.body}>
            14.1.1. A "Política de Privacidade" é parte integrante e inseparável
            dos Termos de Uso da plataforma RAYV e pode ser acessada através do
            link localizado no rodapé da página.
          </Text>
          <Text style={styles.body}>
            14.1.2. Em caso de conflito entre qualquer disposição da "Política
            de Privacidade" e qualquer outra deste documento, prevalecerá a
            norma mais específica.
          </Text>
          <Text style={styles.sectionTitle}>15. Proteção de Registros, Informações e Comunicações Privadas</Text>
          <Text style={styles.body}>
            15.1. A RAYV só será obrigada a fornecer registros de acesso,
            informações pessoais ou comunicações privadas armazenadas em sua
            plataforma mediante ordem judicial ou solicitação de autoridade
            policial ou administrativa competente.
          </Text>
          <Text style={styles.body}>
            15.1.1. Em todos os casos, a RAYV informará o Organizador sobre a
            solicitação de revelação, estando desde já autorizada a responder à
            requisição recebida.
          </Text>
          <Text style={styles.sectionTitle}>16. Limitação de Responsabilidade</Text>
          <Text style={styles.body}>
            16.1. ESTÁ EXPRESSAMENTE ESTABELECIDO QUE A RAYV NÃO TEM A OBRIGAÇÃO
            DE CONTROLAR TODAS AS AÇÕES EXECUTADAS PELOS USUÁRIOS NA UTILIZAÇÃO
            DA PLATAFORMA RAYV E, PORTANTO, A RAYV NÃO PODE SER RESPONSABILIZADA
            PELOS ATOS DE SEUS USUÁRIOS, INCLUINDO AQUELES QUE SEJAM ILÍCITOS,
            IMORAIS OU ANTIÉTICOS. NESSES CASOS, OS USUÁRIOS SERÃO PESSOAL E
            EXCLUSIVAMENTE RESPONSÁVEIS POR EVENTUAIS RECLAMAÇÕES DE TERCEIROS
            OU AÇÕES JUDICIAIS, DEVENDO ISENTAR A RAYV DE QUALQUER
            RESPONSABILIDADE OU ÔNUS.
          </Text>
          <Text style={styles.body}>
            16.2. A RAYV não se responsabiliza por qualquer dano, direto ou
            indireto, causado por eventos de terceiros, como ataques de hackers,
            falhas no sistema, no servidor ou na conexão à internet, incluindo
            ações de programas maliciosos como vírus, cavalos de troia e outros
            que possam, de alguma forma, danificar o equipamento ou a conexão
            dos usuários devido ao acesso, uso ou navegação na plataforma RAYV,
            exceto se tal fato ocorrer por dolo ou culpa da RAYV.
          </Text>
          <Text style={styles.body}>
            16.3. Em nenhuma circunstância a RAYV, seus proprietários, gerentes,
            funcionários, representantes, agentes e/ou terceiros atuando em seu
            nome serão responsáveis pelo pagamento de indenização ao Usuário
            e/ou Consumidor, ou seus herdeiros, sucessores e representantes
            legais, por danos materiais, morais, lucros cessantes e danos
            emergentes decorrentes da participação nos eventos.
          </Text>
          <Text style={styles.sectionTitle}>17. Disposições Gerais</Text>
          <Text style={styles.body}>
            17.1. A RAYV não se responsabiliza por quaisquer danos ou prejuízos
            causados pelo envio de informações ou conteúdo publicitário que não
            tenham sido enviados pela própria RAYV, bem como pelo conteúdo das
            opiniões postadas nas páginas dos eventos.
          </Text>
          <Text style={styles.body}>
            17.2. Ao navegar pela plataforma RAYV e utilizar suas
            funcionalidades, os usuários concordam com todos os termos dos
            Termos de Uso e demais políticas legais vigentes na data de acesso.
            Portanto, é recomendável que os usuários se mantenham informados e
            atualizados.
          </Text>
          <Text style={styles.listItem}>
            17.2.1. Nossos serviços são destinados a pessoas maiores de 18 anos,
            que possuam documento pessoal e endereço de e-mail válidos. Menores
            de idade ou incapazes podem usar os serviços, desde que devidamente
            autorizados ou assistidos por seus representantes legais.
          </Text>
          <Text style={styles.body}>
            17.3. Os Termos de Uso estão sujeitos a melhorias e aprimoramentos
            contínuos. Dessa forma, a RAYV se reserva o direito de modificá-los
            a qualquer momento, conforme os objetivos da plataforma RAYV, bem
            como para adequação e conformidade com novas leis ou normas com
            força jurídica equivalente, cabendo aos usuários verificar as
            atualizações sempre que acessarem a plataforma RAYV.
          </Text>
          <Text style={styles.body}>
            17.4. Qualquer tolerância em relação à violação dos termos e
            condições aqui contidos será considerada mera liberalidade e não
            será interpretada como novação, precedente invocável, renúncia a
            direitos, alteração tácita dos termos contratuais, direito adquirido
            ou alteração contratual.
          </Text>
          <Text style={styles.body}>
            17.5. Se alguma disposição destes Termos de Uso for considerada
            inaplicável ou sem efeito, as demais normas continuarão em vigor,
            sem a necessidade de uma medida judicial que declare tal assertiva.
            Os Termos aqui descritos serão interpretados de acordo com a
            legislação brasileira.
          </Text>
          <Text style={styles.body}>
            17.6. A comunicação entre a RAYV e o usuário deverá ser realizada
            pelos canais de atendimento indicados e disponibilizados na
            plataforma RAYV, especialmente através do link fornecido.
          </Text>
          <Text style={styles.body}>
            17.7. Sempre que possível, potenciais divergências entre o usuário e
            a RAYV serão resolvidas de forma amigável. Quando todos os esforços
            nesse sentido forem esgotados, você concorda que o foro da comarca
            de São João Del Rei, no Estado de Minas Gerais, será o eleito para
            resolver controvérsias ou queixas decorrentes da utilização da nossa
            plataforma ou relacionadas a estes Termos de Uso.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
