import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const checkExistingEventReport = async (eventName, reportingUserId) => {
    try {
      const reportQuery = query(
        collection(db, "eventsReports"),
        where("eventName", "==", eventName),
        where("reportingUserId", "==", reportingUserId)
      );
      const reportSnapshot = await getDocs(reportQuery);
  
      return !reportSnapshot.empty;
    } catch (error) {
      console.error("Erro ao verificar denúncia existente: ", error);
      return false;
    }
  };

  export const getEventReportCount = async (username) => {
    try {
      const eventsRef = collection(db, "eventsData");
      const q = query(eventsRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const eventDoc = querySnapshot.docs[0];
        const eventData = eventDoc.data();
        const reportCount = eventData.reportCount || 0;
  
        return reportCount;
      } else {
        console.error("Evento não encontrado com o username:", username);
        return null;
      }
    } catch (error) {
      console.error("Erro ao obter a contagem de denúncias do evento:", error);
      throw error;
    }
  };
