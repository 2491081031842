import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";
import { updateEventReportCountByUsername } from "../events/updateEvent";

export const createReport = async (reportData) => {
  try {
    if (!reportData.eventName || !reportData.reportingUserId || !reportData.message) {
      throw new Error("Todos os campos são obrigatórios para criar uma denúncia");
    }

    const reportRef = collection(db, "eventsReports");
    await addDoc(reportRef, reportData);
    updateEventReportCountByUsername(reportData.eventUsername);
    return true;
  } catch (error) {
    console.error("Erro ao criar denúncia: ", error);
    return false;
  }
};