import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Modal,
  Alert,
  TouchableWithoutFeedback,
  Share,
} from "react-native";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../services/utils/firebase/firebaseConfig";
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";
import { onUserProfileSnapshot, getUserEssential } from "../../../services/cruds/users/getUser";

const ProfileTop = ({
  isVisited,
  userId,
  avatarUrl,
  fullName,
  username,
  progressProfile,
  descriptionProfile,
  accountType,
  eventHistory = [],
  hiddenEvents = [],
  followers = [],
  following = [],
  pendingFollowers = [],
  registrationDate,
  rewardsPoints,
  reviews,
  isPublic,
  navigation,
  currentUser,
  isCurrentUser,
}) => {
  const [userIdVisit, setUserIdVisit] = useState(null);
  const [liveFollowers, setLiveFollowers] = useState(followers);
  const [livePendingFollowers, setLivePendingFollowers] = useState(pendingFollowers);
  const [modalVisible, setModalVisible] = useState(false);
  const [loggedInUserAccountType, setLoggedInUserAccountType] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserIdVisit(user.uid);
        const userEssential = await getUserEssential(user.uid);
        setLoggedInUserAccountType(userEssential?.accountType);
      } else {
        console.log("Nenhum usuário conectado");
      }
    });

    if (userId) {
      const unsubscribeFollowers = onUserProfileSnapshot(userId, (data) => {
        if (data) {
          setLiveFollowers(data.followers || []);
          setLivePendingFollowers(data.pendingFollowers || []);
        }
      });

      return () => {
        unsubscribeAuth();
        unsubscribeFollowers();
      };
    }
  }, [userId, userIdVisit]);

  const date = registrationDate
    ? new Date(
        registrationDate.seconds * 1000 + registrationDate.nanoseconds / 1000000
      )
    : new Date();

  const year = date.toLocaleDateString("pt-BR", {
    year: "numeric",
  });

  const handleShareProfile = async () => {
    console.log("Botão Compartilhar Perfil clicado");
    try {
      const result = await Share.share({
        message: `Confira o perfil de ${username} em rayv.com.br/visit/${username}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          console.log("Compartilhado via", result.activityType);
        } else {
          console.log("Perfil compartilhado");
        }
      } else if (result.action === Share.dismissedAction) {
        console.log("Compartilhamento cancelado");
      }
    } catch (error) {
      Alert.alert("Erro ao compartilhar", error.message);
    }
  };

  const handleFollow = () => {
    navigation.navigate("DownloadAppScreen");
  };

  const verified = progressProfile === 2;

  const calculateLevel = (points) => {
    let level = 1;
    let pointsRequired = 100;

    while (points >= pointsRequired) {
      points -= pointsRequired;
      level++;
      pointsRequired = Math.ceil(pointsRequired * 1.2);
    }

    return level;
  };

  const filteredEventHistory = eventHistory.filter(
    (eventId) => !hiddenEvents.includes(eventId)
  );

  const averageRating = reviews ? reviews.rating : 0;

  const renderStars = () => {
    const filledStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);

    return (
      <View style={styles.starsContainer}>
        {Array(filledStars)
          .fill()
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star" size={18} color="#FFF" />
          ))}
        {halfStar && <MaterialCommunityIcons name="star-half-full" size={18} color="#FFF" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <MaterialCommunityIcons key={index} name="star-outline" size={18} color="#FFF" />
          ))}
      </View>
    );
  };

  return (
    <SafeAreaView>
      <View style={styles.profileContainer}>
        <View style={styles.avatarContainer}>
          <TouchableOpacity onPress={() => setModalVisible(true)}>
            <Image
              source={
                avatarUrl !== "" ? { uri: avatarUrl } : defaultAvatarImage
              }
              style={styles.avatar}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.levelCircle}
            onPress={() => navigation.navigate("Home")}
          >
            <Text style={styles.levelText}>
              {calculateLevel(rewardsPoints)}
            </Text>
          </TouchableOpacity>
          <FontAwesome5
            name="circle"
            size={20}
            color="#5B2372"
            style={styles.levelIcon}
          />
        </View>
        <View style={styles.userInfoContainer}>
          <View style={styles.infoContainer}>
            <Text style={styles.fullName}>
              {fullName !== "" ? fullName : "Convidado"}
            </Text>
            {verified || accountType === "" ? (
              <MaterialCommunityIcons
                name="check-circle"
                size={18}
                color="#4CAF50"
                style={styles.verifiedIcon}
              />
            ) : null}
            {isPublic === false && (
              <MaterialCommunityIcons
                name="lock"
                size={18}
                color="#FFF9"
                style={styles.privacyIcon}
              />
            )}
          </View>
          <View style={styles.infoContainer}>
            <MaterialCommunityIcons
              name="card-account-details"
              size={13}
              color="#C0C0C0"
              style={styles.infoIcon}
            />
            <Text style={styles.memberSince}>
              {username !== "" ? username : "convidado"}
            </Text>
          </View>
          <View style={styles.infoContainer}>
            <MaterialCommunityIcons
              name="calendar"
              size={13}
              color="#C0C0C0"
              style={styles.infoIcon}
            />
            <Text style={styles.memberSince}>
              since {year !== "" ? year : "202?"}{" "}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.generalContainer}>
        {descriptionProfile ? (<Text style={styles.description}>
          {isVisited || !(userId == "")
            ? descriptionProfile
              ? descriptionProfile
              : ""
            : "Faça seu login para mais informações"}
        </Text>) : ((!isVisited && (userId == "")) ? (<Text style={styles.description}>Faça seu login para mais informações</Text>) : null)}
        <View style={styles.statsContainer}>
          {accountType === "Producer" && (
            <TouchableOpacity
              style={styles.statItem}
              onPress={() =>
                navigation.navigate("ProfileDetails", {
                  followers,
                  following,
                  username,
                  pendingFollowers,
                  eventHistory: isCurrentUser ? eventHistory : filteredEventHistory,
                  hiddenEvents,
                  reviews,
                  userId,
                  isVisited,
                  accountType,
                  initialTab: "Reviews",
                  isPublic,
                  currentUser,
                  isCurrentUser,
                })
              }
            >
              {renderStars()}
              <Text style={styles.statLabel}>avaliação</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={styles.statItem}
            onPress={() =>
              navigation.navigate("ProfileDetails", {
                followers,
                following,
                username,
                pendingFollowers,
                eventHistory: isCurrentUser ? eventHistory : filteredEventHistory,
                hiddenEvents,
                reviews,
                userId,
                isVisited,
                accountType,
                initialTab: "Eventos",
                isPublic,
                currentUser,
                isCurrentUser,
              })
            }
          >
            <Text style={styles.statNumber}>{isCurrentUser ? eventHistory.length : filteredEventHistory.length}</Text>
            <Text style={styles.statLabel}>eventos</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.statItem}
            onPress={() =>
              navigation.navigate("ProfileDetails", {
                followers,
                following,
                username,
                pendingFollowers,
                eventHistory: isCurrentUser ? eventHistory : filteredEventHistory,
                hiddenEvents,
                reviews,
                userId,
                isVisited,
                accountType,
                initialTab: "Seguidores",
                isPublic,
                currentUser,
                isCurrentUser,
              })
            }
          >
            <Text style={styles.statNumber}>{liveFollowers.length}</Text>
            <Text style={styles.statLabel}>seguidores</Text>
          </TouchableOpacity>
          {accountType !== "Producer" && (
            <TouchableOpacity
              style={styles.statItem}
              onPress={() =>
                navigation.navigate("ProfileDetails", {
                  followers,
                  following,
                  username,
                  pendingFollowers,
                  eventHistory: isCurrentUser ? eventHistory : filteredEventHistory,
                  hiddenEvents,
                  reviews,
                  userId,
                  isVisited,
                  accountType,
                  initialTab: "Seguindo",
                  isPublic,
                  currentUser,
                  isCurrentUser,
                })
              }
            >
              <Text style={styles.statNumber}>{following.length}</Text>
              <Text style={styles.statLabel}>seguindo</Text>
            </TouchableOpacity>
          )}
        </View>
        {accountType !== "" && (
          <View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity style={styles.button} onPress={handleFollow}>
                <Text style={styles.buttonText}>Seguir</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={handleShareProfile}
              >
                <Text style={styles.buttonText}>Compartilhar Perfil</Text>
              </TouchableOpacity>
            </View>
            {!isVisited && progressProfile < 2 && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.buttonSegundo}
                  onPress={() => navigation.navigate("EditProfile")}
                >
                  <Text style={styles.buttonText}>Completar Cadastro</Text>
                </TouchableOpacity>
              </View>
            )}
            {!isVisited &&
              progressProfile == 2 &&
              accountType === "Producer" && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.buttonSegundo}
                    onPress={() => navigation.navigate("ManageBalance")}
                  >
                    <Text style={styles.buttonText}>Gerenciar Saldo</Text>
                  </TouchableOpacity>
                </View>
              )}
              {!isVisited &&
              progressProfile == 2 &&
              accountType === "User" && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.buttonSegundo}
                    onPress={() => navigation.navigate("MyTickets")}
                  >
                    <Text style={styles.buttonText}>Meus Ingressos</Text>
                  </TouchableOpacity>
                </View>
              )}
          </View>
        )}
        {accountType === "" && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.buttonLogin}
              onPress={() => navigation.navigate("Login")}
            >
              <Text style={styles.buttonText}>Fazer Login</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <TouchableOpacity onPress={() => setModalVisible(false)}>
                <Image
                  source={
                    avatarUrl !== "" ? { uri: avatarUrl } : defaultAvatarImage
                  }
                  style={styles.modalImage}
                />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    flexDirection: "row",
    padding: 10,
    backgroundColor: "#000",
    alignItems: "center",
  },
  avatarContainer: {
    marginRight: 15,
    position: "relative",
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  levelCircle: {
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "#5B2372",
    width: 20,
    height: 20,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  levelText: {
    color: "white",
    fontSize: 12,
  },
  levelIcon: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  userInfoContainer: {
    flex: 1,
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  fullName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
  verifiedIcon: {
    marginStart: 5,
    paddingTop: 3,
  },
  privacyIcon: {
    marginStart: 5,
    paddingTop: 3,
  },
  memberSince: {
    fontSize: 13,
    paddingStart: 5,
    color: "#C0C0C0",
  },
  infoIcon: {
    right: 0,
    top: 3,
  },
  generalContainer: {
    paddingHorizontal: 15,
  },
  description: {
    fontSize: 14,
    fontWeight: "200",
    color: "white",
    marginHorizontal: 5,
  },
  statsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 30,
    marginTop: 8,
  },
  statItem: {
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  statNumber: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  statLabel: {
    fontSize: 14,
    color: "white",
  },
  notificationDot: {
    position: "absolute",
    top: 0,
    right: -5,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: "#5B2372",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: "#5B2372",
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 4,
    flex: 1,
    marginHorizontal: 5,
  },
  buttonSegundo: {
    backgroundColor: "#282828",
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 4,
    flex: 1,
    marginTop: 7,
    marginHorizontal: 5,
  },
  buttonLogin: {
    backgroundColor: "#282828",
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 4,
    flex: 1,
    marginHorizontal: 5,
  },
  buttonText: {
    textAlign: "center",
    color: "white",
    fontSize: 13,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    borderRadius: 10,
    padding: 10,
  },
  modalImage: {
    width: 200,
    height: 200,
    borderRadius: 20,
    resizeMode: "cover",
  },
  privateContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  privateText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16,
  },
  starsContainer: {
    flexDirection: "row",
  },
});

export default ProfileTop;
