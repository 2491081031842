import React, { useState } from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { EventDetails } from "./EventDetails";
import { LocalDetails } from "./LocalDetails";
import { PolicyDetails } from "./PolicyDetails";
import Communication from "./Communication"; 

const ButtonNavigator = ({ evento, abrirMapa }) => {
  const [activeTab, setActiveTab] = useState("Descrição");

  const renderContent = () => {
    switch (activeTab) {
      case "Descrição":
        return (
          <EventDetails
            description={evento?.description}
            socialMedia={evento?.organizer?.socialMedia}
            contact={evento?.organizer?.contact}
          />
        );
      case "Local":
        return (
          <LocalDetails
            address={evento?.address}
            showOnMap={evento?.address?.showOnMap}
            abrirMapa={abrirMapa}
          />
        );
      case "Políticas":
        return (
          <PolicyDetails
            privacy={evento?.privacy}
            refundPolicy={evento?.refundPolicy}
            eventRules={evento?.eventRules}
          />
        );
      case "Comunicado":
        return <Communication eventId={evento.username} />;
      default:
        return null;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.tabBar}>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === "Descrição" && styles.activeTabButton]}
          onPress={() => setActiveTab("Descrição")}
        >
          <Text style={styles.tabButtonText}>Descrição</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === "Comunicado" && styles.activeTabButton]}
          onPress={() => setActiveTab("Comunicado")}
        >
          <Text style={styles.tabButtonText}>Comunicado</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === "Local" && styles.activeTabButton]}
          onPress={() => setActiveTab("Local")}
        >
          <Text style={styles.tabButtonText}>Local</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === "Políticas" && styles.activeTabButton]}
          onPress={() => setActiveTab("Políticas")}
        >
          <Text style={styles.tabButtonText}>Políticas</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.contentContainer}>
        {renderContent()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#000",
    paddingVertical: 10,
  },
  tabButton: {
    padding: 10,
    borderBottomWidth: 1.5,
    borderBottomColor: "#121212",
  },
  tabButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 15,
  },
  activeTabButton: {
    borderBottomWidth: 1.5,
    borderBottomColor: "#fff",
  },
  contentContainer: {
    flex: 1,
    padding: 10,
  },
});

export default ButtonNavigator;
