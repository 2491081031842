import { getEvent } from "../events/getEvent";
import { auth } from "../../utils/firebase/firebaseConfig";
import axios from 'axios';

const API_URL = "https://us-central1-rayv-a7c48.cloudfunctions.net/api";

const getIdToken = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("Usuário não autenticado.");
  }
  return await user.getIdToken();
};

export const buscarEventoPorId = async (id) => {
  try {
    const dadosEvento = await getEvent(id);
    return dadosEvento;
  } catch (error) {
    console.error("Erro ao buscar evento:", error);
    Alert.alert("Erro", "Não foi possível carregar o evento.");
  }
};

export const validarQrCode = async (ticketId, key) => {
  try {
    const token = await getIdToken();
    const response = await axios.post(
      `${API_URL}/verificarQr`,
      { ticketId, key },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  } catch (error) {
    console.error("Erro ao validar ingresso:", error);
  }
}

export const validarTicket = async (ticketId, code) => {
  try {
    const token = await getIdToken();
    const response = await axios.post(
      `${API_URL}/verificarTicket`,
      { ticketId, code },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data);
    }

    return response.data;
    
  } catch (error) {
    console.error("Erro ao validar ingresso:", error.response ? error.response.data : error.message);
    throw new Error(error.response ? error.response.data : error.message);
  }
}

export const validarTicketProdutor = async (ticketId) => {
  try {
    const token = await getIdToken();
    const response = await axios.post(
      `${API_URL}/verifyTicketProducer`,
      { ticketId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data);
    }

    return response.data;
    
  } catch (error) {
    console.error("Erro ao validar ingresso:", error.response ? error.response.data : error.message);
    throw new Error(error.response ? error.response.data : error.message);
  }
}

export const desvalidarTicketProdutor = async (ticketId) => {
  try {
    const token = await getIdToken();
    const response = await axios.post(
      `${API_URL}/unverifyTicketProducer`,
      { ticketId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data);
    }

    return response.data;
    
  } catch (error) {
    console.error("Erro ao desvalidar ingresso:", error.response ? error.response.data : error.message);
    throw new Error(error.response ? error.response.data : error.message);
  }
}
