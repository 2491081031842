import React, { useState } from "react";
import { View, Image, TouchableOpacity, StyleSheet, Text } from "react-native";
import { MaterialCommunityIcons} from "@expo/vector-icons";
import ContentLoader, { Circle } from 'react-content-loader/native';
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";

const UserList = ({ usuario, onPressItem, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Garantir que usuario.hiddenEvents esteja definido
  const hiddenEvents = usuario.hiddenEvents || [];

  // Ordenar os adesivos por data de criação e filtrar adesivos de eventos ocultos
  const sortedStickers = usuario.stickers
    ? Object.entries(usuario.stickers)
        .filter(([key]) => !hiddenEvents.includes(key))
        .sort((a, b) => {
          return (
            new Date(a[1].creationDate.toDate()) -
            new Date(b[1].creationDate.toDate())
          );
        })
    : [];

  const verified = usuario.progressProfile === 2;

  return (
    <TouchableOpacity
      onPress={() => onPressItem(usuario)}
      style={[styles.card, { backgroundColor: "#000" }]}
    >
      <View style={styles.imageContainer}>
        {!imageLoaded && (
          <ContentLoader
            speed={1}
            width={55}
            height={55}
            backgroundColor="#3d3d3d"
            foregroundColor="#1c1c1c"
          >
            <Circle cx="27.5" cy="27.5" r="27.5" />
          </ContentLoader>
        )}
        <Image
          source={
            usuario.avatarUrl !== ""
              ? { uri: usuario.avatarUrl }
              : defaultAvatarImage
          }
          style={styles.cardImage}
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)} // Para garantir que o estado seja atualizado mesmo em caso de erro no carregamento
        />
      </View>
      <View style={styles.cardContent}>
        <View style={styles.infoContainer}>
          <Text style={styles.cardTitle}>{usuario.fullName}</Text>
          {verified ? (
            <MaterialCommunityIcons
              name="check-circle"
              size={14}
              color="#4CAF50"
              style={styles.verifiedIcon}
            />
          ) : null}
          {usuario.accountType === "Producer" && (
            <MaterialCommunityIcons
              name="party-popper"
              size={14}
              color="#FFF9" 
              style={styles.producerIcon}
            />
          )}
          {!usuario.isPublic ? (
            <MaterialCommunityIcons
              name="lock"
              size={14}
              color="#FFF9"
              style={styles.privacyIcon}
            />
          ) : null}
        </View>
        <Text style={styles.cardSubtitle}>@{usuario.username}</Text>
        {usuario.isPublic && sortedStickers.length > 0 && (
          <View style={styles.insigniasContainer}>
            {sortedStickers.slice(0, 3).map(([key, value], stickerIndex) => (
              <Image
                key={stickerIndex}
                source={{ uri: value.url }}
                style={styles.insigniaImage}
              />
            ))}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default UserList;

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    backgroundColor: "#000",
    alignItems: "center",
    borderRadius: 5,
    overflow: "hidden",
    marginBottom: 15,
    paddingHorizontal: 10,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowColor: "#000",
    shadowOffset: { height: 3, width: 0 },
    elevation: 3,
    paddingHorizontal: 10,
    padding: 5,
  },
  imageContainer: {
    position: "relative",
    width: 55,
    height: 55,
  },
  cardImage: {
    width: 55,
    height: 55,
    borderRadius: 100,
    position: "absolute",
    top: 0,
    left: 0,
  },
  cardContent: {
    flex: 1,
    marginLeft: 20,
    justifyContent: "center",
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  cardSubtitle: {
    fontSize: 12,
    color: "#FFFD",
  },
  insigniasContainer: {
    flexDirection: "row",
    marginTop: 7,
  },
  insigniaImage: {
    width: 15,
    height: 15,
    marginRight: 4,
  },
  infoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  producerIcon: {
    paddingTop: 3,
    marginStart: 3,
  },
  verifiedIcon: {
    paddingTop: 3,
    marginStart: 3,
  },
  privacyIcon: {
    paddingTop: 1,
    marginStart: 3,
  },
});
