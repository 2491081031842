import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  containerHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#000",
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "#FFF",
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#121212",
    backgroundColor: "#000",
  },
  settingIcon: {
    color: "#FFFD",
    width: 24,
  },
  settingTextContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  settingTitle: {
    fontSize: 14,
    color: "#FFFD",
  },
  settingArrow: {
    color: "#FFFD",
  },
  answerContainer: {
    padding: 20,
    backgroundColor: "#121212",
  },
  answerText: {
    fontSize: 14,
    color: "#FFFD",
  },
});
