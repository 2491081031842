import React from 'react';
import { View, Text, TouchableOpacity, Modal, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

const FeesSection = ({ evento, feesModalVisible, setFeesModalVisible }) => (
  <View>
    <View style={styles.sectionDividerSecond}></View>
    <TouchableOpacity onPress={() => setFeesModalVisible(true)}>
      <View style={styles.feesContainer}>
        <Icon name="cash-outline" size={24} color="#FFFD" />
        <View style={styles.feesDetails}>
          <Text style={styles.feesTitle}>Detalhes das Taxas</Text>
          <Text style={styles.feesDescription}>Clique aqui para ver as taxas de pagamento</Text>
        </View>
      </View>
    </TouchableOpacity>
    <Modal visible={feesModalVisible} transparent={true} animationType="slide" onRequestClose={() => setFeesModalVisible(false)}>
      <TouchableWithoutFeedback onPress={() => setFeesModalVisible(false)}>
        <View style={modalStyles.modalContainer}>
          <View style={modalStyles.modalContent}>
            <TouchableOpacity style={modalStyles.modalCloseButton} onPress={() => setFeesModalVisible(false)}>
              <Icon name="close" size={24} color="#FFF" />
            </TouchableOpacity>
            <Text style={modalStyles.modalTitle}>Detalhes das Taxas</Text>
            {evento?.absorbFee ? (
              <Text style={modalStyles.feesText}>As taxas estão inclusas no valor do ingresso.</Text>
            ) : (
              <>
                <Text style={modalStyles.feesText}>Taxas para Cartão: 8%</Text>
                <Text style={modalStyles.feesText}>Taxas para Pix: 5%</Text>
                <Text style={modalStyles.feesText}>Taxas para Boleto: 8% + R$3,50</Text>
              </>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
    <View style={styles.sectionDividerSecond}></View>
  </View>
);

const modalStyles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalContent: {
        backgroundColor: "#1C1C1C",
        borderRadius: 10,
        padding: 20,
        width: "80%",
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
        width: 0,
        height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalTitle: {
        fontSize: 18,
        color: "#FFFFFF",
        marginBottom: 15,
    },
    reportOption: {
        width: "100%",
        backgroundColor: "#3D3D3D",
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
    },
    reportOptionText: {
        color: "#FFF",
        fontSize: 16,
        textAlign: "center",
    },
    modalCloseButton: {
        position: "absolute",
        top: 8,
        right: 8,
        zIndex: 1,
    },
    feesText: {
        color: "#FFF",
        fontSize: 16,
        textAlign: "center",
        marginVertical: 5,
    },
});

export default FeesSection;
