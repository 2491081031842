import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  SafeAreaView,
  Linking,
  ScrollView,
  Dimensions,
  Animated,
  Easing,
} from "react-native";
import Header from "../../components/general/home_search/Header";

// Assets
import playStoreIcon from "../../../assets/imgs/icons/play_store.webp";
import appleStoreIcon from "../../../assets/imgs/icons/apple_store.png";
import appLogo from "../../../assets/imgs/logos/Mascote_Retrato_Preto.png";

const DownloadAppScreen = ({ navigation }) => {
  const screenWidth = Dimensions.get("window").width;
  const isSmallScreen = screenWidth < 600;
  const [fadeAnim] = useState(new Animated.Value(0)); // Initial opacity 0

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1500,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  const openPlayStore = () => {
    Linking.openURL("https://play.google.com/store/apps/details?id=com.rayv.android");
  };

  const openAppleStore = () => {
    Linking.openURL("https://apps.apple.com/br/app/rayv/id6504122380");
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <Header
          navigation={navigation}
          onHomePress={() => navigation.navigate("Home")}
          onSearchPress={() => navigation.navigate("Search")}
          onSettingsPress={() => navigation.navigate("Settings")}
          onCreateEventPress={() => navigation.navigate("CreateEvent")}
          onLoginPress={() => navigation.navigate("Login")}
        />
      </View>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <Animated.View style={[styles.mainContent, { opacity: fadeAnim }]}>
          <Image source={appLogo} style={styles.logo} resizeMode="contain" />
          <Text style={styles.title}>Seu rolê começa aqui!</Text>
          <Text style={styles.subtitle}>
            Curta eventos de um jeito novo! Com o RayV, você descobre eventos exclusivos, compra ingressos rápido e ainda se conecta com a galera. Tudo em um só lugar!
          </Text>
          <Text style={styles.highlightText}>
            Baixe agora e desbloqueie um mundo de possibilidades!
          </Text>
          <TouchableOpacity style={styles.downloadButton} onPress={openPlayStore}>
            <Image source={playStoreIcon} style={styles.storeIcon} />
            <Text style={styles.buttonText}>Google Play</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.downloadButtonAlt} onPress={openAppleStore}>
            <Image source={appleStoreIcon} style={styles.storeIcon} />
            <Text style={styles.buttonTextSecond}>App Store</Text>
          </TouchableOpacity>
        </Animated.View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
  },
  headerContainer: {
    backgroundColor: "#000",
  },
  contentContainer: {
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  mainContent: {
    alignItems: "center",
    width: "100%",
    maxWidth: 800,
  },
  logo: {
    width: 100,
    height: 100,
    marginBottom: 5,
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: "#CCC",
    textAlign: "center",
    marginBottom: 15,
    paddingHorizontal: 20,
  },
  highlightText: {
    fontSize: 20,
    color: "#fffd",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  downloadButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#1A2035",
    padding: 10,
    borderRadius: 10,
    marginVertical: 10,
    width: "80%",
    justifyContent: "center",
  },
  downloadButtonAlt: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fffd",
    borderColor: "#fffd",
    padding: 10,
    borderRadius: 10,
    marginVertical: 10,
    width: "80%",
    justifyContent: "center",
  },
  storeIcon: {
    width: 28,
    height: 28,
    marginRight: 15,
  },
  buttonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonTextSecond: {
    color: "#000",
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default DownloadAppScreen;
