import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

// Função para verificar se uma denúncia já foi feita
export const checkExistingReport = async (reportedUserId, reportingUserId) => {
  try {
    const reportQuery = query(
      collection(db, "userReports"),
      where("reportedUserId", "==", reportedUserId),
      where("reportingUserId", "==", reportingUserId)
    );
    const reportSnapshot = await getDocs(reportQuery);

    return !reportSnapshot.empty;
  } catch (error) {
    console.error("Erro ao verificar denúncia existente: ", error);
    return false;
  }
};
