import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from "react-native-vector-icons/Ionicons";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

const formatEventDuration = (startTime, endTime) => {
  if (!startTime || !endTime) return "";

  const startDate = moment(startTime.toDate()).tz("America/Sao_Paulo").locale("pt-br");
  const endDate = moment(endTime.toDate()).tz("America/Sao_Paulo").locale("pt-br");

  const formatDay = (day) => {
    const dayMap = {
      domingo: "Domingo",
      "segunda-feira": "Segunda",
      "terça-feira": "Terça",
      "quarta-feira": "Quarta",
      "quinta-feira": "Quinta",
      "sexta-feira": "Sexta",
      sábado: "Sábado",
    };
    return dayMap[day.toLowerCase()] || day;
  };

  const startText = `${formatDay(startDate.format("dddd"))} às ${startDate.format("HH:mm")}`;
  const endText = `${formatDay(endDate.format("dddd"))} às ${endDate.format("HH:mm")}`;

  return `${startText} até ${endText}`;
};

const EventDetailsSection = ({ evento, abrirMapa }) => {
  const startDate = evento?.dates?.start?.toDate ? moment(evento.dates.start.toDate()) : moment(evento.dates.start);
  const endDate = evento?.dates?.end?.toDate ? moment(evento.dates.end.toDate()) : moment(evento.dates.end);

  return (
    <View style={styles.cardContainer}>
      <View style={styles.card}>
        <MaterialCommunityIcons name="calendar-month-outline" size={24} style={styles.icon} />
        <View style={styles.textContent}>
          <Text style={styles.cardText}>{formatEventDuration(evento.dates.start, evento.dates.end)}</Text>
          <Text style={styles.cardSubText}>{`${startDate.format("DD [de] MMMM")} - ${endDate.format("DD [de] MMMM")}`}</Text>
          <Text style={styles.brasiliaTime}>(Horário de Brasília)</Text>
        </View>
      </View>
      <View style={styles.card}>
        <MaterialIcons name="location-on" size={24} style={styles.icon} />
        <View style={styles.textContent}>
          <Text style={styles.cardText}>{evento?.address?.name}</Text>
          <Text style={styles.cardSubText}>{evento?.address?.street}, {evento?.address?.number}, {evento?.address?.neighborhood}, {evento?.address?.city} - {evento?.address?.state}, {evento?.address?.zip}</Text>
        </View>
      </View>
      <TouchableOpacity onPress={abrirMapa} style={styles.mapButton}>
        <Icon name="map-outline" size={14} color="#FFF" />
        <Text style={styles.mapButtonText}>Ver Mapa</Text>
      </TouchableOpacity>
    </View>
  );
};

export default EventDetailsSection;
