import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon from "react-native-vector-icons/Ionicons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import moment from 'moment';

const PastLots = ({ evento, expandPastLots, setExpandPastLots }) => {
  const currentDate = moment();
  const endDateEvent = evento.dates.end?.toDate ? moment(evento.dates.end.toDate()) : moment(evento.dates.end);

  if (evento.status === "Finalizado" || currentDate.isAfter(endDateEvent)) {
    return null;
  }

  const pastOrSoldOutLots = evento?.lots
    ? Object.values(evento.lots).filter((lote) => {
        const isFinally = lote.finally;
        const isVisible = lote.visible;
        const isSoldOut = Number(lote.sold) >= Number(lote.quantity);
        
        const endDate = lote.end?.toDate ? moment(lote.end.toDate()) : moment(lote.end);
        const hasDatePassed = lote.turnType === "date" && currentDate.isAfter(endDate);
        
        return isVisible && (isSoldOut || hasDatePassed || isFinally);
      })
    : [];

  const renderPastOrSoldOutLot = (lote, index) => {
    return (
      <View key={index} style={[styles.lotCard, styles.soldOutLotCard]}>
        <View style={styles.lotDetailContainer}>
          <Text style={styles.lotName}>{lote.name}</Text>
          <Text style={styles.lotSoldOut}>Esgotado</Text>
        </View>
      </View>
    );
  };

  return (
    pastOrSoldOutLots.length > 0 && (
      <View style={styles.lotSelectionContainer}>
        <TouchableOpacity onPress={() => setExpandPastLots(!expandPastLots)}>
          <View style={styles.sectionHeader}>
            <Icon name="alert-circle-outline" size={22} color="#FFF" style={styles.sectionIcon} />
            <Text style={styles.lotTitleText}>Lotes Anteriores</Text>
            <Icon name={expandPastLots ? "chevron-up" : "chevron-down"} size={22} color="#FFF" style={styles.sectionIconSecond} />
          </View>
        </TouchableOpacity>
        {expandPastLots && pastOrSoldOutLots
          .sort((a, b) => Number(a.price) - Number(b.price))
          .map((lote, index) => renderPastOrSoldOutLot(lote, index))}
      </View>
    )
  );
};

export default PastLots;
