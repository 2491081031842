import React from 'react';
import { View, Text, TouchableOpacity, Image, Modal, TouchableWithoutFeedback } from 'react-native';
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import defaultAvatarImage from "../../../../assets/imgs/Avatar_Macaco.png";

const StickerSection = ({ evento, modalVisible, setModalVisible }) => {
  const stickerImage = evento && evento.sticker !== "" ? { uri: evento.sticker } : defaultAvatarImage;

  return (
    <View>
      <View style={styles.sectionDividerSecond}></View>
      <TouchableOpacity onPress={() => setModalVisible(true)}>
        <View style={styles.stickerContainer}>
          <View style={styles.stickerDetails}>
            <Text style={styles.stickerTitle}>Adesivo do(a) {evento?.name}</Text>
            <Text style={styles.stickerDescription}>Adquira o adesivo com a compra do evento e ganhe recompensas únicas</Text>
          </View>
          <Image source={stickerImage} style={styles.stickerImage} />
        </View>
      </TouchableOpacity>
      <Modal visible={modalVisible} transparent={true} animationType="fade" onRequestClose={() => setModalVisible(false)}>
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <TouchableOpacity onPress={() => setModalVisible(false)}>
                <Image source={evento?.sticker ? { uri: evento.sticker } : require("../../../../assets/imgs/Logo_Macaco2.png")} style={styles.modalImage} />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <View style={styles.sectionDividerSecond}></View>
    </View>
  );
};

export default StickerSection;
