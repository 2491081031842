import React, { useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { buscarEventoPorId } from "../../../services/cruds/tickets/ticketFuncs";

const EventLoader = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { id } = route.params;
  console.log(route.params);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("HomeTabs");
    }
  };

  useEffect(() => {
    const loadEvent = async () => {
      try {
        const evento = await buscarEventoPorId(id);
        if (evento) {
          navigation.replace("Ticket", { evento });
        } else {
          console.error("Evento não encontrado.");
          handleGoBack();
        }
      } catch (error) {
        console.error("Erro ao carregar evento:", error);
        handleGoBack();
      }
    };

    loadEvent();
  }, [id]);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#000" }}>
      <ActivityIndicator size="large" />
      <Text>Carregando evento...</Text>
    </View>
  );
};

export default EventLoader;
