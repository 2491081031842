import React from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { styles } from "../../../../styles/utils/terms/producerTerms/producerTermsStyles";
import Icon from "react-native-vector-icons/Ionicons";

export default function ProducerTermsOfUse({ navigation }) {
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" />
      <View style={styles.containerHeader}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.backButton}
        >
          <Icon name="arrow-back" size={25} color="#FFFFFF" />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Text style={styles.title}>Termos de Uso</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon
            name="person-outline"
            size={25}
            color="#000"
            style={styles.goBackButton}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.content}>
          <Text style={styles.sectionTitle}>
            TERMOS DE USO PARA PRODUTORES DA PLATAFORMA E APLICATIVO RAYV
          </Text>
          <Text style={styles.sectionTitle}>1. A Plataforma</Text>
          <Text style={styles.body}>
            A RAYV é uma plataforma tecnológica disponível para produtores e
            casas de eventos, facilitando a venda de ingressos, inscrição para
            eventos, cobrança de contribuições para eventos e a gestão de
            participantes, acessível no endereço eletrônico www.rayv.com.br, ou
            por meio dos aplicativos oficiais. É uma pessoa jurídica de direito
            privado, inscrita no CNPJ sob o nº X, com endereço na X.
          </Text>
          <Text style={styles.sectionTitle}>2. Informações Gerais</Text>
          <Text style={styles.body}>
            2.1. Os atuais Termos regem os serviços disponibilizados na
            plataforma RAYV, os quais compreendem:
          </Text>
          <Text style={styles.listItem}>
            (i) fornecer soluções tecnológicas (Plataforma RAYV) para que
            Produtores possam criar, gerenciar e promover seus próprios eventos
            presenciais ou virtuais;
          </Text>
          <Text style={styles.listItem}>
            (ii) facilitar, por meio da plataforma RAYV, as transações de venda
            de ingressos e inscrições cadastradas pelos Produtores;
          </Text>
          <Text style={styles.listItem}>
            (iii) hospedar os eventos criados pelos Produtores.
          </Text>
          <Text style={styles.body}>
            2.2. Os eventos criados e anunciados na plataforma RAYV são de
            inteira responsabilidade dos respectivos Produtores, que são
            exclusivamente responsáveis por estabelecer as condições de venda,
            realização e cancelamento dos eventos. Embora a RAYV não seja
            responsável pela criação, organização ou propriedade dos eventos
            hospedados em sua plataforma, ela pode monitorá-los para garantir
            conformidade com suas políticas legais.
          </Text>
          <Text style={styles.body}>
            2.2.1. O Organizador exonera a RAYV de qualquer responsabilidade
            civil, seja ela de natureza moral ou material, incluindo lucros
            cessantes e danos emergentes, resultantes das ações relacionadas à
            produção e realização dos eventos. O Organizador concorda em
            indenizar imediatamente a RAYV caso esta seja condenada
            judicialmente, sem a necessidade de uma ação de regresso. Se a RAYV
            incorrer em prejuízos devido a litígios, ela terá o direito de
            demandar o Organizador, que será responsável pelos custos judiciais,
            custas e honorários advocatícios equivalentes a 20% do valor da
            causa.
          </Text>
          <Text style={styles.body}>
            2.3. A plataforma RAYV e suas funcionalidades são fornecidas aos
            usuários na forma como estão disponíveis, sujeitas a melhorias e
            atualizações regulares. A RAYV se compromete a:
          </Text>
          <Text style={styles.listItem}>
            a) Manter as funcionalidades da plataforma RAYV operacionais, com
            links funcionais e layouts que promovam a usabilidade e
            navegabilidade;
          </Text>
          <Text style={styles.listItem}>
            b) Apresentar as funcionalidades de maneira clara, completa, precisa
            e suficiente para permitir a compreensão das operações realizadas.
          </Text>
          <Text style={styles.body}>
            2.4. A RAYV se esforça para manter sua plataforma continuamente
            disponível. No entanto, eventualmente, podem ocorrer períodos de
            indisponibilidade temporária devido a manutenção programada ou
            eventos de força maior, como desastres naturais ou falhas nos
            sistemas de comunicação e acesso à internet.
          </Text>
          <Text style={styles.body}>
            2.4.1. Em casos de indisponibilidade, a RAYV trabalhará para
            restabelecer o acesso à plataforma o mais rápido possível, dentro
            das limitações técnicas de seus próprios serviços e dos serviços de
            terceiros.
          </Text>
          <Text style={styles.body}>
            2.4.2. O Organizador isenta a RAYV de qualquer responsabilidade por
            interrupções parciais ou totais de seus serviços, decorrentes de
            problemas de energia, falhas nos serviços das concessionárias,
            problemas nas redes de telecomunicações e outros provedores, greves,
            tumultos ou eventos alheios ao controle da RAYV. A RAYV também não
            será responsável por danos causados por eventos imprevisíveis ou
            fora de seu controle.
          </Text>
          <Text style={styles.body}>
            2.5. Qualquer manutenção programada da plataforma RAYV será
            comunicada com antecedência, sempre que possível, por meio dos
            canais oficiais de comunicação da RAYV.
          </Text>
          <Text style={styles.sectionTitle}>3. Acesso à plataforma RAYV</Text>
          <Text style={styles.body}>
            3.1. Os usuários utilizarão um login e senha pessoais e
            intransferíveis para acessar suas contas. Eles concordam em não
            divulgar esses dados a terceiros e serão responsáveis pelo uso feito
            com eles. Qualquer uso não autorizado da conta deve ser
            imediatamente comunicado à RAYV. A empresa não será responsável por
            perdas ou danos decorrentes de acessos não autorizados.
          </Text>
          <Text style={styles.body}>
            3.2. Apenas o titular da conta na RAYV terá acesso aos dados
            relacionados a ela, pois são informações pessoais. Alterações nos
            dados cadastrados só podem ser feitas pelo próprio usuário logado.
            Em geral, a RAYV não faz essas alterações, independentemente do
            motivo.
          </Text>
          <Text style={styles.sectionTitle}>4. Cadastro de Usuários</Text>
          <Text style={styles.body}>
            4.1. O cadastro na plataforma RAYV é gratuito, entretanto, os
            serviços oferecidos estão sujeitos às tarifas estabelecidas pela
            RAYV em seus Termos.
          </Text>
          <Text style={styles.body}>
            4.1.1. O cadastro na Plataforma RAYV é necessário para que usuários
            acessem seus ingressos, uma vez que os ingressos são
            disponibilizados para uso pessoal e intransferível por meio do
            aplicativo da Plataforma. O Organizador concorda com esses termos.
          </Text>
          <Text style={styles.body}>
            4.1.2. A RAYV não verifica a veracidade ou correção das informações
            cadastradas, e a criação de perfis falsos pode ser considerada
            crime. Informações falsas ou incorretas podem resultar na não
            prestação dos serviços pela RAYV, sem ônus para esta.
          </Text>
          <Text style={styles.body}>
            4.2. Ao se cadastrar, o Organizador declara e concorda que:
          </Text>
          <Text style={styles.listItem}>
            a) As informações fornecidas para o cadastro na RAYV são verdadeiras
            e precisas, e se compromete a atualizá-las quando necessário.
          </Text>
          <Text style={styles.listItem}>
            b) Informará prontamente à RAYV sobre quaisquer alterações em seus
            dados cadastrais, incluindo a revogação de poderes em caso de
            pessoas jurídicas, no prazo de 10 dias.
          </Text>
          <Text style={styles.listItem}>
            c) Conhece o programa de prevenção à lavagem de dinheiro da RAYV e
            se compromete a relatar imediatamente à RAYV qualquer atividade
            suspeita na plataforma.
          </Text>
          <Text style={styles.listItem}>
            d) Seu relacionamento com a RAYV é como Produtor/Organizador de
            eventos, cujos ingressos serão comercializados pela RAYV.
          </Text>
          <Text style={styles.body}>
            4.2.4. Ao se cadastrar, o Organizador Público concorda que:
          </Text>
          <Text style={styles.listItem}>
            a) Tem autoridade para representar a entidade da Administração
            Pública em todas as atividades relacionadas à execução dos serviços
            oferecidos na Plataforma RAYV.
          </Text>
          <Text style={styles.listItem}>
            b) A escolha pela Plataforma RAYV foi feita de forma livre e
            independente, sem influência da RAYV.
          </Text>
          <Text style={styles.listItem}>
            c) Cumpriu todas as leis, regulamentações e regras internas
            aplicáveis, incluindo a Lei nº 8.666/93, a Lei nº 14.133/21 e futuras
            regulamentações.
          </Text>
          <Text style={styles.listItem}>
            d) Seguiu todos os procedimentos legais necessários para contratar a
            Plataforma RAYV, incluindo a estimativa dos valores contratados, a
            escolha da modalidade de contratação e a realização de pesquisa de
            mercado.
          </Text>
          <Text style={styles.listItem}>
            e) A conta bancária fornecida no cadastro é de titularidade da
            entidade da Administração Pública cadastrada, e os valores recebidos
            pela utilização da Plataforma RAYV serão destinados às atividades
            legítimas da entidade.
          </Text>
          <Text style={styles.listItem}>
            f) É totalmente responsável pelo destino dos valores recebidos pela
            utilização da Plataforma RAYV.
          </Text>
          <Text style={styles.listItem}>
            g) A RAYV não é responsável por quaisquer ilegalidades ou condutas
            impróprias cometidas por Produtores Públicos, estando protegida pela
            presunção de legalidade dos atos da Administração Pública.
          </Text>
          <Text style={styles.body}>
            4.2.5. Se a RAYV tiver motivos para suspeitar que as informações
            cadastradas não são verdadeiras, ela se reserva o direito de
            suspender ou encerrar a conta do usuário, além de recusar a
            prestação de serviços.
          </Text>
          <Text style={styles.body}>
            4.2.6. A RAYV não é responsável pela veracidade ou correção das
            informações cadastradas. A criação de perfis falsos na plataforma
            RAYV pode ser considerada crime, e a inserção de informações falsas
            ou incorretas pode resultar na não prestação dos serviços sem ônus
            para a RAYV.
          </Text>
          <Text style={styles.body}>
            4.3. Além de outras medidas, a RAYV pode advertir, moderar,
            suspender ou cancelar o cadastro dos usuários e os eventos
            associados, recusando-se a fornecer os serviços descritos nestes
            Termos se o usuário:
          </Text>
          <Text style={styles.listItem}>
            a) Violar qualquer disposição destes Termos ou de qualquer outra
            política da RAYV;
          </Text>
          <Text style={styles.listItem}>
            b) Praticar atos fraudulentos ou dolosos;
          </Text>
          <Text style={styles.listItem}>
            c) Causar qualquer dano ou prejuízo a terceiros ou à própria RAYV no
            uso da plataforma RAYV.
          </Text>
          <Text style={styles.sectionTitle}>
            5. Responsabilidades e obrigações dos Produtores na criação de
            eventos
          </Text>
          <Text style={styles.body}>
            5.1. Ao criar eventos na Plataforma RAYV, os Produtores devem:
          </Text>
          <Text style={styles.listItem}>
            a) Publicar eventos com informações verdadeiras e completas,
            incluindo local, data, horário, valores, atrações, serviços
            oferecidos, contato, políticas relevantes e termos legais.
          </Text>
          <Text style={styles.listItem}>
            b) Atualizar eventos com a opção "Local a definir" pelo menos 7 dias
            antes do evento.
          </Text>
          <Text style={styles.listItem}>
            c) Publicar apenas eventos que possam cumprir, sem infringir direitos
            de terceiros.
          </Text>
          <Text style={styles.listItem}>
            d) Permitir que a RAYV verifique a legitimidade do evento com
            informações e documentos.
          </Text>
          <Text style={styles.listItem}>
            e) Não representar outra pessoa ou entidade.
          </Text>
          <Text style={styles.listItem}>
            f) Não publicar eventos que violem as Diretrizes da Comunidade.
          </Text>
          <Text style={styles.listItem}>
            g) Ser responsável pelos links postados na página do evento.
          </Text>
          <Text style={styles.listItem}>
            h) Não bloquear comunicações da RAYV.
          </Text>
          <Text style={styles.listItem}>
            i) Preencher corretamente os dados de repasse de valores.
          </Text>
          <Text style={styles.listItem}>
            j) Usar a RAYV apenas para vender ingressos para eventos na
            plataforma.
          </Text>
          <Text style={styles.listItem}>
            k) Baixar o aplicativo Zoom para eventos no RAYV Streaming.
          </Text>
          <Text style={styles.listItem}>
            l) Atender aos requisitos mínimos do RAYV Streaming.
          </Text>
          <Text style={styles.listItem}>
            m) Atender aos requisitos mínimos do RAYV Live.
          </Text>
          <Text style={styles.body}>
            5.2. Durante calamidades públicas ou emergências de saúde, os
            Produtores assumem a responsabilidade total pelos eventos, incluindo
            as medidas sanitárias, e consentem o compartilhamento de
            informações.
          </Text>
          <Text style={styles.body}>
            5.3. A RAYV pode recusar serviços por qualquer motivo, a seu
            critério.
          </Text>
          <Text style={styles.body}>
            5.4. As empresas homologadas não têm relação comercial com a RAYV e
            os produtores têm liberdade para negociar serviços fora do programa
            homologado.
          </Text>
          <Text style={styles.sectionTitle}>6. Divulgação dos eventos</Text>
          <Text style={styles.body}>
            6.1. A RAYV dispõe de uma ferramenta própria para auxiliar na
            divulgação dos eventos, permitindo que o Organizador envie convites
            personalizados por e-mail. Entretanto, se constatarmos que a lista
            de contatos utilizada pelo Organizador não é confiável ou está sendo
            utilizada de forma abusiva, a RAYV poderá restringir ou bloquear o
            envio de e-mails sem aviso prévio. Por isso, o Organizador deve
            verificar sua lista de contatos e garantir que os endereços de
            e-mail utilizados para divulgação sejam confiáveis.
          </Text>
          <Text style={styles.body}>
            6.2. Além disso, é responsabilidade do Organizador divulgar as
            informações do evento, seja na página específica do evento ou por
            outros meios. Para garantir uma divulgação adequada, a RAYV fornece
            uma ferramenta para o envio de e-mails aos Participantes, a qual
            deve ser utilizada exclusivamente para disseminar informações e
            instruções sobre o evento. Se a RAYV identificar o uso inadequado
            dessa ferramenta para outros fins, poderá bloquear o acesso do
            Organizador a ela.
          </Text>
          <Text style={styles.body}>
            6.2.1. O Organizador também é encarregado de responder dúvidas ou
            fornecer orientações aos Consumidores ou Participantes, utilizando,
            por exemplo, a ferramenta "Fale com o produtor" disponível na página
            do evento. As respostas devem ser dadas de forma clara e dentro de
            um prazo razoável.
          </Text>
          <Text style={styles.sectionTitle}>7. Venda de Ingressos</Text>
          <Text style={styles.body}>
            7.1. A venda de ingressos para eventos geralmente começa na página
            específica do evento criada pelo Organizador. O Comprador seleciona
            os ingressos desejados e o método de pagamento, considerando as
            opções disponíveis, e conclui a compra.
          </Text>
          <Text style={styles.body}>
            7.1.1. O Organizador deve utilizar apenas os meios de pagamento
            disponíveis na plataforma para vender ingressos pagos. Qualquer
            tentativa de utilizar outros métodos de pagamento ou criar
            "ingressos grátis" com posterior cobrança poderá resultar na
            exclusão ou moderação do evento pela RAYV, a seu critério exclusivo.
          </Text>
          <Text style={styles.body}>
            7.2. Após a confirmação do pagamento por um intermediário
            financeiro, a RAYV recolhe o valor correspondente, deduzindo a taxa
            de serviço, e repassa o valor restante ao Organizador conforme o
            modelo de recebimento escolhido.
          </Text>
          <Text style={styles.body}>
            7.3. Todas as transações realizadas pela RAYV passam por processos
            internos e automatizados de análise de risco, complementados pelos
            processadores de pagamento.
          </Text>
          <Text style={styles.body}>
            7.4. Por motivos de segurança, as compras feitas com cartão de
            crédito que requerem confirmação adicional podem passar por uma
            análise mais detalhada, com a aprovação pendente por até 72 horas
            após a compra.
          </Text>
          <Text style={styles.body}>
            7.4.1. Se a RAYV identificar alguma irregularidade após essa
            análise, a compra poderá passar por uma nova verificação a qualquer
            momento. O Comprador será notificado por e-mail sobre a suspensão ou
            cancelamento do pedido pela RAYV.
          </Text>
          <Text style={styles.body}>
            7.5. O Organizador deve garantir que não haja fraudes nas compras de
            ingressos e colaborar com a RAYV, quando solicitado, para investigar
            qualquer suspeita de fraude.
          </Text>
          <Text style={styles.body}>
            7.5.1. Se a RAYV identificar, por meio de sua análise de risco, que
            o Organizador está envolvido intencionalmente em fraudes na venda de
            ingressos para seus eventos, poderá tomar medidas como o
            cancelamento dos ingressos, o estorno dos pagamentos feitos com
            cartão de crédito, a exclusão da conta e a responsabilização
            judicial do Organizador.
          </Text>
          <Text style={styles.sectionTitle}>8. Entrega dos ingressos</Text>
          <Text style={styles.body}>
            8.1. Assim que o pagamento for confirmado, um e-mail de confirmação
            da compra será enviado ao endereço de e-mail fornecido pelo
            Comprador. Os ingressos ficarão disponíveis na seção "Meus
            Ingressos" do aplicativo da RAYV, que será utilizado para o check-in
            e acesso ao evento.
          </Text>
          <Text style={styles.body}>
            8.1.1. Para acessar os ingressos, os usuários devem ter uma conta na
            Plataforma com o mesmo endereço de e-mail usado para a identificação
            dos ingressos.
          </Text>
          <Text style={styles.body}>
            8.2. Cada ingresso está associado a um código QR dinâmico, gerado no
            momento da compra.
          </Text>
          <Text style={styles.body}>
            8.2.1. Se o Comprador adquirir ingressos para outras pessoas, ele
            será responsável por enviar os ingressos para esses usuários.
          </Text>
          <Text style={styles.body}>
            8.2.2. Após receber os ingressos, os usuários devem mantê-los
            seguros, não compartilhando publicamente ou permitindo o acesso de
            terceiros.
          </Text>
          <Text style={styles.body}>
            8.3. Os ingressos comprados por meio da Plataforma RAYV não têm
            nomes associados.
          </Text>
          <Text style={styles.body}>
            8.5. Os ingressos adquiridos na Plataforma RAYV só podem ser usados
            uma vez para acessar o evento. O Organizador é responsável por fazer
            o check-in dos Participantes, ou seja, verificar e validar os
            códigos QR dos ingressos.
          </Text>
          <Text style={styles.body}>
            8.5.1. O acesso dos Participantes ao evento não será concedido se o
            check-in do ingresso já tiver sido realizado anteriormente, a menos
            que o Organizador decida permitir exceções e forneça medidas de
            segurança adequadas para a realização do evento.
          </Text>
          <Text style={styles.body}>
            8.6. A RAYV poderá enviar comunicações relacionadas aos ingressos e
            eventos, sendo responsabilidade dos usuários garantir que seus
            sistemas anti-spam ou filtros não interfiram no recebimento dessas
            comunicações. A falta de recebimento de e-mails devido a esses
            filtros não dará direito a compensações ou indenizações.
          </Text>
          <Text style={styles.sectionTitle}>
            9. Uso dos ingressos e participação nos eventos
          </Text>
          <Text style={styles.body}>
            9.1. Os ingressos adquiridos pelos Consumidores garantem sua
            participação no evento de acordo com as condições estabelecidas pelo
            Organizador. O Organizador deve garantir o acesso dos Participantes
            ao evento sempre que apresentarem um ingresso válido por meio do
            aplicativo da RAYV.
          </Text>
          <Text style={styles.body}>
            9.2. A plataforma RAYV oferece ferramentas para o controle adequado
            da entrada dos Participantes nos eventos. A decisão de não utilizar
            essas ferramentas, bem como os problemas resultantes dessa escolha,
            são de responsabilidade exclusiva do Organizador. O uso dessas
            ferramentas evita o acesso de Participantes com ingressos inválidos,
            como cópias não autorizadas, ingressos invalidados devido a
            suspeitas de fraude, contestações de compra, trocas de titularidade
            não autorizadas, cancelamentos de compra ou ingressos falsificados.
          </Text>
          <Text style={styles.sectionTitle}>
            10. Cancelamento e restituição de compras
          </Text>
          <Text style={styles.body}>
            10.1. Se houver necessidade de reembolsar o Comprador, o Organizador
            deve realizar essa operação exclusivamente através das ferramentas
            disponíveis na plataforma RAYV, não sendo permitido o reembolso por
            outros meios.
          </Text>
          <Text style={styles.body}>
            10.2. Até o segundo dia útil após o término do evento, o Organizador
            pode solicitar o cancelamento de uma venda e o consequente reembolso
            do valor do ingresso para o Consumidor, com a dedução da taxa de
            serviço.
          </Text>
          <Text style={styles.body}>
            10.3. Cabe ao Organizador informar sua política de cancelamento aos
            potenciais usuários, alinhando-a com o período máximo de reembolso
            estipulado pela RAYV, e solicitar o cancelamento da transação
            através das ferramentas disponíveis na plataforma.
          </Text>
          <Text style={styles.body}>
            10.4. Se o Comprador exercer seu direito de arrependimento conforme
            o Código de Defesa do Consumidor, o Organizador deve reembolsar o
            valor do ingresso em até sete dias a partir da data da compra.
          </Text>
          <Text style={styles.sectionTitle}>
            11. Eventos cancelados ou substancialmente alterados
          </Text>
          <Text style={styles.body}>
            11.1. O Organizador deve comunicar imediatamente à RAYV qualquer
            cancelamento, alteração significativa ou outra modificação relevante
            referente ao seu evento.
          </Text>
          <Text style={styles.body}>
            11.2. O Organizador é responsável por reembolsar todos os
            Consumidores se o evento não ocorrer por qualquer motivo ou não for
            entregue conforme anunciado. A RAYV tem o direito de cobrar do
            Organizador qualquer valor pago aos Consumidores devido a ações
            judiciais ou administrativas.
          </Text>
          <Text style={styles.body}>
            11.3. Se um evento não for realizado ou ocorrer de forma diferente
            do anunciado, a RAYV pode reembolsar as compras ou suspender o
            repasse até que a situação seja resolvida entre as partes
            envolvidas.
          </Text>
          <Text style={styles.body}>
            11.4. A RAYV pode deduzir valores do repasse ou cobrar do
            Organizador posteriormente, inclusive retendo valores de outros
            eventos, se houver débitos pendentes.
          </Text>
          <Text style={styles.sectionTitle}>
            12. Disputas e estornos de compras
          </Text>
          <Text style={styles.body}>
            12.1. As disputas são analisadas pela operadora de cartão de
            crédito, processadores de pagamento e pela RAYV.
          </Text>
          <Text style={styles.body}>
            12.2. A RAYV e seus processadores de pagamento tomam precauções para
            evitar fraudes nas compras e contestações.
          </Text>
          <Text style={styles.body}>
            12.3. A RAYV conduz disputas, como chargebacks, sem a necessidade de
            aprovação do Organizador em certos casos, como não cumprimento de
            políticas de reembolso, suspeitas de fraude, cancelamento do evento,
            erro técnico ou reclamações dos Consumidores.
          </Text>
          <Text style={styles.sectionTitle}>
            13. Recebimento de valores das vendas e taxa de serviço
          </Text>
          <Text style={styles.body}>
            13.1. O Organizador recebe o valor das vendas por transferência
            bancária, deduzida a taxa de serviço, até o terceiro dia útil após o
            término do evento.
          </Text>
          <Text style={styles.body}>
            13.2. O repasse considera a data real do evento, podendo ser adiado
            se a data cadastrada na plataforma RAYV não corresponder à data
            real.
          </Text>
          <Text style={styles.body}>
            13.3. Ao cadastrar a conta bancária, o Organizador deve usar o mesmo
            titular do cadastro na plataforma RAYV.
          </Text>
          <Text style={styles.body}>
            13.4. O Organizador é responsável por cadastrar corretamente os
            dados bancários e por qualquer atraso nesse processo.
          </Text>
          <Text style={styles.body}>
            13.5. A RAYV pode reter valores em casos de reembolsos,
            cancelamentos ou problemas de acesso ao evento, cobrando do
            Organizador posteriormente.
          </Text>
          <Text style={styles.body}>
            13.6. Uma taxa de serviço é cobrada pelo uso da plataforma RAYV,
            calculada com base nas vendas online processadas.
          </Text>
          <Text style={styles.sectionTitle}>14. Emissão de fatura fiscal</Text>
          <Text style={styles.body}>
            14.1. Nos eventos em que há uma taxa de serviço, uma fatura fiscal
            será emitida em nome do Organizador na primeira semana do mês
            seguinte ao pagamento do evento, pelo valor correspondente às taxas
            cobradas pela RAYV. O Organizador é responsável por fornecer os
            dados necessários para emissão da fatura dentro do prazo na
            plataforma RAYV.
          </Text>
          <Text style={styles.body}>
            14.2. Todos os impostos sobre o total vendido pelo Organizador
            através da plataforma RAYV são de responsabilidade exclusiva deste.
          </Text>
          <Text style={styles.sectionTitle}>15. Registro de recebíveis</Text>
          <Text style={styles.body}>
            15.1. Conforme as regulamentações do Conselho Monetário Nacional e
            do Banco Central do Brasil, a RAYV ou um terceiro autorizado
            realizará o registro dos valores a serem repassados ao Organizador,
            conforme estipulado na Cláusula 13.1 destes Termos de Uso.
          </Text>
          <Text style={styles.body}>
            15.2. Se o Organizador decidir ceder os recebíveis ou garantir
            operações de crédito com eles, ele deve informar integralmente os
            termos destes Termos de Uso aos cessionários.
          </Text>
          <Text style={styles.body}>
            15.3. A responsabilidade pelas operações de recebíveis é exclusiva
            do Organizador, não cabendo à RAYV responsabilidade alguma nesse
            sentido.
          </Text>
          <Text style={styles.body}>
            15.4. A RAYV não se responsabiliza por erros dos cessionários na
            informação dos dados das operações de recebíveis.
          </Text>
          <Text style={styles.body}>
            15.5. O Organizador deve informar à RAYV sobre as operações de
            recebíveis e autorizar a divulgação dessas informações ao sistema de
            registro.
          </Text>
          <Text style={styles.sectionTitle}>
            16. Direitos de propriedade intelectual
          </Text>
          <Text style={styles.body}>
            16.1. A RAYV detém os direitos sobre sua marca e conteúdos,
            protegidos pelas leis de direitos autorais, marcas e patentes.
          </Text>
          <Text style={styles.body}>
            16.2. Ao disponibilizar conteúdo na plataforma RAYV, os usuários
            concedem uma licença gratuita e irrevogável para sua publicação.
          </Text>
          <Text style={styles.body}>
            16.3. A RAYV não controla nem é responsável pelos conteúdos de sites
            de terceiros vinculados à plataforma.
          </Text>
          <Text style={styles.body}>
            16.4. Os usuários são responsáveis por garantir que possuem
            autorização para publicar conteúdos na plataforma.
          </Text>
          <Text style={styles.body}>
            16.5. O usuário é responsável por eventuais prejuízos decorrentes de
            seus atos na plataforma.
          </Text>
          <Text style={styles.body}>
            16.6. A RAYV pode remover eventos em caso de violação de direitos
            autorais.
          </Text>
          <Text style={styles.body}>
            16.7. O Organizador deve cumprir as leis de direitos autorais e
            propriedade industrial, inclusive pagar as contribuições devidas ao
            ECAD, quando aplicável.
          </Text>
          <Text style={styles.body}>
            16.7.1. A RAYV pode solicitar comprovação de pagamento ao ECAD pelo
            Organizador. O não cumprimento pode levar à despublicação do evento.
          </Text>
          <Text style={styles.sectionTitle}>
            17. Segurança da plataforma RAYV
          </Text>
          <Text style={styles.body}>
            17.1. Não é permitido acesso às áreas de programação da plataforma.
          </Text>
          <Text style={styles.body}>
            17.2. Não é permitida a engenharia reversa ou qualquer alteração nas
            ferramentas da plataforma.
          </Text>
          <Text style={styles.body}>
            17.3. Não é permitido o uso de aplicativos automatizados sem
            autorização da RAYV.
          </Text>
          <Text style={styles.sectionTitle}>18. Privacidade dos usuários</Text>
          <Text style={styles.body}>
            18.1. A RAYV possui uma Política de Privacidade que regula o
            tratamento de informações pessoais coletadas na plataforma.
          </Text>
          <Text style={styles.body}>
            18.1.1. A Política de Privacidade prevalece em caso de conflito com
            os Termos de Uso.
          </Text>
          <Text style={styles.sectionTitle}>
            19. Proteção de registros, informações e comunicações privadas
          </Text>
          <Text style={styles.body}>
            19.1. A RAYV só disponibilizará registros de acesso ou informações
            pessoais mediante ordem judicial.
          </Text>
          <Text style={styles.body}>
            19.2. A RAYV informará o Organizador sobre pedidos de divulgação de
            informações.
          </Text>
          <Text style={styles.sectionTitle}>20. Empréstimo de Equipamentos</Text>
          <Text style={styles.body}>
            20.1. Sob acordos específicos, a RAYV pode emprestar equipamentos ao
            Organizador, que deve seguir práticas de segurança da informação,
            conforme padrões PCI-DSS.
          </Text>
          <Text style={styles.body}>
            20.1.1. O Organizador é responsável pela segurança física e lógica
            dos dispositivos, além de não armazenar informações confidenciais
            neles.
          </Text>
          <Text style={styles.body}>
            20.1.2. O Organizador deve comunicar imediatamente à RAYV sobre
            qualquer violação das práticas de segurança estabelecidas, assumindo
            a responsabilidade por qualquer consequência.
          </Text>
          <Text style={styles.body}>
            20.2. O Organizador deve colaborar com investigações em casos de
            incidentes de segurança de dados.
          </Text>
          <Text style={styles.sectionTitle}>
            21. Limitação da Responsabilidade
          </Text>
          <Text style={styles.body}>
            21.1. É estabelecido de forma clara que a RAYV não assume a
            obrigação de supervisionar todas as atividades realizadas pelos
            usuários na Plataforma RAYV e, portanto, não pode ser
            responsabilizada pelas ações dos usuários, incluindo aquelas de
            natureza ilegal, imoral ou antiética. Os usuários são os únicos
            responsáveis por quaisquer reclamações de terceiros ou processos
            judiciais, isentando a RAYV de qualquer responsabilidade.
          </Text>
          <Text style={styles.body}>
            21.2. Em nenhuma circunstância, a RAYV, seus proprietários,
            gerentes, funcionários, representantes ou terceiros agindo em seu
            nome serão responsáveis pelo pagamento de indenizações aos usuários,
            consumidores ou seus representantes legais por danos materiais,
            morais, lucros cessantes ou danos emergentes decorrentes da
            organização e realização de eventos, sendo essa responsabilidade
            integralmente do organizador.
          </Text>
          <Text style={styles.sectionTitle}>22. Disposições Gerais</Text>
          <Text style={styles.body}>
            22.1. Qualquer negociação direta realizada pela RAYV com
            Consumidores ou Produtores, pessoalmente ou à distância, terá
            precedência sobre estes Termos de Uso, que servirão como fonte
            subsidiária para interpretação e preenchimento de lacunas.
          </Text>
          <Text style={styles.body}>
            22.2. A RAYV não se responsabiliza por danos causados pelo envio de
            informações ou conteúdo publicitário não originados por ela mesma,
            nem pelo conteúdo das opiniões postadas na página dos eventos.
          </Text>
          <Text style={styles.body}>
            22.3. Ao utilizar a plataforma RAYV, os usuários concordam com os
            Termos de Uso e outras políticas legais vigentes na data de acesso,
            sendo recomendável que se mantenham atualizados.
          </Text>
          <Text style={styles.body}>
            22.3.1. Nossos serviços são destinados a maiores de 18 anos, com
            documento de identificação e endereço de e-mail válidos, ou a
            menores e incapazes devidamente autorizados ou assistidos por
            responsáveis legais.
          </Text>
          <Text style={styles.body}>
            22.4. Os Termos de Uso estão sujeitos a melhorias e alterações pela
            RAYV, com o objetivo de adequação legal ou regulatória, cabendo aos
            usuários verificá-los regularmente.
          </Text>
          <Text style={styles.body}>
            22.5. Qualquer tolerância da RAYV a violações dos Termos não será
            interpretada como renúncia a direitos ou alteração contratual.
          </Text>
          <Text style={styles.body}>
            22.6. Se alguma disposição destes Termos for considerada inválida,
            as demais permanecerão válidas.
          </Text>
          <Text style={styles.body}>
            22.7. A comunicação entre a RAYV e os usuários deve ser feita pelos
            canais de atendimento indicados na plataforma.
          </Text>
          <Text style={styles.body}>
            22.8. Tentativas de resolver disputas serão feitas de forma
            amigável. Em caso de impasse, fica estabelecido o foro da comarca de
            São João Del Rei, Minas Gerais, para resolver questões relacionadas
            a estes Termos de Uso.
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
