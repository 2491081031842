import { doc, getDoc, query, orderBy, limit, startAfter, collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase/firebaseConfig";

export const getChat = async (eventId, chatType, lastLoadedMessage = null) => {
  try {
    const chatId = `${eventId}${chatType}`;
    const chatRef = doc(db, "eventsChats", chatId);
    const docSnap = await getDoc(chatRef);

    if (!docSnap.exists()) {
      return;
    }

    const messages = docSnap.data().messages || [];

    let messagesQuery;
    if (lastLoadedMessage) {
      messagesQuery = query(
        collection(chatRef, "messages"),
        orderBy("timestamp", "desc"),
        startAfter(lastLoadedMessage),
        limit(10)
      );
    } else {
      messagesQuery = query(
        collection(chatRef, "messages"),
        orderBy("timestamp", "desc"),
        limit(10)
      );
    }

    const chatSnap = await getDocs(messagesQuery);
    const paginatedMessages = chatSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })).reverse();

    console.log(paginatedMessages);
    return { messages: [...messages, ...paginatedMessages] };
  } catch (error) {
    console.error("Erro ao obter chat:", error);
    throw error;
  }
};
