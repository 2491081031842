import React, { useEffect, useState } from "react";
import { SafeAreaView, Text, View, ActivityIndicator, StyleSheet, Dimensions } from "react-native";

// Components
import VisitHeader from "../../../components/profile/profileView/VisitHeader";
import ProfileTop from "../../../components/profile/profileGeneral/ProfileTop";
import ProfileBody from "../../../components/profile/profileGeneral/ProfileBody";
import { onUserProfileSnapshot } from "../../../services/cruds/users/getUser";

// Styles
import { styles } from "../../../styles/profile/profileView/ProfileStyles";

const VisitProfile = (props) => {
  const { route, navigation } = props;
  const [visitedUser, setVisitedUser] = useState(route.params.usuario);
  const [currentUser] = useState(route.params.currentUser);
  const [isHeader] = useState(route.params.isHeader ? route.params.isHeader : false);
  const [loading, setLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState(Dimensions.get("window").width);

  useEffect(() => {
    const fetchUserProfile = async (userId) => {
      setLoading(true);
      const unsubscribe = onUserProfileSnapshot(userId, (data) => {
        setVisitedUser(data);
        setLoading(false);
      });

      return () => unsubscribe && unsubscribe();
    };

    if (route.params.usuario && route.params.usuario.userId) {
      fetchUserProfile(route.params.usuario.userId);
    } else {
      setLoading(false);
    }
  }, [route.params.usuario]);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get("window").width);
    };

    Dimensions.addEventListener("change", updateScreenWidth);

    return () => {
      Dimensions.removeEventListener("change", updateScreenWidth);
    };
  }, []);

  if (loading) {
    return (
      <SafeAreaView style={styles.container}>
        <VisitHeader
          navigation={navigation}
          isHeader={isHeader}
          visitedUserId={visitedUser.userId}
          visitedUserUsername={visitedUser.username}
        />
        <ActivityIndicator size="large" color="#FFF" />
      </SafeAreaView>
    );
  }

  if (!visitedUser) {
    return (
      <SafeAreaView style={styles.container}>
        <Text>Perfil do usuário não encontrado.</Text>
      </SafeAreaView>
    );
  }

  const dynamicStyles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#000",
      paddingHorizontal: screenWidth > 500 ? "20%" : "0%", 
    },
  });

  const isVisited = visitedUser.userId !== currentUser;
  const isCurrentUser = visitedUser.userId === currentUser;

  return (
    <SafeAreaView style={styles.container}>
      <VisitHeader
        navigation={navigation}
        isHeader={isHeader}
        visitedUserId={visitedUser.userId}
        visitedUserUsername={visitedUser.username}
      />
      <View style={dynamicStyles.container}>
        <ProfileTop
          isVisited={isVisited}
          userId={visitedUser.userId}
          avatarUrl={visitedUser.avatarUrl}
          fullName={visitedUser.fullName}
          username={visitedUser.username}
          progressProfile={visitedUser.progressProfile}
          descriptionProfile={visitedUser.descriptionProfile}
          accountType={visitedUser.accountType}
          eventHistory={visitedUser.eventHistory}
          hiddenEvents={visitedUser.hiddenEvents}
          followers={visitedUser.followers}
          following={visitedUser.following}
          pendingFollowers={visitedUser.pendingFollowers}
          registrationDate={visitedUser.registrationDate}
          rewardsPoints={visitedUser.rewardsPoints}
          reviews={visitedUser.reviews}
          isPublic={visitedUser.isPublic}
          navigation={navigation}
          currentUser={currentUser}
          isCurrentUser={isCurrentUser}
        />
        <ProfileBody
          navigation={navigation}
          accountType={visitedUser.accountType}
          avatarUrl={visitedUser.avatarUrl}
          hiddenEvents={visitedUser.hiddenEvents}
          stickers={visitedUser.stickers}
          rewardsPoints={visitedUser.rewardsPoints}
          challenges={visitedUser.challenges}
          address={visitedUser.address}
          contactEmail={visitedUser.contactEmail}
          phoneNumber={visitedUser.phoneNumber}
          isVisited={isVisited}
          isPublic={visitedUser.isPublic}
          isCurrentUser={isCurrentUser}
        />
      </View>
    </SafeAreaView>
  );
};

export default VisitProfile;
