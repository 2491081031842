import React from 'react';
import { View, StyleSheet } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';

const ListEventUSkeleton = () => (
  <View style={styles.skeletonContainer}>
    <ContentLoader
      speed={1}
      width={360}
      height={95}
      backgroundColor="#3d3d3d"
      foregroundColor="#1c1c1c"
    >
      <Rect x="0" y="0" rx="8" ry="8" width="160" height="90" />
      <Rect x="170" y="10" rx="5" ry="5" width="150" height="20" />
      <Rect x="170" y="40" rx="5" ry="5" width="100" height="20" />
      <Rect x="170" y="70" rx="5" ry="5" width="80" height="20" />
    </ContentLoader>
  </View>
);

const styles = StyleSheet.create({
  skeletonContainer: {
    marginVertical: 10,
    marginHorizontal: 4,
  },
});

export default ListEventUSkeleton;
