import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import * as Progress from "react-native-progress";

const ChallengeCard = ({ challengeId, challengeData, isVisited }) => {
  const { description, imageUrl, target, progress, completed, name } =
    challengeData;

  const safeProgress = !isNaN(progress) && target > 0 ? progress / target : 0;

  return (
    <View>
      {isVisited ? (
        completed && (
          <>
            <View style={styles.cardContainer}>
              <Image source={{ uri: imageUrl }} style={styles.icon} />
              <View style={styles.textContainer}>
                <Text style={styles.title}>{name}</Text>
                <Text style={styles.description}>{description}</Text>
                <Text style={styles.completedText}>Conquista Completa</Text>
              </View>
            </View>
            <Text style={styles.aboutSectionTitleLine}></Text>
          </>
        )
      ) : (
        <>
          <View style={styles.cardContainer}>
            <Image source={{ uri: imageUrl }} style={styles.icon} />
            <View style={styles.textContainer}>
              <Text style={styles.title}>{name}</Text>
              <Text style={styles.description}>{description}</Text>
              {/* {!completed && <Text style={styles.progressText}>{`Progresso: ${progress}/${target}`}</Text>} */}
              {!isVisited && !completed && (
                <Progress.Bar
                  progress={safeProgress}
                  width={null}
                  color={completed ? "#052552" : "#5B2372"}
                  style={styles.progressBar}
                />
              )}
              {completed && (
                <Text style={styles.completedText}>Conquista Completa</Text>
              )}
            </View>
          </View>
          <Text style={styles.aboutSectionTitleLine}></Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: "#000",
  },
  completedCard: {
    borderColor: "#3D3D3D",
    borderWidth: 3,
    borderRadius: 5,
  },
  icon: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  description: {
    color: "#A7A7A7",
    fontSize: 14,
  },
  progressText: {
    color: "#FFF",
    fontSize: 14,
  },
  progressBar: {
    marginTop: 5,
  },
  completedText: {
    color: "#5B2372",
    fontSize: 14,
    fontWeight: "bold",
  },
  loadingText: {
    color: "#FFF",
    textAlign: "center",
    marginTop: 20,
  },
  aboutSectionTitleLine: {
    height: 0.5,
    backgroundColor: "#333",
  },
});

export default ChallengeCard;
