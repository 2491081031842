import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import { styles } from "../../../styles/utils/terms/TermsStyles";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Header from "../../../components/general/settings/Header";

class Terms extends Component {
  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View>
          <Header navigation={this.props.navigation} />
        </View>
        <ScrollView>
          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => this.props.navigation.navigate("TermsOfUse")}
          >
            <MaterialCommunityIcons
              name="file-document-multiple-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Termos de Uso para Usuários
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossos termos de uso
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => this.props.navigation.navigate("ProducerTermsOfUse")}
          >
            <MaterialCommunityIcons
              name="file-document-multiple"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Termos de Uso para Produtores
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossos termos de uso
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => this.props.navigation.navigate("PrivacyPolicy")}
          >
            <MaterialCommunityIcons
              name="shield-lock-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Política de Privacidade para Usuários
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossa política de privacidade
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.props.navigation.navigate("ProducerPrivacyPolicy")
            }
          >
            <MaterialCommunityIcons
              name="shield-lock"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Política de Privacidade para Produtores
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossa política de privacidade
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingItem}
            onPress={() => this.props.navigation.navigate("CookiePolicy")}
          >
            <MaterialCommunityIcons
              name="cookie-outline"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Política de Cookies para Usuários
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossa política de cookies
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.settingItem}
            onPress={() =>
              this.props.navigation.navigate("ProducerCookiePolicy")
            }
          >
            <MaterialCommunityIcons
              name="cookie"
              size={24}
              style={styles.settingIcon}
            />
            <View style={styles.settingTextContainer}>
              <Text style={styles.settingTitle}>
                Política de Cookies para Produtores
              </Text>
              <Text style={styles.settingSubtitle}>
                Leia nossa política de cookies
              </Text>
            </View>
            <Ionicons
              name="chevron-forward"
              size={24}
              style={styles.settingArrow}
            />
          </TouchableOpacity>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

export default Terms;
