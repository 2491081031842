import React from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";

const formatZipCode = (zip) => {
  const cleaned = ("" + zip).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{5})(\d{3})$/);
  if (match) {
    return `${match[1]}-${match[2]}`;
  }
  return zip;
};

export const LocalDetails = ({ address, showOnMap, abrirMapa }) => (
  <ScrollView style={styles.tabBeginContainer}>
    <View style={styles.tabContainer}>
      <View style={styles.tabHeader}>
        <MaterialCommunityIcons name="map-search" size={20} color="#FFF" />
        <Text style={styles.tabTitle}>Local do Evento</Text>
      </View>
      {address && (
        <View>
          <View style={styles.localContainer}>
            <View style={styles.localRow}>
              <MaterialCommunityIcons
                name="map-marker-radius"
                size={20}
                color="#FFF"
              />
              <Text style={styles.localText}>{address.name}</Text>
            </View>
            <View style={styles.localRow}>
              <MaterialCommunityIcons
                name="map-marker"
                size={20}
                color="#FFF"
              />
              <Text style={styles.localText}>
                {address.street}, {address.number}
              </Text>
            </View>
            <View style={styles.localRow}>
              <MaterialCommunityIcons name="city" size={20} color="#FFF" />
              <Text style={styles.localText}>
                {address.neighborhood}, {address.city} - {address.state}
              </Text>
            </View>
            <View style={styles.localRow}>
              <MaterialCommunityIcons name="mailbox" size={20} color="#FFF" />
              <Text style={styles.localText}>{formatZipCode(address.zip)}</Text>
            </View>
            {address.complement && (
              <View style={styles.localRow}>
                <MaterialCommunityIcons
                  name="map-marker"
                  size={20}
                  color="#FFF"
                />
                <Text style={styles.localText}>{address.complement}</Text>
              </View>
            )}
          </View>
        </View>
      )}
    </View>

    {showOnMap && <View style={styles.sectionDividerTab}></View>}
    {showOnMap && (
      <View style={styles.localContainer}>
        <>
          <TouchableOpacity onPress={abrirMapa} style={styles.mapButtonDown}>
            <Icon name="map-outline" size={20} color="#FFF" />
            <Text style={styles.mapButtonText}>Visualizar no Google Maps</Text>
          </TouchableOpacity>
        </>
      </View>
    )}
  </ScrollView>
);
