import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { styles } from "../../../styles/ticket/ticketView/TicketStyles";
import logoImage from "../../../../assets/imgs/Logo_Macaco2.png";

const BuyButton = ({ selectedLots, evento, getTotal, sendDates, removeDates }) => (
  <View>
    {Object.values(selectedLots).some((lot) => lot.quantity > 0) && (
      <View style={[styles.buyButtonContainer]}>
        <View style={styles.priceAndButtonContainer}>
          <View style={styles.textContainer}>
            <View style={styles.logoAndPriceContainer}>
              <Image source={logoImage} style={styles.buyImage} />
              <Text style={styles.priceText}>R$ {getTotal().toFixed(2)}</Text>
            </View>
            <Text style={styles.priceDetailText}>
              {evento?.absorbFee
                ? "Taxa inclusa"
                : `Mais taxas a partir de R$ ${(getTotal() * 0.05).toFixed(2)}. Podendo variar a depender da forma de pagamento`}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity onPress={sendDates} style={styles.buyButton}>
              <Text style={styles.buyButtonText}>Comprar</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={removeDates} style={styles.removeButton}>
              <Text style={styles.removeButtonText}>Remover</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )}
  </View>
);

export default BuyButton;
